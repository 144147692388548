<!--Source: https://stackblitz.com/edit/angular-7-image-upload-->

<div class="overall-container">
  <!-- Upload image -->
  <div class="group-gap">
    <mat-progress-bar mode="indeterminate" class="pgr-bar"></mat-progress-bar>
    <div class="avatar-upload">
      <div class="avatar-edit">
        <input
          type="file"
          id="imageUpload"
          accept=".png, .jpg, .jpeg"
          #fileInput
          (change)="uploadFile($event)"
        />
      </div>
      <div class="avatar-preview">
        <div
          id="imagePreview"
          (click)="fileInput.click(); onClick()"
          [style.backgroundImage]="'url(' + imageUrl + ')'"
        ></div>
        <div
          class="middle"
          (click)="fileInput.click(); onClick()"
          (mouseenter)="onHover()"
          (mouseleave)="onLeave()"
          [style.opacity]="setOpacityOfImage()"
        >
          <i class="fa fa-arrow-circle-up fa-5x upload-icon"></i>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="form">
    <div class="center">
      <mat-form-field class="input-field">
        <mat-label>Vorname</mat-label>
        <input
          type="text"
          matInput
          [formControl]="firstNameFormControl"
          placeholder="Vorname"
          readonly
        />
      </mat-form-field>

      <mat-form-field class="input-field">
        <mat-label>Nachname</mat-label>
        <input
          type="text"
          matInput
          [formControl]="lastNameFormControl"
          placeholder="Nachname"
          readonly
        />
      </mat-form-field>

      <mat-form-field class="input-field">
        <mat-label>Geburtstag</mat-label>
        <input
          type="text"
          matInput
          [formControl]="birthdayFormControl"
          placeholder="Geburtstag"
          readonly
        />
      </mat-form-field>

      <!--TODO: Das Datum ist vermutlich falsch. Deshalb erstmal deaktiviert-->
      <!-- <mat-form-field class="input-field">
        <mat-label>Eintrittsdatum</mat-label>
        <input
          type="text"
          matInput
          [formControl]="entryDateFormControl"
          placeholder="Eintrittsdatum"
          readonly
        />
      </mat-form-field> -->

      <mat-form-field
        class="input-field"
        *ngIf="
          this.commonFunctions.isPropAvailable(
            this.userData,
            'leavingDate',
            'membership'
          )
        "
      >
        <mat-label>Austrittsdatum</mat-label>
        <input
          type="text"
          matInput
          [formControl]="leavingDateFormControl"
          placeholder="Austrittsdatum"
          readonly
        />
      </mat-form-field>

      <mat-form-field
        class="input-field"
        *ngIf="
          this.commonFunctions.isPropAvailable(
            this.userData,
            'number',
            'membership'
          )
        "
      >
        <mat-label>Mitgliedsnummer</mat-label>
        <input
          type="text"
          matInput
          [formControl]="membershipNumberFormControl"
          placeholder="Mitgliedsnummer"
          readonly
        />
      </mat-form-field>

      <mat-form-field
        class="input-field"
        *ngIf="
          this.commonFunctions.isPropAvailable(
            this.userData,
            'state',
            'membership'
          )
        "
      >
        <mat-label>Status</mat-label>
        <input
          type="text"
          matInput
          [formControl]="stateFormControl"
          placeholder="Status"
          readonly
        />
      </mat-form-field>

      <mat-form-field
        class="input-field"
        *ngIf="
          this.commonFunctions.isPropAvailable(
            this.userData,
            'type',
            'membership'
          )
        "
      >
        <mat-label>Typ</mat-label>
        <input
          type="text"
          matInput
          [formControl]="typeFormControl"
          placeholder="Typ"
          readonly
        />
      </mat-form-field>
    </div>
  </form>

  <!-- Remove -->
  <!-- <button
      *ngIf="customImageSet"
      type="submit"
      class="button"
      (click)="removeUploadedFile()"
    >
      Bild löschen
    </button> -->
</div>
