<mat-accordion>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState"
    [ngClass]="getPanelColorClass()"
    class="expansion-panel"
  >
    <mat-expansion-panel-header
      [ngClass]="getHeaderColorClass()"
      style="height: auto"
    >
      <mat-panel-title style="display: flow">
        <p style="margin-top: 15px; font-size: 1.2rem">
          <b>{{ getTitleText().title }}</b>
        </p>
        <p
          class="header-panel-text"
          *ngIf="
            getTitleText().amountParticipants &&
            getTitleText().amountMinParticipants
          "
        >
          Teilnehmer: {{ getTitleText().amountParticipants }} /
          {{ getTitleText().amountMinParticipants }}
        </p>
        <p class="header-panel-text" *ngIf="getTitleText().dayName">
          {{ getTitleText().dayName }}
        </p>
        <p class="header-panel-text" *ngIf="getTitleText().timeOfShift">
          {{ getTitleText().timeOfShift }} Uhr
        </p>
        <p class="header-panel-text" *ngIf="getTitleText().state">
          - {{ getTitleText().state }} -
        </p>
        <p></p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-dvider></mat-dvider>

    <mat-divider></mat-divider>
    <table *ngIf="isVisible(visibility.FullInfoTable)">
      <tr>
        <td class="tbl-icons"><mat-icon>play_arrow</mat-icon></td>
        <td class="tbl-content">{{ getRunningState() }}</td>
      </tr>
      <tr *ngIf="isVisible(visibility.ConstructionDismantlingTableRows)">
        <td class="tbl-icons"><mat-icon>calendar_month</mat-icon></td>
        <td class="tbl-content">
          {{ data.start?.seconds * 1000 | date : "dd.MM.yyyy" }} -
          {{ data.end?.seconds * 1000 | date : "dd.MM.yyyy" }}
        </td>
      </tr>
      <tr *ngIf="isVisible(visibility.ConstructionDismantlingTableRows)">
        <td class="tbl-icons"><mat-icon>schedule</mat-icon></td>
        <td class="tbl-content">
          {{ data.start?.seconds * 1000 | date : "H:mm" }} -
          {{ data.end?.seconds * 1000 | date : "H:mm" }} Uhr
        </td>
      </tr>
      <tr>
        <td class="tbl-icons"><mat-icon>person</mat-icon></td>
        <td class="tbl-content">
          {{
            data.responsiblePerson == ""
              ? "nicht festgelegt"
              : data.responsiblePerson
          }}
        </td>
      </tr>
      <!-- <tr>
        <td class="tbl-icons"><mat-icon>title</mat-icon></td>
        <td class="tbl-content">
          {{ data.kind | wpKind }}
        </td>
      </tr> -->
      <tr *ngIf="isVisible(visibility.ParticipantsCount)">
        <td class="tbl-icons"><mat-icon>group</mat-icon></td>
        <td class="tbl-content">
          Teilnehmer (Anzahl: {{ data?.minParticipants }}):
        </td>
      </tr>
      <tr *ngIf="isVisible(visibility.Ue18Text)">
        <td class="tbl-icons"><mat-icon>18_up_rating</mat-icon></td>
        <td class="tbl-content">Nur für Erwachsene</td>
      </tr>
    </table>

    <table *ngIf="isVisible(visibility.SmallInfoTable)">
      <tr>
        <td colspan="2">Verantwortliche Person:</td>
      </tr>
      <tr>
        <td class="tbl-icons"><mat-icon>person</mat-icon></td>
        <td class="tbl-content">
          {{
            data.responsiblePerson == ""
              ? "nicht festgelegt"
              : data.responsiblePerson
          }}
        </td>
      </tr>
      <tr *ngIf="isVisible(visibility.Ue18Text)">
        <td class="tbl-icons"><mat-icon>18_up_rating</mat-icon></td>
        <td class="tbl-content">Nur für Erwachsene</td>
      </tr>
    </table>

    <div *ngIf="isVisible(visibility.ParticipantsComponents)">
      <div style="padding: 10px 00px 10px 0px; margin-left: -20px">
        <participants-view
          [data]="data?.participants"
          [event]="event"
          [workPackage]="data"
          [viewMode]="viewMode"
          (componentChanged)="onComponentChanged()"
        ></participants-view>
      </div>
      <p *ngIf="data?.participants?.length == 0">Keine Teilnehmer vorhanden</p>
    </div>

    <!-- Call shift organizer-->
    <div
      *ngIf="
        isVisible(visibility.CallShiftOrganizerButton) &&
        getShiftOrganizerTelNumber()
      "
    >
      <mat-divider></mat-divider>
      <div style="width: 100%; padding: 10px">
        <button
          mat-raised-button
          color="primary"
          matTooltip="Schichtleiter anrufen"
          (click)="callShiftOrganizer(getShiftOrganizerTelNumber())"
          style="width: 100%"
        >
          <mat-icon style="margin-right: 8px">call</mat-icon>
          Anrufen
        </button>
      </div>
    </div>

    <!-- Participate/Unparticipate -->
    <div *ngIf="isVisible(visibility.ParticipantJoin)">
      <mat-divider></mat-divider>
      <div style="width: 100%; padding: 10px">
        <button
          mat-raised-button
          style="width: 100%; color: white"
          [ngStyle]="{
            'background-color': isRegisteredForShift() ? 'red' : 'blue'
          }"
          (click)="
            isRegisteredForShift()
              ? unregisterForWorkPackage()
              : registerForWorkPackage()
          "
        >
          {{
            isRegisteredForShift()
              ? isConstructionOrDismantling() && isShiftRunning()
                ? "Schicht beenden"
                : "Abmelden"
              : "Anmelden"
          }}
        </button>
      </div>
    </div>

    <!-- QR Code scan to set user as present-->
    <div *ngIf="isVisible(visibility.ContrDismPresenceScanBtn)">
      <mat-divider></mat-divider>
      <div style="width: 100%; padding: 10px">
        <button
          mat-raised-button
          (click)="markUserAsPresent()"
          style="width: 100%; color: white; background-color: darkmagenta"
        >
          Anwesenheit melden
        </button>
      </div>
    </div>

    <!-- Participate/Unparticipate Hints -->
    <div *ngIf="isVisible(visibility.ParticipantJoinHints)">
      <mat-divider></mat-divider>
      <div style="width: 100%; padding: 10px; padding-top: 20px">
        <!--show hint(s) for user-->
        <p *ngIf="isRegisteredForShift() && isShiftRunning()">
          Bitte melde dich beim Schichtverantwortlichen wenn du dich abmelden
          willst.
        </p>
        <!-- <p *ngIf="!isRegisteredForShift() && !isShiftRunning()">
          Die Schicht wurde noch nicht zur Anmeldung freigegeben
        </p> -->
        <p
          *ngIf="
            data.isUe18 && !common.isOver18(userData?.personalData?.birthDate)
          "
        >
          Diese Schicht ist nur für Erwachsene
        </p>
      </div>
    </div>

    <mat-divider></mat-divider>
    <mat-card-footer>
      <button
        mat-icon-button
        class="footer-icon"
        aria-label="QR Code anzeigen"
        matTooltip="QR-Code anzeigen"
        (click)="showQrCodeForPresenceMarker()"
        [disabled]="data?.processedInBackend"
        *ngIf="
          isVisible(visibility.QrCodeFooter) && isConstructionOrDismantling()
        "
      >
        <mat-icon>qr_code_2</mat-icon>
      </button>

      <button
        mat-icon-button
        style="margin-left: 20px"
        class="footer-icon"
        aria-label="bearbeiten"
        matTooltip="Bearbeiten"
        (click)="edit()"
        [disabled]="event?.processedInBackend"
        *ngIf="isVisible(visibility.EditButton)"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        mat-icon-button
        class="footer-icon"
        aria-label="loeschen"
        matTooltip="Löschen"
        (click)="delete()"
        [disabled]="event?.processedInBackend"
        *ngIf="isVisible(visibility.DeleteButton)"
      >
        <mat-icon>delete</mat-icon>
      </button>

      <button
        mat-icon-button
        class="footer-icon"
        aria-label="Teilnehmer bearbeiten"
        matTooltip="Teilnehmer bearbeiten"
        (click)="showParticipantsEditDialog()"
        [disabled]="event?.processedInBackend"
        *ngIf="isVisible(visibility.AddButton)"
      >
        <mat-icon>add</mat-icon>
      </button>

      <mat-slide-toggle
        class="footer-icon"
        style="position: absolute; left: 10px; padding-top: 10px"
        matTooltip="Zur Anmeldung freigeben"
        color="primary"
        (change)="changeReleaseForUsers($event)"
        [checked]="releaseForUsersChecked"
        [disabled]="event?.processedInBackend"
        *ngIf="isVisible(visibility.FreigabeTooggle)"
        >Freigabe</mat-slide-toggle
      >

      <div
        style="width: 100%; padding: 10px"
        *ngIf="isVisible(visibility.ButtonStartConstrDismant)"
      >
        <button
          mat-button
          style="width: 100%; color: white"
          [ngStyle]="{ 'background-color': data.running ? 'red' : 'blue' }"
          aria-label="Starten"
          matTooltip="Starten"
          (click)="startEndConstructionDismantling(data.kind)"
          [disabled]="event?.processedInBackend"
        >
          {{ data.kind | wpKind }} {{ data.running ? "beenden" : "starten" }}
        </button>
      </div>
      <div
        style="width: 100%; padding: 10px"
        *ngIf="isVisible(visibility.QrCodeButton)"
      >
        <button
          mat-button
          style="width: 100%; color: white; background-color: blue"
          aria-label="QR Code anzeigen"
          matTooltip="QR Code anzeigen"
          (click)="showQrCodeForPresenceMarker()"
          [disabled]="event?.processedInBackend"
        >
          QR Code anzeigen
        </button>
      </div>
    </mat-card-footer>
  </mat-expansion-panel>
</mat-accordion>
