import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import {
  IEvent,
  EventViewMode,
  IComponentChanged,
  DateGroupedWorkPackages,
  Sub_Participant,
  User,
  IWorkPackage,
} from 'src/app/services/definitions.service';
import { LogService } from 'src/app/services/log.service';
import { ThreadSafeService } from 'src/app/services/thread-safe-component-state.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'grouped-work-packages',
  templateUrl: './grouped-work-packages.component.html',
  styleUrls: ['./grouped-work-packages.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [ThreadSafeService], // Provide the service here
})
export class GroupedWorkPackagesComponent implements OnInit {
  @Input() data: DateGroupedWorkPackages;
  @Input() users: User[];
  @Input() accountingYear: any;
  @Input() userOptions: any[];
  @Input() event: IEvent;
  @Input() viewMode: EventViewMode;
  @Output() componentChanged = new EventEmitter<IComponentChanged>();
  @Output() componentLoaded = new EventEmitter<boolean>();
  @Output() doReload = new EventEmitter();

  public enumViewMode = EventViewMode;
  public date: Date;
  public dayOfWeek: string;
  public viewData: DateGroupedWorkPackages;
  public groupedWps: any[] = [];
  public showOverall: boolean = true;
  public newDate: Date = new Date();
  private userData: User;
  private routeSub: Subscription;
  private debugModeOn: boolean = false;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  //Info: Das Init wird nur ein mal gecalled, aber die Methode update() wird mehrfach gecalled.
  //Das liegt daran, dass es fuer jedes Datum ein neues DateGroupedWorkPackages-Objekt gibt.
  //Das laesst sich nicht so richtig mit dem ThreadSafeService verbinden, da der component state immer ueberschrieben wird.
  //Liegt eher am schlechten Design der Komponente. Da aber 'fast' alle Daten der Komponente bereits vorher geladen wurden,
  //ist das ok so.

  ngOnInit(): void {
    //enable debug mode if set in url param
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.debugModeOn = params['debug'] === 'true';
    });
  }

  ngAfterViewInit() {
    if (this.data) {
      this.update();
    }
  }

  // Loads all participants
  private async loadParticipantsData() {
    if (this.data && this.data.workPackages) {
      this.data.workPackages.forEach(async (element) => {
        var path = `${element.path}/${Sub_Participant()}`;

        var loaded = await this.dataModel.get(path, false, true);
        if (!loaded.result) {
          this.common.showErrorToast('Teilnehmer konnten nicht geladen werden');
          this.log.error(loaded.errorMessage);
        } else {
          element.participants = loaded.data;
        }
      });
    }
  }

  //Decide if a shift shall be shown
  public showWp(wp: IWorkPackage): boolean {
    //at this point in time, no work packages are loaded. Return true to allow loading and then decide if to hide
    if (wp && wp.participants == undefined) {
      return true;
    }
    return this.common.showWpView(wp, this.viewMode, this.userData.uid);
  }

  //If all shifts decide to hide (for whatever reason), set this grouped work package as full
  private decideIfAllShiftsFull(wps: IWorkPackage[]) {
    //if all work packages decide to hide, hide the whole group
    var all = wps.every(
      (wp) => !this.common.showWpView(wp, this.viewMode, this.userData.uid)
    );
    if (all) {
      // if (this.debugModeOn)
      //   console.log(
      //     '(GWP) Group will not show'
      //   );
      this.showOverall = false;
    } else {
      // if (this.debugModeOn)
      //   console.log('(GWP) Group can be shown');
      this.showOverall = true;
    }
    return this.showOverall;
  }

  public getDayName(date: Date) {
     return this.common.getDayName(date);
  }

  public wpChanged() {
    this.doReload?.emit(true);
    this.update();
    //propagate to upward component
    this.componentChanged?.emit();
  }

  //To avoid: Expression has changed after it was checked
  public getDate(): Date {
    if (this.viewData) return this.viewData.date;
    return new Date();
  }

  private async update() {
    await this.loadParticipantsData();

    //To avoid: Expression has changed after it was checked
    setTimeout(() => {
      this.viewData = this.data;
      //sort by type
      this.groupedWps = _.values(_.groupBy(this.viewData.workPackages, 'type'));

      this.decideIfAllShiftsFull(this.data.workPackages);

      // console.log('(GWP) Emit loaded for group: ' + this.data.date.toDateString());
      this.componentLoaded.emit(true);
    }, 500);
  }

  //Called when sub compoenent is loaded
  public wpViewLoaded(workPackage: IWorkPackage) {
    // console.log('(GWP): sub comp. wpView loaded: '+ workPackage.id);
  }

  //show debug info about a single work package
  public showWpDebugInfo(wp: IWorkPackage) {
    if (!this.debugModeOn) {
      return '';
    }
    return this.common.showWpDebugInfo(wp, this.userData.uid, this.viewMode);
  }

  //Show debug info about the grouped work package
  public showGWpDebugInfo() {
    if (!this.debugModeOn) {
      return '';
    }
    return `Decision to show overall: ${this.showOverall}`;
  }

  public isDebugMode(): boolean{
    return this.debugModeOn;
  }
}
