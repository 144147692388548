import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Event {
  start: Date;
  end: Date;
  title: string;
  type: string;
}

@Component({
  selector: 'app-event-dialog',
  templateUrl: './event-dialog.component.html',
})
export class EventDialogComponent {
  eventTitle: string;
  eventStartDate: Date;
  eventStartTime: string;
  eventEndDate: Date;
  eventEndTime: string;
  eventType: string;
  eventTypes: string[] = ['meeting', 'task', 'reminder'];

  constructor(
    public dialogRef: MatDialogRef<EventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.eventStartDate = data.date;
    this.eventEndDate = data.date;
    if (data.event) {
      this.eventTitle = data.event.title;
      this.eventStartDate = new Date(data.event.start);
      this.eventEndDate = new Date(data.event.end);
      this.eventStartTime = this.formatTime(this.eventStartDate);
      this.eventEndTime = this.formatTime(this.eventEndDate);
      this.eventType = data.event.type;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAdd(): void {
    if (this.eventTitle && this.eventStartDate && this.eventEndDate && this.eventType) {
      const startDate = this.combineDateAndTime(this.eventStartDate, this.eventStartTime);
      const endDate = this.combineDateAndTime(this.eventEndDate, this.eventEndTime);
      this.dialogRef.close({ 
        title: this.eventTitle, 
        start: startDate, 
        end: endDate,
        type: this.eventType
      });
    }
  }

  private formatTime(date: Date): string {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  private combineDateAndTime(date: Date, time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);
    const combinedDate = new Date(date);
    combinedDate.setHours(hours, minutes, 0, 0);
    return combinedDate;
  }
}
