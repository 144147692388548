<div class="prompt" *ngIf="data.mobileType === 'android'">
  <p class="title-text">Installieren</p>
  <p class="subtitle-text">
    Klicke hier um die App auf deinem Handy zu installieren
  </p>

  <button
    mat-raised-button
    color="primary"
    class="install-button"
    (click)="installPwa()"
  >
    App installieren
  </button>
</div>

<div *ngIf="data.mobileType === 'ios'" class="prompt-ios">
  <p class="title-text">Installieren</p>
  <p class="subtitle-text">
    Um diese App zu installieren, klicken auf den Menü-button und auf 'Zum
    Home-Bildschirm'
  </p>

  <table style="width: 100%; margin-top: 30px">
    <tr>
      <td style="padding-bottom: 20px; font-size: 2rem">1.</td>
      <td style="padding-bottom: 20px">
        <img src="./../../assets/images/ios_share_tab.png" class="ios-images" />
      </td>
    </tr>
    <tr>
      <td style="font-size: 2rem">2.</td>
      <td>
        <img
          src="./../../assets/images/ios_add_to_home_screen.png"
          class="ios-images"
        />
      </td>
    </tr>
  </table>

  <!-- <div
    style="
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    "
  >
    <img src="./assets/icons/ios-share-button.png" />
    <mat-icon>navigate_next</mat-icon>
    <img
      style="padding-top: 6px"
      src="./assets/icons/ios-add-to-home-screen.png"
    />
  </div> -->
</div>
