import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserGroupEntity } from './definitions.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(public router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRole: UserGroupEntity[] = route.data.expectedRole;
    var hasAccess = this.authService.hasRightToAccess(expectedRole, route);
    return hasAccess;
  }
}
