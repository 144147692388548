import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { HostListener } from '@angular/core';
import {
  BottomSheetData,
  ViewDataType,
  BottomSheetComponent,
} from '../bottom-sheet/bottom-sheet.component';
import { AuthService } from '../services/auth.service';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import { DataModelService } from '../services/data-model.service';
import {
  PointTransfer,
  PointTransferTransaction,
  Properties,
  TransferState,
  User,
} from '../services/definitions.service';

@Component({
  selector: 'app-point-receive',
  templateUrl: './point-receive.component.html',
  styleUrls: ['./point-receive.component.css'],
})
export class PointReceiveComponent implements OnInit {
  private pointTransferCollectionSub: Subscription;
  public scannerEnabled: boolean = true;
  public camInitialized: boolean = false;
  private userData: User;
  private transactionId: string = '';
  public userSpecificFinishedTransfers: PointTransfer[] = [];
  public showAskPermissionButton: boolean = false;
  public foundCameras: any[];
  private currentCamIx: number = 0;
  private readonly KEY_CAM_IX: string = 'last_camera_ix';
  private scanAllowed: boolean = true;
  private transactionResult: PointTransferTransaction = {
    done: false,
    succeed: false,
    message: '',
  };

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  constructor(
    private commonFunctions: CommonFunctionsService,
    private firestore: AngularFirestore,
    private dataModelService: DataModelService,
    private authService: AuthService,
    private bottomSheet: MatBottomSheet,
    private spinner: NgxSpinnerService
  ) {
    // Assign the data to the data source for the table to render
    this.commonFunctions.registerEventEmitterHandlerUserData((data: User) => {
      this.userData = data;
    });
    this.scannerEnabled = true;
  }

  ngOnInit(): void {
    this.subscribeOnCollectionChange();
  }

  ngOnDestroy() {
    this.unsubscribeEventEmitterListener();
    this.scannerEnabled = false;
    this.scanner?.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.scanner.reset();
    this.scanner.restart();
    this.askForPermission();
  }

  public scanSuccess(result: string) {}

  public scanFailure(event) {}

  public scanError(err: any) {}

  public scanComplete(val: any) {
    if (val && this.scanAllowed) {
      this.scanAllowed = false;
      var data = val.text;
      if (data) {
        var splitted = data.split(';');
        //check consistency
        if (splitted.length == 4) {
          var senderUserId = splitted[0];
          var senderUserName = splitted[1];
          var amountPoints = splitted[2];
          var comment = splitted[3];
          var receiverUserId = this.authService.getCurrentUserId();
          var receiverUserName = this.commonFunctions.getPrettyPrintedUserName(
            this.userData
          );

          //check
          if (senderUserId == receiverUserId) {
            this.commonFunctions.showErrorToast(
              'Sender und Empfänger dürfen nicht identisch sein'
            );
            return;
          }

          //set scan threshold
          this.scanThreshold();

          //create a new points transfer entry
          this.createPointsEntry(
            senderUserId,
            senderUserName,
            receiverUserId,
            receiverUserName,
            amountPoints,
            comment,
            TransferState.PENDING
          );
        } else {
          this.commonFunctions.showErrorToast('Ungültiger QR-Code');
        }
      } else {
        this.commonFunctions.showErrorToast('Ungültiger QR-Code');
      }
    }
  }

  //Creates a new entry in the point transfer collection
  private createPointsEntry(
    senderUserId: string,
    senderUserName: string,
    receiverUserId: string,
    receiverUserName: string,
    amountPoints: number,
    comment: string,
    state: string
  ) {
    this.dataModelService
      .createPointTransferEntry(
        senderUserId,
        senderUserName,
        receiverUserId,
        receiverUserName,
        amountPoints,
        comment,
        state
      )
      .then(
        (res) => {
          if (res) {
            this.transactionId = res;
            this.showWaitingDialog();
          } else {
            this.commonFunctions.showErrorToast(
              'Transfer-Punkte konnten nicht gespeichert werden.'
            );
            this.transactionId = '';
          }
        },
        (rejected) => {
          this.commonFunctions.showErrorToast(
            'Transfer-Punkte konnten nicht gespeichert werden: ' + rejected
          );
          this.transactionId = '';
        }
      )
      .catch((error) => {
        this.commonFunctions.showErrorToast(
          'Transfer-Punkte konnten nicht gespeichert werden: ' + error
        );
        this.transactionId = '';
      });
  }

  //Shows a waiting dialog
  private showWaitingDialog() {
    this.commonFunctions.showWaitingDialog(
      'Warte auf Bestätigung',
      15000,
      () => {
        //cancellation check
        return (
          this.transactionResult != undefined && this.transactionResult.done
        );
      },
      () => {
        //timeout occurred
        //auf state declined setzen
        var errMsg = 'Konnte status nicht in der Datenbank speichern';
        this.dataModelService
          .updateTransferEntryState(this.transactionId, TransferState.DECLINED)
          .then(
            () => {
              //intentionally empty
            },
            (err) => {
              this.commonFunctions.showErrorToast(errMsg);
            }
          )
          .catch(() => {
            this.commonFunctions.showErrorToast(errMsg);
          });
        this.transactionResult = this.createTransactionResult(
          true,
          false,
          'Transaktion abgelaufen'
        );
        this.transactionId = '';
        this.commonFunctions.showErrorToast(this.transactionResult.message);
      },
      () => {
        //On Finished
        if (this.transactionResult != undefined) {
          if (this.transactionResult.done) {
            if (this.transactionResult.succeed) {
              //success
              this.commonFunctions.showSuccessToast(
                'Punkte-Transfer erfolgreich'
              );
            } else {
              //not successful
              this.commonFunctions.showErrorToast(
                this.transactionResult.message
              );
            }
          }
        }
        //reset
        this.transactionResult = undefined;
        this.transactionId = '';
      }
    );
  }

  //Listens to changes on the point transfer collection
  private subscribeOnCollectionChange() {
    var thisUsersId = this.authService.getCurrentUserId();
    this.pointTransferCollectionSub = this.firestore
      .collection(PointTransfer())
      .valueChanges({ idField: 'id' })
      .subscribe(
        (data: any[]) => {
          if (data) {
            var allTransfers: PointTransfer[] = data;

            //get all finished (for showing history)
            this.userSpecificFinishedTransfers = allTransfers.filter(
              (x) =>
                x.receiverUserId == thisUsersId &&
                x.state == TransferState.FINISHED
            );

            //get all with this id
            var allToCheck = allTransfers.filter(
              (x) => x.id == this.transactionId
            );

            allToCheck.forEach((element) => {
              //checke id der transaktion passt und ob der state auf finished ist
              if (element.id == this.transactionId) {
                if (element.state == TransferState.FINISHED) {
                  this.transactionResult = this.createTransactionResult(
                    true,
                    true
                  );
                } else if (element.state == TransferState.DECLINED) {
                  this.transactionResult = this.createTransactionResult(
                    true,
                    false,
                    'Transaktion vom Sender abgelehnt'
                  );
                }
              }
            });
          }
        },
        (error) => {
          this.commonFunctions.showErrorToast(error, 7000);
        }
      );
  }

  private unsubscribeEventEmitterListener() {
    if (this.pointTransferCollectionSub)
      this.pointTransferCollectionSub.unsubscribe();
  }

  // Shows the points history in the bottom sheet
  public showPointsHistory() {
    //sort data
    this.userSpecificFinishedTransfers.sort((a, b) =>
      a[Properties.Timestamp] < b[Properties.Timestamp] ? 1 : -1
    );
    let data = new BottomSheetData(
      this.userSpecificFinishedTransfers,
      ViewDataType.POINT_TRANSFER_RECEIVER
    );
    this.bottomSheet.open(BottomSheetComponent, {
      data: data,
    });
  }

  // asks for the permission to use the camera
  public async askForPermission() {
    var failed: boolean = false;
    var error = '';
    //Ask for permission
    await navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        /* use the stream */
      })
      .catch(function (err) {
        /* handle the error */
        failed = true;
        error = err;
      });
    if (failed) {
      this.commonFunctions.showErrorToast(
        'Keine Berechtigung zur Nutzung der Kamera'
      );
    }
  }

  //handles the click on 'Kein Kamerabild sichtbar?' icon-button
  public showInfoDialog() {
    this.commonFunctions.openDialog(
      'Info',
      'Versuche die Kamera zu wechseln. Ein reload der Seite kann in manchen Fällen auch helfen. Solltest du ein IPhone besitzen, so funktioniert die Kamera erst ab der iOS Version 14.3.',
      DialogType.INFO
    );
  }

  //Handle the event which includes all available cameras
  public camerasFoundHandler(event) {
    this.foundCameras = event;
    this.handleCamInit();
  }

  //Handles the initiating phase of the camera
  private async handleCamInit() {
    if (!this.camInitialized) {
      this.camInitialized = true;
      var lastIx = localStorage.getItem(this.KEY_CAM_IX);
      if (lastIx) {
        var ixNum = parseInt(lastIx);

        await this.delay(3000);
        // this.commonFunctions.showSuccessToast(
        //   'Restoring cam settings to: ' + this.foundCameras[ixNum].label
        // );
        this.scanner.reset();
        this.scanner.device = this.foundCameras[ixNum];
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }
  }

  //Wait a specific delay
  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  //Handle the event when no camera is available
  public camerasNotFoundHandler(event) {
    this.commonFunctions.showErrorToast('Keine Kamera vorhanden');
    this.spinner.hide();
  }

  //handles camera device change event
  public deviceHasChanged(event) {
    // console.log('Device has changed: ' + event?.label);
  }

  //changes the camera device
  public changeCamera() {
    if (this.foundCameras) {
      var ix = this.currentCamIx + 1;
      if (ix > this.foundCameras.length - 1) {
        ix = 0;
      }
      this.currentCamIx = ix;
      this.scanner.reset();
      this.scanner.device = this.foundCameras[ix];
      //save last selection
      localStorage.setItem(this.KEY_CAM_IX, ix.toString());
    }
  }

  //Disables a the scan to suppress multiple db entry creation
  private scanThreshold() {
    setTimeout(() => {
      this.scanAllowed = true;
    }, 5000);
  }

  //Listens on back button click
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    //abort transaction
    this.transactionResult = this.createTransactionResult(
      true,
      false,
      'Transaktion abgebrochen'
    );
    //auf declined setzen
    if (this.transactionId) {
      var errMsg = 'Konnte status nicht in der Datenbank speichern';
      this.dataModelService
        .updateTransferEntryState(this.transactionId, TransferState.DECLINED)
        .then(
          () => {},
          () => {
            this.commonFunctions.showErrorToast(errMsg);
          }
        )
        .catch(() => {
          this.commonFunctions.showErrorToast(errMsg);
        });
    }
  }

  private createTransactionResult(
    finished: boolean,
    result: boolean,
    message: string = ''
  ): PointTransferTransaction {
    return { done: finished, succeed: result, message: message };
  }

  //Get data from video element -------------------------
  /*
  //install npm package: extendable-media-recorder
  //in den constructor: private elRef: ElementRef

      var tdsdsdds = this.elRef.nativeElement.getElementsByTagName('video');
      if (tdsdsdds) {
        var native = tdsdsdds[0];
        const captureStream = native.captureStream();

        // use MediaStream Recording API
        this.mediaRecorder = new MediaRecorder(captureStream);

        this.mediaRecorder.start(500);

        this.mediaRecorder.ondataavailable = (data) => {
          var reader = new FileReader();
          var blob = data.data;

          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var result = reader.result;
            console.log('got data: ' + result);
          };

          setTimeout(() => {
            this.mediaRecorder.stop();
          }, 50);
        };
      }
  */
}
