import { Pipe, PipeTransform } from '@angular/core';
import { EncouragementPointEntryAction } from '../services/data-model.service';

@Pipe({
  name: 'encPointEntryAction',
})
export class EncPointEntryActionPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case EncouragementPointEntryAction.ADD:
        if (args && args[0] && args[0] == '2') return 'Hinzufügen';
        else return 'Hinzugefügt';
      case EncouragementPointEntryAction.REMOVE:
        if (args && args[0] && args[0] == '2') return 'Entnehmen';
        else return 'Entnommen';
      default:
        return 'Unbekannt';
    }
  }
}
