<h1 mat-dialog-title>{{ data.dialogTitle }}</h1>
<hr style="opacity: 0.2" />
<div mat-dialog-content>
  <form [formGroup]="myForm">
    <!--User selection-->
    <mat-form-field class="form-fields" *ngIf="isMobile">
      <mat-label>Namen eingeben...</mat-label>
      <input
        type="text"
        placeholder=""
        aria-label="Namen eingeben..."
        matInput
        [formControl]="userSelectorFormControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelectedUser($event.option.value)"
      >
        <mat-option
          *ngFor="let option of filteredNameOptions | async"
          [value]="option"
        >
          {{ option.personalData?.firstName }} {{ option.personalData?.lastName
          }} ({{ option.email }})
        </mat-option>
      </mat-autocomplete>
      <button
        *ngIf="userSelectorFormControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="userSelectorFormControl.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div *ngIf="dataLoaded">
      <!--Entry Action-->
      <mat-form-field class="form-fields">
        <mat-label>Art</mat-label>
        <mat-select formControlName="entryAction">
          <mat-option
            *ngFor="let item of wheA"
            [value]="item"
            (onSelectionChange)="optionSelected($event)"
          >
            {{ item | encPointEntryAction : '2' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--Receiver selection-->
      <mat-form-field class="form-fields" *ngIf="removePointsActive">
        <mat-label>Empfänger</mat-label>
        <input
          type="text"
          placeholder=""
          aria-label="auswählen..."
          matInput
          [formControl]="receiverUserSelectorFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filteredNameOptionsReceiver | async"
            [value]="option"
          >
            {{ option.personalData?.firstName }} {{
            option.personalData?.lastName }} ({{ option.email }})
          </mat-option>
        </mat-autocomplete>
        <button
          *ngIf="receiverUserSelectorFormControl.value"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="receiverUserSelectorFormControl.setValue('')"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!--Amount-->
      <mat-form-field class="form-fields">
        <mat-label>{{getAmountText(data.maxAmount)}}</mat-label>
        <input
          matInput
          type="number"
          placeholder=""
          [formControl]="amountFormControl"
          min="1"
        />
        <mat-error *ngIf="amountFormControl.invalid"
          >Es können nur {{data.maxAmount}} Punkte entnommen werden</mat-error
        >
      </mat-form-field>

      <!--Comment-->
      <mat-form-field class="form-fields">
        <mat-label>Kommentar</mat-label>
        <input matInput [formControl]="commentFormControl" />
      </mat-form-field>

      <!--Point release date-->
      <mat-form-field
        class="form-fields"
        style="padding-bottom: 0px; padding-top: 5px"
        *ngIf="!removePointsActive"
      >
        <mat-label>Punktfreigaben-Datum wählen</mat-label>
        <input
          matInput
          [min]="minDate"
          [max]="maxDate"
          [matDatepicker]="picker"
          [formControl]="releaseDateFormControl"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker touchUi #picker [startAt]="startDate"></mat-datepicker>
      </mat-form-field>

      <!--Tags-->
      <mat-form-field class="form-fields">
        <mat-label>Stichworte</mat-label>
        <input
          matInput
          [formControl]="tagsFormControl"
          placeholder="Bsp.: Turnierplatzierung, Ehrungspunkte"
        />
      </mat-form-field>
    </div>
  </form>
</div>
<hr style="opacity: 0.2" />
<div mat-dialog-actions class="dialog-btn-container">
  <button
    mat-raised-button
    type="reset"
    class="dialog-btn"
    (click)="onNoClick()"
  >
    {{ data?.buttonNoName }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="dialog-btn"
    (click)="onYesClick()"
    [disabled]="!myForm.valid"
  >
    {{ data?.buttonYesName }}
  </button>
</div>
