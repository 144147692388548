import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

export enum NotiType {
  Error,
  Success,
  Info,
  Warn,
}

export class NotiSheetData {
  public text: string;
  public type: NotiType;
  public timeout: number;
  public permanent: boolean;

  constructor(
    text: string,
    type: NotiType,
    timeout: number,
    permanent: boolean = false
  ) {
    this.text = text;
    this.type = type;
    this.timeout = timeout;
    this.permanent = permanent;
  }
}

@Component({
  selector: 'app-noti-bottom-sheet',
  templateUrl: './noti-bottom-sheet.component.html',
  styleUrls: ['./noti-bottom-sheet.component.css'],
})
export class NotiBottomSheetComponent implements OnInit {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: NotiSheetData,
    private bottomSheetRef: MatBottomSheetRef<NotiBottomSheetComponent>
  ) {
    if (!data.permanent) {
      setTimeout(() => {
        this.bottomSheetRef.dismiss();
      }, data.timeout);
    }
  }

  ngOnInit(): void {}
}
