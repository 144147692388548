<mat-tab-group mat-align-tabs="center">
  <div class="form" style="padding-top: 40px">
    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()"> -->
    <!-- <mat-tab label="Persönliche Daten">
        <div class="center">
          <mat-form-field class="input-field">
            <mat-label>Vorname</mat-label>
            <input
              type="text"
              matInput
              [formControl]="firstNameFormControl"
              placeholder="Vorname"
              readonly
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Nachname</mat-label>
            <input
              type="text"
              matInput
              [formControl]="lastNameFormControl"
              placeholder="Nachname"
              readonly
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Geburtstag</mat-label>
            <input
              type="text"
              matInput
              [formControl]="birthdayFormControl"
              placeholder="Geburtstag"
              readonly
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Strasse</mat-label>
            <input
              type="text"
              matInput
              [formControl]="streetFormControl"
              placeholder="Strasse"
              readonly
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Hausnummer</mat-label>
            <input
              type="text"
              matInput
              [formControl]="houseNumberFormControl"
              placeholder="Hausnummer"
              readonly
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Plz</mat-label>
            <input
              type="text"
              matInput
              [formControl]="plzFormControl"
              placeholder="Plz"
              readonly
            />
          </mat-form-field>

          <mat-form-field class="input-field">
            <mat-label>Stadt</mat-label>
            <input
              type="text"
              matInput
              [formControl]="cityFormControl"
              placeholder="Stadt"
              readonly
            />
          </mat-form-field>
        </div>
      </mat-tab> -->

    <!-- <mat-tab label="Kontakinformationen">
        <div class="center">
          <mat-form-field class="input-field">
            <mat-label>E-Mail</mat-label>
            <input
              type="email"
              matInput
              [formControl]="emailFormControl"
              placeholder="example@web.com"
              readonly
            />
          </mat-form-field>

          <mat-form-field
            class="input-field"
            *ngIf="
              this.commonFunctions.isPropAvailable(
                this.userData,
                'emailCompany',
                'contact'
              )
            "
          >
            <mat-label>E-Mail Geschäftlich</mat-label>
            <input
              type="email"
              matInput
              [formControl]="emailCompanyFormControl"
              placeholder="example@web.com"
              readonly
            />
          </mat-form-field>

          <mat-form-field
            class="input-field"
            *ngIf="
              this.commonFunctions.isPropAvailable(
                this.userData,
                'emailPrivate',
                'contact'
              )
            "
          >
            <mat-label>E-Mail Privat</mat-label>
            <input
              type="email"
              matInput
              [formControl]="emailPrivateFormControl"
              placeholder="example@web.com"
              readonly
            />
          </mat-form-field>

          <mat-form-field
            class="input-field"
            *ngIf="
              this.commonFunctions.isPropAvailable(
                this.userData,
                'telPrivate',
                'contact'
              )
            "
          >
            <mat-label>Telefon Privat</mat-label>
            <input
              type="tel"
              maxlength="20"
              matInput
              [formControl]="telPrivateFormControl"
              placeholder="+49 152 1111 2222"
              #postalCodePrivate
              readonly
            />
          </mat-form-field>

          <mat-form-field
            class="input-field"
            *ngIf="
              this.commonFunctions.isPropAvailable(
                this.userData,
                'telCompany',
                'contact'
              )
            "
          >
            <mat-label>Telefon Geschäftlich</mat-label>
            <input
              type="tel"
              maxlength="20"
              matInput
              [formControl]="telCompanyFormControl"
              placeholder="+49 152 1111 2222"
              #postalCodeCompany
              readonly
            />
          </mat-form-field>

          <mat-form-field
            class="input-field"
            *ngIf="
              this.commonFunctions.isPropAvailable(
                this.userData,
                'telMobile',
                'contact'
              )
            "
          >
            <mat-label>Telefon Mobil</mat-label>
            <input
              type="tel"
              maxlength="20"
              matInput
              [formControl]="telMobileFormControl"
              placeholder="+49 152 1111 2222"
              #postalCodeMobile
              readonly
            />
          </mat-form-field>
        </div>
      </mat-tab> -->

    <mat-tab label="Verschiedenes">
      <table style="width: 100%; text-align: center">
        <tr>
          <td>
            <button
              class="button"
              (click)="sendLogsToDev()"
            >
              Log-files senden
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              class="button"
              (click)="clearLogs()"
            >
              Log-files löschen
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              class="button"
              (click)="tryRegisterPushNoti()"
            >
              Notifications empfagen
            </button>
          </td>
        </tr>
        <tr>
          <td>
            <button
              class="button"
              (click)="logout()"
            >
              Ausloggen
            </button>
          </td>
        </tr>
      </table>
    </mat-tab>
    <!-- </form> -->
  </div>
</mat-tab-group>
