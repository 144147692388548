import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from './services/definitions.service';

@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  //Event emitter
  userDataLoaded = new EventEmitter();
  workHoursDataLoaded = new EventEmitter();
  encouragementPointsDataLoaded = new EventEmitter();
  protocolWorkHoursDataLoaded = new EventEmitter();
  accountingYearDataLoaded = new EventEmitter();

  //Subscriptions
  subsLoadedUserData: Subscription;
  subsLoadedWorkHoursData: Subscription;
  subsLoadedEncouragementPointsData: Subscription;
  subsLoadedProtocolWorkHoursData: Subscription;
  subsLoadedAccountingYearData: Subscription;

  //cached data
  public cachedUserData: User;
  public cachedWorkHoursData: any;
  public cachedEncouragementPointsData: any;
  public cachedProtocolWorkHoursData: any;
  public cachedAccountingYearData: any;

  constructor() {}

  callUserDataLoaded(data: User) {
    this.userDataLoaded.emit(data);
    this.cachedUserData = data;
  }
  callWorkHoursDataLoaded(data: any) {
    this.workHoursDataLoaded.emit(data);
    this.cachedWorkHoursData = data;
  }
  callEncouragementPointsDataLoaded(data: any) {
    this.encouragementPointsDataLoaded.emit(data);
    this.cachedEncouragementPointsData = data;
  }
  callProtocolWorkHoursDataLoaded(data: any) {
    this.protocolWorkHoursDataLoaded.emit(data);
    this.cachedProtocolWorkHoursData = data;
  }
  callAccountingYearDataLoaded(data: any) {
    this.accountingYearDataLoaded.emit(data);
    this.cachedAccountingYearData = data;
  }

  public clearAllData() {
    this.cachedAccountingYearData = undefined;
    this.cachedEncouragementPointsData = undefined;
    this.cachedProtocolWorkHoursData = undefined;
    this.cachedWorkHoursData = undefined;
    this.cachedUserData = undefined;

    this.subsLoadedUserData = undefined;
    this.subsLoadedWorkHoursData = undefined;
    this.subsLoadedEncouragementPointsData = undefined;
    this.subsLoadedProtocolWorkHoursData = undefined;
    this.subsLoadedAccountingYearData = undefined;
  }
}
