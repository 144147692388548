import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PromptComponent } from '../prompt-component/prompt-component.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CustomInstallPromptService {
  private promptEvent: any;

  //Source: https://medium.com/@oleksandr.k/a-way-to-show-a-prompt-to-install-your-angular-pwa-both-on-android-and-ios-devices-7a770f55c54
  constructor(
    private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) {}

  public initPwaPrompt() {
    //Note: implemented in LoginComponent
    // --------------------------------------------
    // if (this.platform.ANDROID) {
    //   window.addEventListener('beforeinstallprompt', (event: any) => {
    //     event.preventDefault();
    //     this.promptEvent = event;
    //     this.openPromptComponent('android');
    //   });
    // }
    // if (this.platform.IOS) {
    //   const isInStandaloneMode =
    //     'standalone' in window.navigator && window.navigator['standalone'];
    //   if (!isInStandaloneMode) {
    //     this.openPromptComponent('ios');
    //   }
    // }
  }

  // private async openPromptComponent(mobileType: 'ios' | 'android') {
  //   // await this.delay(2000);
  //   this.bottomSheet.open(PromptComponent, {
  //     data: { mobileType, promptEvent: this.promptEvent },
  //     disableClose: true,
  //   });
  // }

  // private delay(ms: number) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }
}
