import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { EncouragementPointEntryDialog } from '../dialogs/encouragement-point-entry/encouragement-point-entry';
import { AuthService } from '../services/auth.service';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import {
  DataModelService,
  WorkHourEntryAction,
  WorkHourEntryReason,
} from '../services/data-model.service';
import {
  Sub_EncouragementPoints,
  EncouragementPointEntryDialogIntention,
  EncouragementPointEntryDialogResult,
  UserDataTableView,
  User
} from '../services/definitions.service';
import { UniversalDialogDialogData } from '../universal-dialog/universal-dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-point-accounts-admin',
  templateUrl: './point-accounts-admin.component.html',
  styleUrls: ['./point-accounts-admin.component.css'],
})
export class PointAccountsAdminComponent implements OnInit {
  public userData: User; //muss public sein!
  userDataLoaded: boolean = false;
  myControl = new FormControl();
  options: User[] = [];
  filteredOptions: Observable<string[]>;
  filteredNameOptions: Observable<User[]>;
  allUsersData: User[];
  showTable: boolean;
  dataSource: MatTableDataSource<UserDataTableView>;
  clickedRows = new Set<UserDataTableView>();
  selection = new SelectionModel<UserDataTableView>(false, []);
  selectedUserData: any;
  selectedUsersEncouragementPoints: any;
  displayedColumns: string[] = [
    'select',
    'amount',
    'action',
    'comment',
    'doneby',
    'release',
    'timestamp',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dataModel: DataModelService,
    private commonFunctions: CommonFunctionsService,
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private router: Router
  ) {
    //check if in mobile mode
    if (this.deviceService.isMobile()) {
      commonFunctions
        .openDialog(
          'Achtung',
          'Diese Seite ist nicht für die Mobile Ansicht optimiert. Bitte öffne sie auf dem PC.',
          DialogType.INFO,
          'Abbrechen',
          'Fortfahren'
        )
        .afterClosed()
        .subscribe((result: UniversalDialogDialogData) => {
          if (result.result != 1) {
            this.router.navigateByUrl('/');
            return;
          }
        });
    }

    spinnerService.show();
    commonFunctions.registerEventEmitterHandlerUserData((userData) => {
      this.userData = userData;
      spinnerService.hide();
    });
    this.dataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    //Load all users data
    this.spinnerService.show();
    this.commonFunctions.loadAllUsers(
      (res: boolean, allUsersData: User[], options: User[]) => {
        if (res) {
          this.allUsersData = allUsersData;
          this.options = options;
          this.userDataLoaded = true;

          this.filteredNameOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );
        }
        this.spinnerService.hide();
      }
    );
  }

  //Filter input by name
  private _filter(value: string): User[] {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();

      return this.options.filter((option: User) =>
        this.displayFn(option).toLowerCase().includes(filterValue)
      );
    }
  }

  //Loads the data of the selected user
  public loadUserData() {
    if (!this.allUsersData) {
      this.commonFunctions.showErrorToast(
        'Userdaten konnten nicht geladen werden'
      );
      return;
    }

    var selectedUserId = this.getSelectedUserId();
    if (selectedUserId == null) {
      this.commonFunctions.showErrorToast('Ungültiger Eintrag ausgewählt');
      return;
    }
    this.showTable = true;
    this.spinnerService.show();

    //find selected user id in 'allUsersData'
    this.selectedUserData = this.allUsersData.find((x) => {
      return x.uid == selectedUserId;
    });

    //load encouragement points
    this.dataModel
      .getUserSubcollectionDoc(selectedUserId, Sub_EncouragementPoints())
      .then(
        (value) => {
          this.dataSource = new MatTableDataSource(value);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.selectedUsersEncouragementPoints = value;
        },
        (rejectedReason) => {
          this.commonFunctions.showErrorToast(
            'Förderpunkte konnten nicht geladen werden: ' + rejectedReason
          );
        }
      )
      .catch((error) => {
        this.commonFunctions.showErrorToast(
          'Förderpunkte konnten nicht geladen werden: ' + error
        );
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  public displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  //Action on click of button "add work hour"
  public buttonActionAdd() {
    var currentUserId = this.authService.getCurrentUserId();
    var selectedUserId = this.myControl.value?.uid;
    if (!selectedUserId) {
      this.commonFunctions.showErrorToast('Ungültiger Eintrag ausgewählt');
      return;
    }
    var destinationUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.myControl.value
    );
    if (!this.userData) {
      this.commonFunctions.showErrorToast('Du bist nicht eingeloggt');
      return;
    }
    var currentUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.userData
    );

    this.dialog
      .open(EncouragementPointEntryDialog, {
        width: '550px',
        data: {
          result: EncouragementPointEntryDialogResult.OK,
          dialogIntention: EncouragementPointEntryDialogIntention.ADD,
          dialogTitle: 'Förderpunkte hinzufügen/entnehmen',
          descriptionText: 'Bitte gib nun die Details ein',
          buttonNoName: 'Abbrechen',
          buttonYesName: 'Eintragen',
          comment: '',
          action: WorkHourEntryAction.ADD,
          reason: WorkHourEntryReason.STANDARD,
          amount: 0,
          maxAmount: this.getAvailablePointsCount(),
          currentUserId: currentUserId,
          currentUserName: currentUserName,
          destinationUserId: selectedUserId,
          destinationUserName: destinationUserName,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == 1) {
          //reload table data
          this.loadUserData();
        }
      });
  }

  //Action on click of button "remove"
  public buttonActionDeleteEntry() {
    var hasValue = this.selection.hasValue();

    if (!hasValue) {
      this.commonFunctions.showErrorToast('Kein Element ausgewählt');
      return;
    }

    var selectedUserId = this.getSelectedUserId();
    this.optionSelected
    if (selectedUserId == null) {
      this.commonFunctions.showErrorToast('UserID nicht vorhanden');
      return;
    }

    this.commonFunctions
      .openDialog(
        'Achtung',
        'Wirklich löschen?',
        DialogType.INFO,
        'Abbrechen',
        'Löschen'
      )
      .afterClosed()
      .subscribe(async (result) => {
        if (result.result == 1) {
          var selectedElement: UserDataTableView = this.selection.selected[0];

          var firstName = this.commonFunctions.getPrettyPrintedFirstName(
            this.userData
          );
          var lastName = this.commonFunctions.getPrettyPrintedLastName(
            this.userData
          );

          // delete entry
          var deleted =
            await this.dataModel.deleteEncouragementPointEntryDocument(
              selectedUserId,
              selectedElement.id, firstName, lastName, selectedElement
            );

          if (deleted.result) {
            this.commonFunctions.showSuccessToast('Eintrag gelöscht', 1000);
            //reload table data
            this.loadUserData();
            this.selection.clear();
          } else {
            this.commonFunctions.showErrorToast(
              'Eintrag konnte nicht gelöscht werden: ' + deleted.errorMessage
            );
          }
        }
      });
  }

  //gets the id of the user that is selected in the combo box
  private getSelectedUserId() {
    var selectedUser = this.myControl.value;
    if (!selectedUser) {
      this.commonFunctions.showErrorToast('Ungültiger Eintrag ausgewählt');
      return null;
    }
    return selectedUser.uid;
  }

  //name selection done
  public optionSelected(val: any) {
    this.loadUserData();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: UserDataTableView): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  //Gesamtanzahl Punkte
  public getAvailablePointsCount() {
    if (this.selectedUserData && this.selectedUsersEncouragementPoints) {
      return this.commonFunctions.sumEncouragementPoints(
        this.selectedUsersEncouragementPoints
      );
    }
    return 0;
  }

  //Nur Foerderpunkte
  public getEncouragementPointCount() {
    if (this.selectedUserData && this.selectedUsersEncouragementPoints) {
      return this.commonFunctions.calculateEncouragementPointsCount(
        true,
        this.selectedUsersEncouragementPoints
      );
    }
    return 0;
  }

  //Noch gesperrte Punkte
  public getLockedPointsCount() {
    if (this.selectedUserData && this.selectedUsersEncouragementPoints) {
      return this.commonFunctions.calculateEncouragementPointsCount(
        false,
        this.selectedUsersEncouragementPoints
      );
    }
    return 0;
  }
}
