import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogEntry } from './log.service';
import { LogPublisher } from './LogPublisher';

@Injectable({
  providedIn: 'root',
})
export class LogConsole extends LogPublisher {
  log(entry: LogEntry): Observable<boolean> {
    // Log to console
    console.log(entry.buildLogString());
    return Observable.of(true);
  }

  clear(): Observable<boolean> {
    console.clear();
    return Observable.of(true);
  }
}
