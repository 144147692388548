import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CommonFunctionsService,
  DialogType,
} from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import {
  AddWpDialogData,
  IEvent,
  Event,
  Sub_WorkPackage,
  AddEventDialogData,
  Properties,
  IComponentChanged,
  IWorkPackage,
  EventViewMode,
  DateGroupedWorkPackages,
  User,
  WorkPackageKind,
  IEventChanged,
} from 'src/app/services/definitions.service';
import { LogService } from 'src/app/services/log.service';
import { AddWpComponent } from '../../dialogs/add-wp/add-wp/add-wp.component';
import { AddEventComponent } from '../../dialogs/add-event/add-event.component';
import * as _ from 'lodash';
import {
  ComponentState,
  ThreadSafeService,
} from 'src/app/services/thread-safe-component-state.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

enum VisibilityIndex {
  EventText = 0,
  FreigabeTooggle = 1,
  EditButton = 2,
  DeleteButton = 3,
  AddButton = 4,
  DateSeparatedWorkPackages = 5,
  FirstDivider = 6,
  TextFreieSchichten = 7,
  SecondDivider = 8,
  SimplifiedWorkPackges = 9,
  WpForReaders = 10,
  QrCodeTop = 11,
  QrCodeFooter = 12,
}

@Component({
  selector: 'event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [ThreadSafeService], // Provide the service here
})
export class EventViewComponent implements OnInit {
  @Input() data: IEvent;
  @Input() users: User[];
  @Input() accountingYear: any;
  @Input() userOptions: any[];
  @Input() viewMode: EventViewMode;
  @Output() componentChanged = new EventEmitter<IComponentChanged>();
  @Output() componentLoaded = new EventEmitter<IEventChanged>();

  public enumViewMode = EventViewMode;
  public releaseForUsersChecked = true;
  public visibility = VisibilityIndex;
  public helperPlacesAvailable: boolean = true;
  public grouped: DateGroupedWorkPackages[];
  private userData: User;
  private componentState: ComponentState;
  private debugModeOn: boolean = false;
  private routeSub: Subscription;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService,
    public dialog: MatDialog,
    private threadSafeService: ThreadSafeService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  ngOnInit(): void {
    //enable debug mode if set in url param
    this.routeSub = this.route.queryParams.subscribe((params) => {
      this.debugModeOn = params['debug'] === 'true';
    });

    this.threadSafeService.variable$.subscribe((value) => {
      this.componentState = value;
      // console.log('(EventView) componentState:'+ this.componentState.doneLoading());
      if (this.componentState.doneLoading()) {
        if (this.debugModeOn)
          console.log(
            '(EventView) completely loaded: ' + this.componentState.id
          );
        this.componentLoaded.emit({ event: this.data });
      }
    });
    this.threadSafeService.setName('EventViewComponent');
    this.threadSafeService.setId(this.data.id);
  }

  ngAfterViewInit() {
    this.loadWorkPackages();
    if (this.data) {
      //data of this component has been loaded
      this.threadSafeService.setComponentDataLoaded();

      //To avoid: Expression has changed after it was checked
      setTimeout(() => {
        this.releaseForUsersChecked = this.data.releasedForUsers;
      }, 500);
    }
  }

  //Load all work packages of an event
  private async loadWorkPackages() {
    var path = `${Event()}/${this.data.id}/${Sub_WorkPackage()}`;
    var wpLoaded = await this.dataModel.get(path, false, true);
    if (!wpLoaded.result) {
      this.common.showErrorToast('Schichten konnten nicht geladen werden');
      this.log.error(wpLoaded.errorMessage);
    } else {
      this.grouped = this.splitByDays(wpLoaded.data);
      this.data.workPackages = wpLoaded.data;

      //set list of workpackages that have to be resolved
      this.threadSafeService.setSubComponentsIdsList(
        this.getAllWpIds(this.data.workPackages)
      );
    }
  }

  //gets all work package ids that are part of this grouped work package
  private getAllWpIds(workPackages: IWorkPackage[]): string[] {
    var ids: string[] = [];
    if (workPackages) {
      workPackages.forEach((element) => {
        ids.push(element.id);
      });
    }
    return ids;
  }

  //Opens a dialog to add an work package
  public async addWorkPackage() {
    //check user data
    if (this.users == undefined) {
      this.common.showErrorToast('User-Daten nicht geladen');
      return;
    }
    //check if construction- and/or dismantling-shift is already present
    var constructionAlreadyCreated = this.common.isWpKindAlreadyAvailable(
      this.data.workPackages,
      WorkPackageKind.Construction
    );
    var dismantlingAlreadyCreated = this.common.isWpKindAlreadyAvailable(
      this.data.workPackages,
      WorkPackageKind.Dismantling
    );

    var data: AddWpDialogData = {
      result: 0,
      allUserData: this.users,
      userOptions: this.userOptions,
      path: this.data.path,
      eventData: this.data,
      kind: WorkPackageKind.Standard,
      constructionShiftPresent: constructionAlreadyCreated,
      dismantlingShiftPresent: dismantlingAlreadyCreated,
      isUe18: true,
    };
    this.dialog
      .open(AddWpComponent, {
        width: '550px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == 1) {
          this.loadWorkPackages();
        }
      });
  }

  //Raised on button click to delete an event
  public async delete() {
    this.common
      .openDialog(
        'Löschen',
        'Event wirklich löschen?',
        DialogType.INFO,
        'Nein',
        'Ja'
      )
      .afterClosed()
      .subscribe(async (result) => {
        if (result.result == 1) {
          var ret = await this.deleteEvent(this.data.id, this.data.path);
          if (ret) {
            this.common.showSuccessToast('Event gelöscht');
          } else {
            this.common.showErrorToast('Event konnte nicht gelöscht werden');
          }
          this.emitChange();
        }
      });
  }

  //Edit an event
  public async edit() {
    if (this.data == undefined) {
      this.common.showErrorToast('Event hat keine Daten');
      return;
    }
    if (this.accountingYear == undefined) {
      this.common.showErrorToast('Daten zum Abrechnungsjahr nicht geladen');
      return;
    }
    var data: AddEventDialogData = {
      result: 0,
      idAccountingYear: this.accountingYear.id,
      city: this.data.city,
      description: this.data.description,
      houseNumber: this.data.houseNumber,
      locationName: this.data.locationName,
      plz: this.data.plz,
      responsiblePerson: this.data.responsiblePerson,
      responsiblePersonId: this.data.responsiblePersonId,
      street: this.data.street,
      name: this.data.name,
      start: this.data.start,
      end: this.data.end,
      id: this.data.id,
      allUserData: this.users,
      userOptions: this.userOptions,
    };

    this.dialog
      .open(AddEventComponent, {
        width: '550px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == 1) {
          this.emitChange();
        }
      });
  }

  /**
   * Delete an event, all work packages and all participants.
   *
   * @param eventId Id of the event.
   * @param eventDocPath The path of the event
   * @returns A promise (true or false)
   */
  private async deleteEvent(
    eventId: string,
    eventDocPath: string
  ): Promise<boolean> {
    //get all work packages of event
    var path = `${Event()}/${eventId}/${Sub_WorkPackage()}`;
    var allWp = await this.dataModel.get(path);

    if (allWp.result) {
      for (let index = 0; index < allWp.data.length; index++) {
        const wp = allWp.data[index];
        var id = wp[Properties.ID];

        //delete all participants of work packages
        var deletedParticipants = await this.dataModel.deleteParticipants(
          wp.path
        );
        if (!deletedParticipants.result) {
          this.log.error(
            'Failed to delete participants of work package: ' + id
          );
        }

        //delete work packages
        var deletedWp = await this.dataModel.deleteWorkPackages(eventDocPath);
        if (!deletedWp.result) {
          this.log.error('Failed to delete work packages of work event: ' + id);
        }
      }
    } else {
      this.log.error('Failed to get work packages of event: ' + eventId);
    }

    //delete event
    var deletedEvent = await this.dataModel.deleteGeneric(eventDocPath);
    if (deletedEvent.result) {
      return true;
    } else {
      this.log.error('Failed to delete event: ' + eventId);
    }
    return false;
  }

  //Raised when something has change in the child component
  public wpChanged() {
    this.loadWorkPackages();
    //propagate change upwards
    this.emitChange();
  }

  //Signals the parent that something has changed in this component
  private emitChange() {
    var changed: IComponentChanged = { id: this.data.id };
    this.componentChanged.emit(changed);
  }

  //Called when sub compoenent is loaded
  public gwpLoaded(groupedWorkPackage) {
    if (groupedWorkPackage) {
      // console.log('(EventView): grouped work packages loaded for gwp: '+ groupedWorkPackage.date);
      var allWpIds = this.getAllWpIds(groupedWorkPackage.workPackages);
      allWpIds.forEach((element) => {
        this.threadSafeService.setSubComponentLoaded(element);
      });
    }
  }

  //Called when sub compoenent is loaded
  public wpViewLoaded(workPackage: IWorkPackage) {
    // console.log(`(EventViewComponent): sub comp. wpView (view mode: ${EventViewMode[this.viewMode]}) loaded: `+ workPackage.id);
    this.threadSafeService.setSubComponentLoaded(workPackage.id);
  }

  //Gets the state of this event: not started, running, past
  public getRunningState(): string {
    return this.common.getRunningState(this.data);
  }

  //Compares two work packages
  compareFn(a: IWorkPackage, b: IWorkPackage) {
    if (a.start < b.start) {
      return -1;
    }
    if (a.start > b.start) {
      return 1;
    }
    return 0;
  }

  //Checks if the assigned item is a Date
  public isDate(item: any): boolean {
    if (item && item[0] instanceof Date) {
      return true;
    }
    return false;
  }

  public getDayName(date: Date) {
    var days = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ];
    return days[date.getDay()];
  }

  //Returns the text that shall be displayed on top (but only for finished events)
  public getProccessedText() {
    if (this.data) {
      var now = new Date();
      var date = new Date(this.data.end.seconds * 1000);
      if (now > date) {
        if (this.data.processedInBackend) {
          return '(Verarbeitet)';
        } else {
          return '(Noch nicht verarbeitet)';
        }
      }
    }
    return '';
  }

  //Raised when 'Freigabe' toggle button is toggled
  public async changeReleaseForUsers(event) {
    if (event) {
      var released = event.checked;
      var ret = await this.dataModel.setEventReleasedForUsers(
        this.data.id,
        released
      );
      if (!ret) {
        this.common.showErrorToast(ret.errorMessage);
      }
    }
  }

  //Split by date
  private splitByDays(workPackages: IWorkPackage[]): DateGroupedWorkPackages[] {
    var workingCopy = workPackages;
    var ret: DateGroupedWorkPackages[] = [];
    if (workingCopy) {
      //sort by date
      workingCopy = workingCopy.sort(this.compareFn);

      workingCopy.forEach((element) => {
        var start = new Date(element.start.seconds * 1000);
        start.setHours(0, 0, 0, 0); //eliminate hours and stuff

        //check if already existing
        var foundIx = ret.findIndex((x) => {
          return x.date.getTime() == start.getTime();
        });
        var listWp: IWorkPackage[] = [];
        if (foundIx == -1) {
          //add new
          listWp.push(element);
          var newItem: DateGroupedWorkPackages = {
            date: start,
            workPackages: listWp,
          };
          ret.push(newItem);
        } else {
          //add to existing
          ret[foundIx].workPackages.push(element);
        }
      });
    }
    return ret;
  }

  //TODO:
  //Noch zu klaeren: Eventuell nur fuer Auf-und Abbau
  // es fehlt auch noch die Anmeldemoeglichkeit per QR Code. Dabei soll das anwesenheits flag eines users in seiner schicht gesetz werden
  // (Beachte threshold weil man sich bestimmt schon bisschen frueher anmeldet. AM einfachsten ist wenn man gefragt wird fuer welche Schicht man sich anmelden will. (Die Schichtzeit aendert sich aber nicht))
  public showQrCode() {
    //auch print-funktion wenn nicht zu aufwendig
  }

  //Decide if a shift shall be shown
  public showWp(wp: IWorkPackage): boolean {
    //at this point in time, no work packages are loaded. Return true to allow loading and then decide if to hide
    if (wp && wp.participants == undefined) {
      return true;
    }
    return this.common.showWpView(wp, this.viewMode, this.userData.uid);
  }

  //show debug info about a single work package
  public showWpDebugInfo(wp: IWorkPackage) {
    if (!this.debugModeOn) {
      return '';
    }
    return this.common.showWpDebugInfo(wp, this.userData.uid, this.viewMode);
  }

  /**
   * Checks if the current user is an events admin.
   * @returns {boolean} Returns true if the user is an events admin, false otherwise.
   */
  public onlyEventsAdmin(): boolean {
    return this.authService.userIsEventsAdmin(); 
  }

  //Controls the visibility of specific ui elements depending on th view mode
  public isVisible(index: VisibilityIndex): boolean {
    switch (index) {
      case VisibilityIndex.EventText:
        return (
          this.viewMode == this.enumViewMode.Admin ||
          this.viewMode == this.enumViewMode.Participant ||
          this.viewMode == this.enumViewMode.Reader
        );
      case VisibilityIndex.AddButton:
        return (this.viewMode == this.enumViewMode.Admin && this.onlyEventsAdmin());
      case VisibilityIndex.DeleteButton:
        return (this.viewMode == this.enumViewMode.Admin && this.onlyEventsAdmin());
      case VisibilityIndex.EditButton:
        return (this.viewMode == this.enumViewMode.Admin && this.onlyEventsAdmin());
      case VisibilityIndex.DateSeparatedWorkPackages:
        return (
          this.viewMode == this.enumViewMode.Admin ||
          this.viewMode == this.enumViewMode.Participant
        );
      case VisibilityIndex.FreigabeTooggle:
        return (this.viewMode == this.enumViewMode.Admin && this.onlyEventsAdmin());
      case VisibilityIndex.FirstDivider:
        return this.viewMode != this.enumViewMode.SimplifiedAdmin;
      case VisibilityIndex.SecondDivider:
        return this.viewMode == this.enumViewMode.Admin;
      case VisibilityIndex.TextFreieSchichten:
        return this.viewMode == this.enumViewMode.Participant;
      case VisibilityIndex.SimplifiedWorkPackges:
        return this.viewMode == this.enumViewMode.SimplifiedAdmin;
      case VisibilityIndex.WpForReaders:
        return this.viewMode == this.enumViewMode.Reader;
      case VisibilityIndex.QrCodeTop:
        return this.viewMode == this.enumViewMode.SimplifiedAdmin;
      case VisibilityIndex.QrCodeFooter:
        return this.viewMode == this.enumViewMode.Admin;
      default:
        return false;
    }
  }
}
