<div class="flex-container">
  <div *ngFor="let participant of data; let i = index">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <table>
              <!--Admin or Shift Organizer view-->
              <tr *ngIf="visibleForAdminOrShiftOrg()">
                <th
                  rowspan="2"
                  style="
                    border-right: 1px solid rgb(63, 62, 62);
                    padding-right: 20px;
                  "
                >
                  <mat-checkbox
                    [checked]="participant.present"
                    color="primary"
                    matTooltip="Anwesend/Abwesend setzen"
                    (click)="$event.stopPropagation()"
                    (change)="onChangePresence($event, participant)"
                  >
                  </mat-checkbox>
                </th>
                <td >
                  <mat-icon matPrefix>person</mat-icon>
                </td>
                <td>{{ participant.firstName }} {{ participant.lastName }}</td>
              </tr>
              <!--Others view-->
              <tr *ngIf="!visibleForAdminOrShiftOrg()">
                <td></td>
                <td>{{ participant.firstName }} {{ participant.lastName }}</td>
              </tr>
              <tr>
                <td >
                  <mat-icon>access_time</mat-icon>
                </td>
                <td>
                  {{ participant.start?.seconds * 1000 | date : "H:mm" }} -
                  {{ participant.end?.seconds * 1000 | date : "H:mm" }} Uhr
                </td>
              </tr>
            </table>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <table style="margin-top: 20px">
          <tr>
            <td>
              <time-range-picker
                [start]="asDate(participant?.start, true)"
                [end]="asDate(participant?.end)"
                [rangeStart]="timestampToDate(workPackage.start)"
                [rangeEnd]="timestampToDate(workPackage.end)"
                (timeChanged)="timeChanged($event, participant)"
                [disable]="event?.processedInBackend"
              ></time-range-picker>
            </td>
          </tr>
          <tr>
            <td>
              <button
                mat-raised-button
                style="width: 90%"
                color="warn"
                matTooltip="Teilnehmer entfernen"
                (click)="removeParticipant(participant)"
                *ngIf="visibleForAdminOrShiftOrg()"
                [disabled]="event?.processedInBackend"
              >
                Teilnehmer entfernen
              </button>
            </td>
          </tr>
        </table>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
