import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DataModelService } from './data-model.service';
import { Route, User, UserGroupEntity } from './definitions.service';
import { CommonFunctionsService } from './common-functions.service';
import { LogService } from './log.service';
import { EventEmitterService } from '../event-emitter.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: Observable<firebase.default.User>;
  userDetails: firebase.default.User;
  subs: Subscription;
  loginBlocked: boolean = false;
  blockReason: string = '';
  private _userData: User = undefined;

  constructor(
    public router: Router,
    private angularFireAuth: AngularFireAuth,
    private dataModelService: DataModelService,
    private commonFunctions: CommonFunctionsService,
    private eventEmitter: EventEmitterService,
    private log: LogService
  ) {}

  isLoggedIn() {
    if (this.userDetails != null) {
      return true;
    }
    return false;
  }

  logIn() {
    if (this.loginBlocked) {
      setTimeout(() => {        
        this.commonFunctions.showErrorToast(this.blockReason, 10000);
      }, 1000);
      return false;
    }
    if (this.userDetails != null) {
      return true;
    }
    var promise = new Promise<boolean>((resolve, reject) => {
      this.subs = this.angularFireAuth.authState.subscribe((user) => {
        if (user) {
          this.userDetails = user;
          resolve(true);
        } else {
          this.userDetails = null;
          var msg = 'Logged out. Redirecting to login component...';
          this.log.error(msg, AuthService.name);
          this.router.navigate(['/login']);
          this.subs.unsubscribe();
          reject(msg);
        }
      });
    });
    return promise;
  }

  //Check if user has the right to access
  async hasRightToAccess(userGroupEntities: UserGroupEntity[], route: ActivatedRouteSnapshot = undefined) {
    try {
      var loggedIn = await this.logIn();
    } catch (error) {
      this.log.error('Login failed', error, AuthService.name);
      return false;
    }

    if (loggedIn) {
      var uid = this.userDetails.uid;

      //use cache data to reduce server traffic (could be a little security lag but ok i think)
      if (this.eventEmitter.cachedUserData) {
        console.log('Use cached data');
        var userData = this.eventEmitter.cachedUserData; //get cached data
      } else {
        try {
          userData = await this.dataModelService.loadUser(uid);
        } catch (error) {
          this.log.error('Failed to load user data', error, AuthService.name);
          return false;
        }
      }
      this._userData = userData;

      //Special cases:
      //check if user is shift organizer. If so, allow access to event planning 
      if (route?.routeConfig?.path == Route.EVENTS_ADMIN.toString()) {
        var show = await this.commonFunctions.showEventPlanningForShiftOrganzier(userData);
        if (show) {
          return true;
        }
        //check if event admin, the show it although
        if(userData.userGroups.includes(UserGroupEntity.EVENT_ADMIN.valueOf())) {
          this.log.debug('User is event admin. Show route.', AuthService.name);
          return true;
        }
      }

      if (userData) {
        var promise = new Promise<boolean>((resolve, reject) => {
          var userGroups: number[] = userData.userGroups;

          //check if user group property is set
          if (userGroups) {
            //This an AND. User has to have all defined rights
            var success = true;
            userGroupEntities.forEach((element) => {
              if (!userGroups.includes(element)) {
                success = false;
              }
            });
            resolve(success);

            // if (userGroups.includes(userGroupEntity.valueOf())) {
            //   resolve(true);
            // }
            // //Convert user group entities to list of number values
            // const keys = Object.keys(UserGroupEntity).filter(
            //   (k) => typeof UserGroupEntity[k as any] === 'number'
            // );
            // const values = keys.map((k) => UserGroupEntity[k as any]);

            // var left = new List(userGroups);
            // var right = new List(values);
            // var intersection = left.Intersect(right);

            // if (intersection.Count() > 0) {
            //   resolve(true);
            // }
          } else {
            this.log.error(
              'No user group property set for this user',
              AuthService.name
            );
          }
          reject(false);
        });
        return promise;
      } else {
        this.log.error('No user found for id: ' + uid, AuthService.name);
      }
    }
    return false;
  }

  getCurrentUserId() {
    return this.userDetails?.uid;
  }

  getCurrentUserFirstName() {
    try {
      return this.userDetails['personalData']['firstName'];      
    } catch (error) {
      this.log.error('Error fetching users first name', AuthService.name);
      return '';
    }
  }
  
  getCurrentUserLastName() {
    try {
      return this.userDetails['personalData']['lastName'];      
    } catch (error) {
      this.log.error('Error fetching users last name', AuthService.name);
      return '';
    }
  }

  async logout() {
    await this.angularFireAuth.signOut().then((res) => {
      this.log.info('Successfully logged out', AuthService.name);
      this.userDetails = null;
      this.eventEmitter.clearAllData();
      this.router.navigate(['/login']);
    });
  }

  public blockLogin(reason: string) {
    this.loginBlocked = true;
    this.blockReason = reason;
  }

  public unblockLogin() {
    this.loginBlocked = false;
    this.blockReason = '';
  }

  public isLoginBlocked() {
    return this.loginBlocked;
  }

  public getBlockReason() {
    return this.blockReason;
  }

  
  /**
   * Checks if the current user is an events admin.
   * @returns {boolean} Returns true if the user is an events admin, false otherwise.
   */
  public userIsEventsAdmin(): boolean {
    if (this._userData) {
      if (this._userData.userGroups) {
        if (this._userData.userGroups.includes(UserGroupEntity.EVENT_ADMIN)) {
          return true;
        }
      }
    }
    return false;
  }
}
