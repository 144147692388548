<h1 mat-dialog-title>Schichtabmeldung</h1>
<hr style="opacity: 0.2" />
<div mat-dialog-content>
  <form [formGroup]="myForm">
    <div *ngIf="!onlyCall">
      <p style="margin-bottom: 20px">
        Bitte erkläre jetzt, wieso du nicht zur Schicht kommen kannst. Beachte,
        dass der Verantwortliche der Schicht über deine Abmeldung informiert
        wird. Bemühe dich einen Ersatz zu finden oder sprich direkt mit dem
        Schichtverantwortlichen.
      </p>

      <!--Comment-->
      <mat-form-field class="form-fields">
        <mat-label>Erklärung eingeben</mat-label>
        <input matInput [formControl]="commentFormControl" maxlength="500" />
      </mat-form-field>
    </div>
    <div *ngIf="onlyCall">
      <p style="margin-bottom: 20px">
        Bitte kontaktiere den Schichtverantwortlichen um dich abzumelden. Absagen 48h vor der Schicht müssen vom Schichtleiter koordiniert werden.
      </p>

      <div style="width: 100%; padding: 10px">
        <button
          mat-raised-button
          color="primary"
          matTooltip="Schichtleiter anrufen"
          (click)="callShiftOrganizer()"
          style="width: 100%"
        >
          <mat-icon style="margin-right: 8px">call</mat-icon>
          Anrufen
        </button>
      </div>
    </div>
  </form>
</div>
<hr style="opacity: 0.2" />
<div mat-dialog-actions class="dialog-btn-container">
  <div *ngIf="!onlyCall">
    <button
      mat-raised-button
      type="reset"
      class="dialog-btn"
      (click)="onNoClick()"
    >
      Abbrechen
    </button>
    <button
      mat-raised-button
      color="warn"
      class="dialog-btn"
      (click)="onYesClick()"
      [disabled]="!myForm.valid"
    >
      Abmelden
    </button>
  </div>
  <div *ngIf="onlyCall">
    <button
      mat-raised-button
      type="reset"
      class="dialog-btn"
      (click)="onNoClick()"
    >
      Schliessen
    </button>
  </div>
</div>
