<div style="margin: 20px; padding-bottom: 100px">
  <mat-form-field
    appearance="standard"
    style="font-size: 14px; width: 100%; padding-left: 10%; padding-right: 10%"
  >
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilterTable($event)" placeholder="" #input />
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSourceTable"
      matSort
      class="mat-elevation-z8"
      matSortActive="timestamp"
      matSortDirection="desc"
    >
      <!--Besitzer-->
      <ng-container matColumnDef="sourceUserName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Besitzer</th>
        <td mat-cell *matCellDef="let row" style="padding-left: 35px">
          {{ row.sourceUserName }}
        </td>
      </ng-container>

      <!--AmountPoints-->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Anzahl</th>
        <td mat-cell *matCellDef="let row" style="padding-left: 35px">
          {{ row.amount }}
        </td>
      </ng-container>

      <!--Action-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
        <td mat-cell *matCellDef="let row" style="padding-left: 35px">
          {{ row.action }}
        </td>
      </ng-container>

      <!--Amount Events-->
      <ng-container matColumnDef="amountEvents">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Events/a</th>
        <td mat-cell *matCellDef="let row">
          {{ row.amountEvents }}
        </td>
      </ng-container>

      <!--Comment-->
      <ng-container matColumnDef="actionComment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kommentar</th>
        <td mat-cell *matCellDef="let row">
          {{ row.actionComment }}
        </td>
      </ng-container>

      <!--actionDoneByName-->
      <ng-container matColumnDef="actionDoneByName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Eingetragen von
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.actionDoneByName }}
        </td>
      </ng-container>

      <!--reason-->
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grund</th>
        <td mat-cell *matCellDef="let row">
          {{ row.reason }}
        </td>
      </ng-container>

      <!--timestamp-->
      <ng-container matColumnDef="ts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Zeitpunkt</th>
        <td mat-cell *matCellDef="let row">
          {{ row.ts.seconds * 1000 | date: "dd.MM.yyyy, H:mm" }}
          Uhr
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsTable"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsTable"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keine Einträge vorhanden</td>
      </tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 50, 100]"
      aria-label="Select page of users"
    ></mat-paginator>
  </div>
</div>
