import { Pipe, PipeTransform } from '@angular/core';
import { WorkHourEntryReason } from '../services/data-model.service';

@Pipe({
  name: 'workHourEntryReason',
})
export class WorkHourEntryReasonPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    switch (value) {
      case WorkHourEntryReason.STANDARD:
        return 'Standard';
      default:
        return 'Unbekannt';
    }
  }
}
