<h1 mat-dialog-title>{{ data.dialogTitle }}</h1>
<hr style="opacity: 0.2" />
<div mat-dialog-content>
  <form [formGroup]="myForm">
    <!--User selection-->
    <mat-form-field class="form-fields" *ngIf="isMobile">
      <mat-label>Namen eingeben...</mat-label>
      <input
        type="text"
        placeholder=""
        aria-label="Namen eingeben..."
        matInput
        [formControl]="userSelectorFormControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayFn"
        (optionSelected)="optionSelectedUser($event.option.value)"
      >
        <mat-option
          *ngFor="let option of filteredNameOptions | async"
          [value]="option"
        >
          {{ option.personalData?.firstName }} {{ option.personalData?.lastName
          }} ({{ option.email }})
        </mat-option>
      </mat-autocomplete>
      <button
        *ngIf="userSelectorFormControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="userSelectorFormControl.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div *ngIf="dataLoaded">
      <!--WorkHourEntryAction-->
      <mat-form-field class="form-fields">
        <mat-label>Art</mat-label>
        <mat-select formControlName="entryAction">
          <mat-option
            *ngFor="let item of wheA"
            [value]="item"
            (onSelectionChange)="optionSelected($event)"
          >
            {{ item | workHourEntryAction : '2' }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!--Amount-->
      <mat-form-field class="form-fields">
        <mat-label>Anzahl (max. Entnahme: {{data.maxAmount}})</mat-label>
        <input
          matInput
          type="number"
          placeholder=""
          [formControl]="amountFormControl"
          min="1"
        />
        <mat-error *ngIf="amountFormControl.invalid"
          >Es können nur {{data.maxAmount}} Punkte entnommen werden</mat-error
        >
      </mat-form-field>

      <!--Comment-->
      <mat-form-field class="form-fields" style="padding-bottom: 0px">
        <mat-label>Kommentar</mat-label>
        <input matInput [formControl]="commentFormControl" />
      </mat-form-field>
    </div>
  </form>
</div>
<hr style="opacity: 0.2" />
<div mat-dialog-actions class="dialog-btn-container">
  <button
    mat-raised-button
    type="reset"
    class="dialog-btn"
    (click)="onNoClick()"
  >
    {{ data?.buttonNoName }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="dialog-btn"
    (click)="onYesClick()"
    [disabled]="!myForm.valid"
  >
    {{ data?.buttonYesName }}
  </button>
</div>
