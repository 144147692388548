import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

export class WaitingSheetData {
  public text: string;
  public timeout: number;
  public callbackCancelTrigger: any;
  public callbackTimeout: any;
  public successCallback: any;

  constructor(
    text: string,
    timeout: number,
    callbackCancelTrigger: any,
    callbackTimeout: any,
    successCallback: any
  ) {
    this.text = text;
    this.timeout = timeout;
    this.callbackCancelTrigger = callbackCancelTrigger;
    this.callbackTimeout = callbackTimeout;
    this.successCallback = successCallback;
  }
}

@Component({
  selector: 'app-waiting-bottom-sheet',
  templateUrl: './waiting-bottom-sheet.component.html',
  styleUrls: ['./waiting-bottom-sheet.component.css'],
})
export class WaitingBottomSheetComponent implements OnInit {
  private interval: any;
  private solvedBeforeTimeout: boolean = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: WaitingSheetData,
    private bottomSheetRef: MatBottomSheetRef<WaitingBottomSheetComponent>
  ) {
    //cancelation check
    this.interval = setInterval(() => {
      var res = data.callbackCancelTrigger();
      if (res) {
        this.solvedBeforeTimeout = true;
        this.dismiss();
        data.successCallback();
      }
    }, 2000);

    //timeout
    setTimeout(() => {
      this.dismiss();
      if (!this.solvedBeforeTimeout) {
        data.callbackTimeout();
      }
    }, data.timeout);
  }

  private dismiss() {
    clearInterval(this.interval);
    this.bottomSheetRef.dismiss();
  }

  ngOnInit(): void {}
}
