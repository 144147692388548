import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteURL } from '../services/definitions.service';
import { LogService } from '../services/log.service';
import { CommonFunctionsService } from '../services/common-functions.service';

@Component({
  selector: 'app-db-sync-upload',
  templateUrl: './db-sync-upload.component.html',
  styleUrls: ['./db-sync-upload.component.css'],
})
export class DbSyncUploadComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private log: LogService,
    public commonFunctions: CommonFunctionsService
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const allowedExtensions = ['.csv'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(`.${fileExtension}`)) {
        this.uploadFile(file);
      } else {
        alert('Invalid file format. Please select an CSV file.');
      }
    }
  }

  //Called when uploading a file by clicking on the image was triggered
  private uploadFile(file: File) {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    // When file uploads set it to file formcontrol
    reader.onload = () => {
      let formData = new FormData();
      formData.append('file', file, file.name);
      formData.append('tz', new Date().toISOString());
      var file_data = formData;

      this.spinner.show();

      this.http
        .post(SiteURL + '/backend/upload-db-sync-file.php', file_data)
        .subscribe(
          (res) => {
            if (res['status'] && res['status'] == 1) {             
              this.commonFunctions.showSuccessToast(
                'Datei erfolgreich hochgeladen'
              );
              this.spinner.hide();
              return;
            } 

            let msg =
              'Datei konnte nicht hochgeladen werden. Fehler: ' +
              res['message'];
            this.commonFunctions.showErrorToast(msg);
            this.log.error(msg, DbSyncUploadComponent.name);
            this.spinner.hide();
          },
          (err) => {
            this.log.error(
              'Upload der Datei fehlgeschlagen',
              err,
              DbSyncUploadComponent.name
            );
            this.commonFunctions.showErrorToast(
              'Datei konnte nicht hochgeladen werden. Prüfe deine Internetverbindung'
            );
            this.spinner.hide();
          }
        );
    };
  }
}
