import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import {
  DataModelService,
  EncouragementPointEntryAction,
  EncouragementPointReason,
} from 'src/app/services/data-model.service';
import {
  Sub_EncouragementPoints,
  EncouragementPointEntryDialogData,
  EncouragementPointEntryDialogIntention,
  User,
} from 'src/app/services/definitions.service';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'encouragement-point-entry',
  templateUrl: 'encouragement-point-entry.html',
  styleUrls: ['encouragement-point-entry.css'],
})
export class EncouragementPointEntryDialog {
  userSelectorFormControl = new FormControl('', [Validators.required]);
  commentFormControl = new FormControl('', [Validators.required]);
  tagsFormControl = new FormControl('');
  entryActionFormControl = new FormControl(1, [Validators.required]);
  amountFormControl = new FormControl(1, [Validators.required]);
  receiverUserSelectorFormControl = new FormControl('');
  releaseDateFormControl = new FormControl(
    this.commonFunctions.getStartOfNextYear()
  );

  myForm: FormGroup;
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  wheA = [
    EncouragementPointEntryAction.ADD,
    EncouragementPointEntryAction.REMOVE,
  ];
  isMobile: boolean;
  userData: User;
  dataLoaded: boolean;
  allUsersData: User[];
  options: User[];
  selectedUserData: any;
  selectedUsersEncouragementPoints: any;
  removePointsActive: boolean = false;
  filteredNameOptions: Observable<User[]>;
  filteredNameOptionsReceiver: Observable<User[]>;

  constructor(
    public dialogRef: MatDialogRef<EncouragementPointEntryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: EncouragementPointEntryDialogData,
    private spinnerService: NgxSpinnerService,
    private commonFunctions: CommonFunctionsService,
    private dataModel: DataModelService,
    fb: FormBuilder,
    private authService: AuthService
  ) {
    //load current users data
    commonFunctions.registerEventEmitterHandlerUserData((userData) => {
      this.userData = userData;
    });
    this.isMobile =
      data.dialogIntention == EncouragementPointEntryDialogIntention.ADD_MOBILE;

    //load all users data
    this.spinnerService.show();
    this.commonFunctions.loadAllUsers(
      (res: boolean, allUsersData: User[], options: User[]) => {
        if (res) {
          this.allUsersData = allUsersData;
          this.options = options;

          this.filteredNameOptions =
            this.userSelectorFormControl.valueChanges.pipe(
              startWith(''),
              map((value) => this._filter(value || ''))
            );
          this.filteredNameOptionsReceiver =
            this.receiverUserSelectorFormControl.valueChanges.pipe(
              startWith(''),
              map((value) => this._filter(value || ''))
            );
        }
        this.spinnerService.hide();
      }
    );

    //distinguish mobile mode
    if (this.isMobile) {
      this.myForm = fb.group({
        comment: this.commentFormControl,
        entryAction: this.entryActionFormControl,
        amount: this.amountFormControl,
        releaseDate: this.releaseDateFormControl,
        tags: this.tagsFormControl,
        userSelector: this.userSelectorFormControl,
        receiverUserSelector: this.receiverUserSelectorFormControl,
      });
    } else {
      this.dataLoaded = true;
      this.myForm = fb.group({
        comment: this.commentFormControl,
        entryAction: this.entryActionFormControl,
        amount: this.amountFormControl,
        releaseDate: this.releaseDateFormControl,
        tags: this.tagsFormControl,
        receiverUserSelector: this.receiverUserSelectorFormControl,
      });
    }

    // Set the minimum to now and the maximum to two years in the future
    const currentYear = new Date().getFullYear();
    this.startDate = new Date(Date.now());
    this.minDate = this.startDate;
    this.maxDate = new Date(currentYear + 2, 12, 31);
  }

  //Filter input by name
  private _filter(value: string): User[] {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();

      return this.options.filter((option: User) =>
        this.displayFn(option).toLowerCase().includes(filterValue)
      );
    }
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onYesClick(): void {
    var comment = this.myForm.get('comment').value;
    var type: EncouragementPointEntryAction =
      this.myForm.get('entryAction').value;
    var amount = this.myForm.get('amount').value;
    var destinationUserId = this.data.destinationUserId;
    var destinationUserName = this.data.destinationUserName;
    var entryCreatorId = this.data.currentUserId;
    var entryCreatorName = this.data.currentUserName;
    var releaseDate = this.myForm.get('releaseDate').value;
    var tags = this.myForm.get('tags').value;
    var receiverUserId = this.myForm.get('receiverUserSelector').value.uid;

    //check if source and receiver are the same
    if (
      type == EncouragementPointEntryAction.REMOVE &&
      destinationUserId == receiverUserId
    ) {
      this.commonFunctions.showErrorToast(
        'Die Person der Punkte entnommen werden, darf nicht die selbe sein die sie gutgeschrieben bekommt.'
      );
      return;
    }

    //split tags
    var tagsArray = tags.split(',');
    const trimmedTagsArray = tagsArray.map((element) => {
      return element.trim();
    });

    this.addEncouragementPoint(
      destinationUserId,
      entryCreatorId,
      entryCreatorName,
      comment,
      amount,
      type,
      releaseDate,
      trimmedTagsArray,
      EncouragementPointReason.STANDARD,
      receiverUserId,
      destinationUserName
    );
  }

  //name selection done
  public optionSelected(event) {
    if (event.isUserInput) {
      var val = event.source.value;
      this.myForm.clearValidators();

      if (val == EncouragementPointEntryAction.REMOVE) {
        this.removePointsActive = true;

        //Amount
        this.amountFormControl.setValidators(
          Validators.max(this.data.maxAmount)
        );
        this.amountFormControl.updateValueAndValidity();
      } else {
        this.removePointsActive = false;

        //amount
        this.amountFormControl.setValidators(Validators.required);
        this.amountFormControl.updateValueAndValidity();

        //receiver
        this.receiverUserSelectorFormControl.setValidators(Validators.required);
        this.receiverUserSelectorFormControl.updateValueAndValidity();
      }
    }
  }

  //name selection done
  public optionSelectedUser(val: any) {
    this.dataLoaded = false;
    this.loadUserData();
  }

  displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  /**
   * Adds/Removes encouragement points
   *
   * @param destinationUserId the user that gets new points added or removed
   * @param entryCreatorId the id of the user that is currently using the App
   * @param entryCreatorName the name of the user that is currently using the App
   * @param comment a comment
   * @param amount the amount of points
   * @param addOrRemove flag wether to remove or add points
   * @param releaseDate the date where newly added points are allowed to be used
   * @param tags a collections of tags
   * @param reason a reason why this entry has been made
   * @param receiverUserId the id of the user that gets the points added, which are removed (in remove case) from the selected user
   * @param destinationUserName the name of the user that gets the points added, which are removed (in remove case) from the selected user
   */
  private addEncouragementPoint(
    destinationUserId: string,
    entryCreatorId: string,
    entryCreatorName: string,
    comment: string = '',
    amount: number,
    addOrRemove: EncouragementPointEntryAction,
    releaseDate: Date,
    tags: string[],
    reason: EncouragementPointReason = EncouragementPointReason.STANDARD,
    receiverUserId: string,
    destinationUserName: string
  ) {
    this.spinnerService.show();
    var unlockTimestamp;

    //Wenn es ein Punkteabzug ist, dann keine unlock-timestamp setzen bzw. auf "now" setzen
    if (addOrRemove == EncouragementPointEntryAction.REMOVE || !releaseDate) {
      unlockTimestamp = firebase.firestore.Timestamp.fromDate(new Date());
    } else {
      unlockTimestamp = firebase.firestore.Timestamp.fromDate(releaseDate);
    }

    //create entry for addition/removal of points at user
    this.dataModel
      .createEncouragementPointEntry(
        destinationUserId,
        amount,
        addOrRemove,
        comment,
        reason,
        entryCreatorId,
        unlockTimestamp,
        tags,
        entryCreatorName
      )
      .then(
        (done) => {
          if (done) {
            //nur im Falle von entfernen von Punkten
            if (addOrRemove == EncouragementPointEntryAction.REMOVE) {
              //insert receivers points
              this.createReceiverEncouragementPointEntry(
                receiverUserId,
                comment,
                amount,
                destinationUserId,
                destinationUserName
              );
            } else {
              this.commonFunctions.showSuccessToast(
                'Förderpunkte erfolgreich eingetragen',
                1000
              );
              this.data.result = 1;
              this.dialogRef.close(this.data);
            }
          } else {
            this.commonFunctions.showErrorToast(
              'Förderpunkte konnten nicht eingetragen werden'
            );
          }
        },
        (rejected) => {
          this.commonFunctions.showErrorToast(
            'Förderpunkte konnten nicht eingetragen werden: ' + rejected
          );
        }
      )
      .catch((error) => {
        this.commonFunctions.showErrorToast(
          'Förderpunkte konnten nicht eingetragen werden: ' + error
        );
      })
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  //Loads the data of the selected user
  private loadUserData() {
    this.spinnerService.show();
    if (!this.allUsersData) {
      this.commonFunctions.showErrorToast(
        'Userdaten konnten nicht geladen werden'
      );
      return;
    }

    var selectedUserId = this.userSelectorFormControl.value?.uid;
    if (selectedUserId == null) {
      this.commonFunctions.showErrorToast('Ungültiger Eintrag ausgewählt');
      return;
    }
    //overwrite in dialog data
    this.data.destinationUserId = selectedUserId;
    this.data.currentUserId = this.authService.getCurrentUserId();
    this.data.currentUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.userData
    );
    this.data.destinationUserName =
      this.commonFunctions.getPrettyPrintedUserName(
        this.userSelectorFormControl.value
      );

    //find selected user id in 'allUsersData'
    this.selectedUserData = this.allUsersData.find((x) => {
      return x.uid == selectedUserId;
    });

    //load encouragement points
    this.dataModel
      .getUserSubcollectionDoc(selectedUserId, Sub_EncouragementPoints())
      .then(
        (value) => {
          this.selectedUsersEncouragementPoints = value;
          this.data.maxAmount = this.getAvailablePointsCount();
          this.spinnerService.hide();
          this.dataLoaded = true;
        },
        (rejectedReason) => {
          this.commonFunctions.showErrorToast(
            'Förderpunkte konnten nicht geladen werden: ' + rejectedReason
          );
          this.spinnerService.hide();
        }
      )
      .catch((error) => {
        this.commonFunctions.showErrorToast(
          'Förderpunkte konnten nicht geladen werden: ' + error
        );
        this.spinnerService.hide();
      });
  }

  //Verfuegbare Anzahl Punkte
  private getAvailablePointsCount() {
    if (this.selectedUserData && this.selectedUsersEncouragementPoints) {
      return this.commonFunctions.sumEncouragementPoints(
        this.selectedUsersEncouragementPoints
      );
    }
    return 0;
  }

  /**
   * Adds a new addition entry of encouragement point(s) for the receiving user
   *
   * @param destinationUserId the id of the user that gets these points as addition
   * @param comment comment
   * @param amount amount
   * @param entryCreatorId this is the id of the user from whom the point(s) have been transferred
   * @param entryCreatorName the name of the user from whom the point(s) have been transferred
   */
  private createReceiverEncouragementPointEntry(
    destinationUserId: string,
    comment: string = '',
    amount: number,
    entryCreatorId: string,
    entryCreatorName: string
  ) {
    var defaultDate = firebase.firestore.Timestamp.fromDate(new Date());
    //entry creator ist dann der user von dem die Punkte transferiert wurden
    this.dataModel
      .createEncouragementPointEntry(
        destinationUserId,
        amount,
        EncouragementPointEntryAction.ADD,
        comment,
        EncouragementPointReason.POINT_TRANSFER,
        entryCreatorId,
        defaultDate,
        [],
        entryCreatorName
      )
      .then(
        (done) => {
          if (done) {
            this.commonFunctions.showSuccessToast(
              'Förderpunkte erfolgreich eingetragen'
            );
            this.data.result = 1;
            this.dialogRef.close(this.data);
          } else {
            this.commonFunctions.showErrorToast(
              'Förderpunkte beim Empänger konnten nicht eingetragen werden'
            );
          }
        },
        (rejected) => {
          this.commonFunctions.showErrorToast(
            'Förderpunkte beim Empänger konnten nicht eingetragen werden: ' +
              rejected
          );
        }
      )
      .finally(() => {
        this.spinnerService.hide();
      });
  }

  //Get title for amount form control
  public getAmountText(maxAmount: number) {
    return this.removePointsActive
      ? 'Anzahl (max. Entnahme: ' + maxAmount + ' )'
      : 'Anzahl';
  }
}
