module.exports = {
  //Anzahl der Arbeitsstunden die einen Förderpunkt generieren
  GetAmountWorkHoursForOneEncouragementPoint: function () {
    return 4;
  },

  //Mindestanzahl an Arbeitsstunden die man erreichen muss um am Förderprogramm teilnehmen zu können
  GetMinPointsWorkHoursForEncouragementModel: function () {
    return 4;
  },

  /**
   * Berechne Punkte die aus Arbeitsstunden entstehen
   *
   * @param {number} sumWorkHours
   * @returns Die Anzahl der Foerderpunkte die aus den Arbeitsstunden entstehen
   */
  GetPointsFromWorkHours: function (sumWorkHours) {
    //ziehe Mindest-Arbeitsstunden ab
    sumWorkHours -= module.exports.GetMinPointsWorkHoursForEncouragementModel();

    if (sumWorkHours < 0) {
      sumWorkHours = 0;
    }

    //Anzahl Förderpunkte die daraus entstehen würden
    //abrunden und ganzzahlig machen
    var workHoursBlocks = Math.floor(
      sumWorkHours / module.exports.GetAmountWorkHoursForOneEncouragementPoint()
    );
    return workHoursBlocks;
  },

  // ----------------------------------------------------------------------------------------------------

  GetServiceUserEmail: function () {
    return "service@user.de";
  },

  GetServiceUserPW: function () {
    return "59U$Faz98hIRl*&C0Ah8u";
  },

  GetDefaultUserPW: function () {
    return "59U$Faz98hIRl*&C0Ah8s";
  },

  FirebaseApiKey: function () {
    return "AIzaSyD0nCz5OS3NHCM1knvTb2SAZi_SrJxupjo";
  },

  FirebaseAuthDomain: function () {
    return "residenz-app-f37b5.firebaseapp.com";
  },

  FirebaseProjectId: function () {
    return "residenz-app-f37b5";
  },

  FirebaseStorageBucket: function () {
    return "residenz-app-f37b5.appspot.com";
  },

  FirebaseMessageSenderId: function () {
    return "991371730853";
  },

  FirebaseAppId: function () {
    return "1:991371730853:web:51547e35b756fa2e4b524c";
  },

  FirebaseMeasurementId: function () {
    return "G-ZW3LM0VE6E";
  },

  FirebaseConfig: function () {
    return {
      apiKey: module.exports.FirebaseApiKey(),
      authDomain: module.exports.FirebaseAuthDomain(),
      projectId: module.exports.FirebaseProjectId(),
      storageBucket: module.exports.FirebaseStorageBucket(),
      messagingSenderId: module.exports.FirebaseMessageSenderId(),
      appId: module.exports.FirebaseAppId(),
      measurementId: module.exports.FirebaseMeasurementId(),
    };
  },
};
