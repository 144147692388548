<div (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
  <mat-tab-group
    mat-stretch-tabs="true"
    mat-align-tabs="center"
    [(selectedIndex)]="selectedTab"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon">event</mat-icon> -->
        Aktuell
      </ng-template>

      <div *ngFor="let data of currentEvents; let i = index" class="event-view">
        <event-view
          [data]="data"
          [users]="users"
          [userOptions]="userOptions"
          [accountingYear]="accountingYear"
          [viewMode]="viewMode"
          (componentChanged)="eventChanged($event)"
          (componentLoaded)="subComponentIsLoaded($event)"
        ></event-view>
      </div>
      <div *ngIf="currentEvents?.length == 0" class="no-events-text">
        <p>Keine Events vorhanden</p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon">event_upcoming</mat-icon> -->
        Zukunft
      </ng-template>

      <div *ngFor="let data of futureEvents; let i = index" class="event-view">
        <event-view
          [data]="data"
          [users]="users"
          [userOptions]="userOptions"
          [accountingYear]="accountingYear"
          [viewMode]="viewMode"
          (componentChanged)="eventChanged($event)"
          (componentLoaded)="subComponentIsLoaded($event)"
        ></event-view>
      </div>
      <div *ngIf="futureEvents?.length == 0" class="no-events-text">
        <p>Keine Events vorhanden</p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <mat-icon class="example-tab-icon">done</mat-icon> -->
        Beendet
      </ng-template>

      <div *ngFor="let data of pastEvents; let i = index" class="event-view">
        <event-view
          [data]="data"
          [users]="users"
          [userOptions]="userOptions"
          [accountingYear]="accountingYear"
          [viewMode]="viewMode"
          (componentChanged)="eventChanged($event)"
          (componentLoaded)="subComponentIsLoaded($event)"
        ></event-view>
      </div>
      <div *ngIf="pastEvents?.length == 0" class="no-events-text">
        <p>Keine Events vorhanden</p>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<button
  mat-fab
  style="z-index: 9998!important;"
  class="history-btn"
  color="primary"
  aria-label="Event hinzufügen"
  (click)="addEvent()"
  *ngIf="viewMode == enumViewMode.Admin && onlyEventsAdmin()"
>
  <mat-icon class="history-icon">add</mat-icon>
</button>
