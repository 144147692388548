import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable } from 'rxjs';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import {
  AddParticipantsDialogData,
  IDateRange,
  IParticipant,
  ParticipantState,
  Properties,
  User,
} from 'src/app/services/definitions.service';

@Component({
  selector: 'app-add-participants',
  templateUrl: './add-participants.component.html',
  styleUrls: ['./add-participants.component.css'],
})
export class AddParticipantsComponent implements OnInit {
  filteredOptions: Observable<string[]>;
  filteredNameOptions: Observable<string[]>;
  allUsersData: User[];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  title: string = 'Teilnehmer verwalten';
  displayedColumns: string[] = ['select', 'firstname', 'lastname'];
  modifying: boolean;
  changedTimesValid: boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<AddParticipantsDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: AddParticipantsDialogData,
    private commonFunctions: CommonFunctionsService,
    private dataModel: DataModelService
  ) {
    this.allUsersData = data.allUserData;
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.allUsersData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.data.participants?.length > 0) {
      var succeeded: boolean = true;

      //iterate over assigned participants list
      this.data.participants.forEach((element) => {
        var filtered = this.dataSource.data.filter(
          (x) => x.id === element.userId
        );
        if (filtered.length > 0) {
          var exists = filtered[0];

          //set start and end to this object to show in list
          exists[Properties.Start] = element.start;
          exists[Properties.End] = element.end;
          this.selection.toggle(exists);
        } else {
          succeeded = false;
        }
      });

      //sort selected to top
      this.sortSelectedToTop();

      if (!succeeded) {
        this.commonFunctions.showErrorToast(
          'Mindestens ein User wurde nicht in der Liste gefunden'
        );
      }
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //name selection done
  public optionSelected(val: any) {}

  displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  //save participants
  public async set() {
    var selection = this.selection.selected;
    var toSave: any[] = [];

    selection.forEach((element) => {
      var firstName = this.commonFunctions.getPrettyPrintedFirstName(element);
      var lastName = this.commonFunctions.getPrettyPrintedLastName(element);
      var startDate = element.start;
      var endDate = element.end;

      if (startDate == undefined) {
        startDate = this.data.workPackage.start;
      }
      if (endDate == undefined) {
        endDate = this.data.workPackage.end;
      }

      var data: IParticipant = {
        state: ParticipantState.PARTICIPATING,
        firstName: firstName,
        lastName: lastName,
        userId: element.uid,
        start: startDate,
        end: endDate,
        present: false
      };
      toSave.push(data);
    });

    var path = this.data.path; //path to work package
    var set = await this.dataModel.setParticipants(path, toSave);
    if (set.result) {
      this.commonFunctions.showSuccessToast('Teilnehmer updated');
    } else {
      this.commonFunctions.showErrorToast(
        'Teilnehmer update nicht erfolgreich'
      );
    }

    this.data.result = 1;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  // Sort the selected elements to the top of the list
  public sortSelectedToTop() {
    var selected = this.dataSource.data.filter((r) =>
      this.selection.isSelected(r)
    );
    var unselected = this.dataSource.data.filter(
      (r) => !this.selection.isSelected(r)
    );

    var all = selected.concat(unselected);
    this.dataSource = new MatTableDataSource(all);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public getPrettyPrintedFirstName(userData: User) {
    return this.commonFunctions.getPrettyPrintedFirstName(userData);
  }
  public getPrettyPrintedLastNameName(userData: User) {
    return this.commonFunctions.getPrettyPrintedLastName(userData);
  }

  //Converts a firestore timestamp to a JS Date object
  public asDate(
    firestoreTimestamp: firebase.firestore.Timestamp,
    isStart: boolean = false
  ): Date {
    if (firestoreTimestamp) {
      return new Date(firestoreTimestamp.seconds * 1000);
    } else {
      //use time frame from work package as default
      if (isStart) {
        return new Date(this.data.workPackage.start.seconds * 1000);
      } else {
        return new Date(this.data.workPackage.end.seconds * 1000);
      }
    }
  }

  public timestampToDate(timestamp: firebase.firestore.Timestamp): Date {
    if (timestamp) {
      return new Date(timestamp.seconds * 1000);
    }
    return undefined;
  }

  // Raised when child component (date range) signals, that the times have changed
  timeChanged(dateRange: IDateRange, row) {
    if (dateRange && row) {
      this.changedTimesValid = dateRange.valid;

      if (dateRange.valid) {
        row.start = firebase.firestore.Timestamp.fromDate(dateRange.start);
        row.end = firebase.firestore.Timestamp.fromDate(dateRange.end);
      }
    }
  }
}
