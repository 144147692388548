<div style="text-align: center; height: -webkit-fill-available">
  <div id="circle-container">
    <div class="circle-item">
      <span class="dot" [style.borderColor]="getCircleColor()">
        <p class="circle-content blinking-text">
          {{ userData?.sumWorkHours }}
        </p>
      </span>
    </div>
    <div class="circle"></div>
    <!--Info: zusaetzlichen circle starten-->
    <!-- <div class="circle" style="animation-delay: 2.5s"></div> -->
  </div>

  <form>
    <mat-form-field style="text-align: center; width: auto; font-size: 1.1em">
      <input
        matInput
        value="Mindestanzahl: {{ getMinWorkHoursToPerform() }}"
        readonly
      />
    </mat-form-field>
    <div style="text-align: center; margin-top: -15px">
      <button
        style="font-size: 0.8rem"
        mat-icon-button
        (click)="showInfoDialog()"
        aria-label="Infos wie sich die Stunden berechnen"
      >
        <mat-icon>info</mat-icon>
      </button>
      <span style="font-size: 0.8rem" (click)="showInfoDialog()"
        >Wie berechnet sich die Mindestanzahl?</span
      >
    </div>
  </form>
</div>

<button
  *ngIf="usersWorkHoursData?.length > 0"
  mat-fab
  class="history-btn"
  color="primary"
  aria-label="Arbeitsstunden-Historie"
  (click)="showWorkHoursHistory()"
>
  <mat-icon class="history-icon">history</mat-icon>
</button>

<!--https://www.npmjs.com/package/ng-circle-progress-->
<!-- <circle-progress
    [titleColor]="'#545454'"
    [outerStrokeColor]="'#3936e2'"
    [innerStrokeColor]="getCircleColor()"
    [backgroundPadding]="50"
    [backgroundStrokeWidth]="0"
    [titleFontSize]="80"
    [animationDuration]="1500"
    [backgroundGradient]="true"
    [backgroundOpacity]="1"
    [outerStrokeWidth]="0"
    [innerStrokeWidth]="5"
    [space]="5"
    [percent]="userData?.sumWorkHours"
    [radius]="120"
    [showSubtitle]="false"
    [showUnits]="false"
    [showBackground]="false"
    [showBackground]="false"
    [responsive]="false"
  ></circle-progress> -->
