import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { EventDialogComponent } from '../event-dialog/event-dialog.component';

interface Event {
  start: Date;
  end: Date;
  title: string;
  type: string;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css'],
})
export class CalendarComponent implements OnInit {
  currentMonth: Date;
  calendarDays: { date: Date; currentMonth: boolean }[];
  daysOfWeek: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  events: Event[] = [];
  selectedDate: Date | null = null;
  selectedEvent: Event | null = null;
  today: Date = new Date();
  maxDots: number = 5; // Default value, will be recalculated
  @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
  menuTopLeftPosition = { x: '0', y: '0' };


  //Source: Time Picker: https://tonysamperi.github.io/ngx-mat-timepicker/

  //TODO: 
  //- Problem mit dot overflow
  //- Verschiedene Kalender mit auswahl bei anlage und auswahelbar an der seite
  //- Anstatt von Punkten, einen Balken anzeigen der auch overflowed wenn ueber mehrere Tage (im Browser mode)

  constructor(
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private el: ElementRef
  ) {
    this.currentMonth = new Date();
  }

  ngOnInit(): void {
    this.generateCalendar();
    this.calculateMaxDots();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.calculateMaxDots();
  }

  calculateMaxDots(): void {
    const dayCellWidth =
      this.el.nativeElement.querySelector('.day-cell').offsetWidth;
    const dotSize = 10; // size of the dot including margin
    this.maxDots = Math.floor(dayCellWidth / dotSize);
  }

  generateCalendar(): void {
    this.calendarDays = [];
    const firstDayOfMonth = new Date(
      this.currentMonth.getFullYear(),
      this.currentMonth.getMonth(),
      1
    );
    const lastDayOfMonth = new Date(
      this.currentMonth.getFullYear(),
      this.currentMonth.getMonth() + 1,
      0
    );

    const startDay = (firstDayOfMonth.getDay() + 6) % 7; // Adjust for Monday start
    for (let i = startDay; i > 0; i--) {
      const day = new Date(firstDayOfMonth);
      day.setDate(day.getDate() - i);
      this.calendarDays.push({ date: day, currentMonth: false });
    }

    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      this.calendarDays.push({
        date: new Date(
          this.currentMonth.getFullYear(),
          this.currentMonth.getMonth(),
          i
        ),
        currentMonth: true,
      });
    }

    const endDay = (lastDayOfMonth.getDay() + 6) % 7; // Adjust for Monday start
    for (let i = 1; i < 7 - endDay; i++) {
      const day = new Date(lastDayOfMonth);
      day.setDate(day.getDate() + i);
      this.calendarDays.push({ date: day, currentMonth: false });
    }
    this.cdr.detectChanges();
  }

  updateTitle(): void {
    this.cdr.detectChanges();
  }

  prevMonth(): void {
    this.currentMonth.setMonth(this.currentMonth.getMonth() - 1);
    this.generateCalendar();
    this.updateTitle();
  }

  nextMonth(): void {
    this.currentMonth.setMonth(this.currentMonth.getMonth() + 1);
    this.generateCalendar();
    this.updateTitle();
  }

  goToToday(): void {
    this.currentMonth = new Date();
    this.generateCalendar();
    this.updateTitle();
  }

  openEventDialog(day: Date, event: Event | null = null): void {
    const dialogRef = this.dialog.open(EventDialogComponent, {
      width: '250px',
      data: { date: day, event: event },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (event) {
          const index = this.events.indexOf(event);
          if (index !== -1) {
            this.events[index] = result;
          }
        } else {
          this.events.push(result);
        }
        this.generateCalendar();
      }
    });
  }

  getEvents(date: Date): Event[] {
    return this.events.filter(
      (event) => event.start <= date && event.end >= date
    );
  }

  isToday(date: Date): boolean {
    return date.toDateString() === this.today.toDateString();
  }

  selectDate(date: Date): void {
    this.selectedDate = date;
    // this.openEventDialog(date);
  }

  hasEvents(date: Date): boolean {
    return this.getEvents(date).length > 0;
  }

  editEvent(event: Event, day: Date, $event: MouseEvent): void {
    $event.stopPropagation();
    this.selectedEvent = event;
    this.openEventDialog(day, event);
  }

  getEventDots(date: Date): { dots: Event[]; extra: boolean } {
    const events = this.getEvents(date);
    const dots = events.slice(0, this.maxDots).map((event) => event);
    const extra = events.length > this.maxDots;
    return { dots, extra };
  }

  getDotColor(type: string): string {
    switch (type) {
      case 'meeting':
        return '#ff5722'; // Red
      case 'task':
        return '#4caf50'; // Green
      case 'reminder':
        return '#2196f3'; // Blue
      default:
        return '#ffeb3b'; // Yellow
    }
  }

  onRightClick(event: MouseEvent, day: Date) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';
    this.selectedDate = day;
    // we open the menu
    this.matMenuTrigger.openMenu();
  }


  isSelectedDate(date: Date): boolean {
    return this.selectedDate && date.toDateString() === this.selectedDate.toDateString();
  }
}
