import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    QrCodeDialogData  
} from 'src/app/services/definitions.service';

@Component({
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css'],    
})
export class QrCodeDialog implements OnInit {
  qrCodeGenerated: boolean = false;
  public qrCodeSource: string = '';
  public title: string = "";

  constructor(
    public dialogRef: MatDialogRef<QrCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: QrCodeDialogData,
  ) {
    this.title = data.title;
    this.qrCodeSource = `${data.qrCodeTrigger.valueOf()};${data.qrCodeLink}`;
  }

  ngOnInit(): void {  
  }

  onNoClick(): void {
    this.data.decisionYes = false;
    this.dialogRef.close(this.data);
  }  
}
