<h1 mat-dialog-title>{{title}}</h1>
<hr />
<div mat-dialog-content style="text-align: center">
  <qr-code [value]="qrCodeSource" [size]="230"></qr-code>
</div>
<hr />
<div mat-dialog-actions class="dialog-btn-container">
  <div>
    <button
      mat-raised-button
      color="primary"
      class="dialog-btn"
      (click)="onNoClick()"
    >
      Schliessen
    </button>
  </div>
</div>
