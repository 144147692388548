<mat-card class="card">
  <mat-card-header>
    <mat-card-subtitle *ngIf="viewMode == enumViewMode.Admin">
      {{ getRunningState() }} {{ getProccessedText() }}</mat-card-subtitle
    >
    <mat-card-title>
      {{ data.name }}

      <!--QR Code TODO:-->
      <!-- <button
        mat-icon-button
        style="position: absolute; right: 0px; top: -5px"
        class="footer-icon"
        aria-label="QR Code anzeigen"
        matTooltip="QR-Code anzeigen"
        (click)="showQrCode()"
        [disabled]="data?.processedInBackend"
        *ngIf="isVisible(visibility.QrCodeTop)"
      >
        <mat-icon>qr_code_2</mat-icon>
      </button> -->
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="data-table" *ngIf="isVisible(visibility.EventText)">
      <table style="margin-top: 15px">
        <tr>
          <td class="tbl-icons"><mat-icon>home</mat-icon></td>
          <td class="tbl-content">{{ data.locationName }}</td>
        </tr>
        <tr>
          <td class="tbl-icons"></td>
          <td class="tbl-content">{{ data.street }} {{ data.houseNumber }}</td>
        </tr>
        <tr>
          <td class="tbl-icons"></td>
          <td class="tbl-content">{{ data.plz }} {{ data.city }}</td>
        </tr>
        <tr>
          <td class="tbl-icons"><mat-icon>person</mat-icon></td>
          <td class="tbl-content">
            {{
              data.responsiblePerson == ""
                ? "nicht festgelegt"
                : data.responsiblePerson
            }}
          </td>
        </tr>
        <tr>
          <td class="tbl-icons"><mat-icon>calendar_month</mat-icon></td>
          <td class="tbl-content">
            {{ data.start?.seconds * 1000 | date : "dd.MM.yyyy" }} -
            {{ data.end?.seconds * 1000 | date : "dd.MM.yyyy" }}
          </td>
        </tr>
        <tr *ngIf="data?.description != ''">
          <td class="tbl-icons"><mat-icon>description</mat-icon></td>
          <td class="tbl-content">
            {{ data.description }}
          </td>
        </tr>
      </table>
    </div>

    <mat-divider *ngIf="isVisible(visibility.FirstDivider)"></mat-divider>

    <p
      *ngIf="isVisible(visibility.TextFreieSchichten)"
      style="margin-top: 20px; font-size: 1.5em"
    >
      Schichten
    </p>

    <!-- Grouped Work Packages (Admin|Participant view mode)-->
    <div *ngIf="isVisible(visibility.DateSeparatedWorkPackages)">
      <div *ngFor="let dgWp of grouped">
        <grouped-work-packages
          [data]="dgWp"
          [users]="users"
          [accountingYear]="accountingYear"
          [userOptions]="userOptions"
          [event]="data"
          [viewMode]="viewMode"
          (componentChanged)="wpChanged($event)"
          (doReload)="wpChanged($event)"
          (componentLoaded)="gwpLoaded(dgWp)"
        ></grouped-work-packages>
      </div>
    </div>

    <!-- Work Packages (Simplified admin view mode) -->
    <table
      *ngIf="isVisible(visibility.SimplifiedWorkPackges)"
      style="margin-top: 20px"
    >
      <tr *ngFor="let x of data.workPackages">
        <td>
          <p>
            {{ x.start?.seconds * 1000 | date : "dd.MM. | H:mm" }} Uhr -
            {{ x.end?.seconds * 1000 | date : "H:mm" }} Uhr
          </p>
          {{showWpDebugInfo(x)}}
          <wp-view
            *ngIf="showWp(x)"
            [data]="x"
            [users]="users"
            [accountingYear]="accountingYear"
            [userOptions]="userOptions"
            [event]="data"
            [viewMode]="viewMode"
            (componentChanged)="wpChanged($event)"
            (componentLoaded)="wpViewLoaded(x)"
          ></wp-view>
        </td>
      </tr>
    </table>

    <!-- Work Packages (Reader view mode)-->
    <div style="margin-top: 20px" *ngIf="isVisible(visibility.WpForReaders)">
      <div *ngFor="let x of data.workPackages" style="margin-bottom: 20px">
        {{showWpDebugInfo(x)}}
        <wp-view
          *ngIf="showWp(x)"
          [data]="x"
          [users]="users"
          [accountingYear]="accountingYear"
          [userOptions]="userOptions"
          [event]="data"
          [viewMode]="viewMode"
          (componentChanged)="wpChanged($event)"
          (componentLoaded)="wpViewLoaded(x)"
        ></wp-view>
      </div>
    </div>

    <p *ngIf="data?.workPackages?.length == 0" style="margin-top: 20px">
      Keine Schichten vorhanden
    </p>

    <mat-divider *ngIf="isVisible(visibility.SecondDivider)"></mat-divider>
  </mat-card-content>
  <mat-card-footer>
    <mat-slide-toggle
      class="footer-icon"
      style="position: absolute; left: 10px; padding-top: 10px"
      matTooltip="Zur Anmeldung freigeben"
      color="primary"
      (change)="changeReleaseForUsers($event)"
      [checked]="releaseForUsersChecked"
      [disabled]="data?.processedInBackend"
      *ngIf="isVisible(visibility.FreigabeTooggle)"
      >Freigabe</mat-slide-toggle
    >

    <!-- <button
      mat-icon-button
      class="footer-icon"
      aria-label="QR Code anzeigen"
      matTooltip="QR-Code anzeigen"
      (click)="showQrCode()"
      [disabled]="data?.processedInBackend"
      *ngIf="isVisible(visibility.QrCodeFooter)"
    >
      <mat-icon>qr_code_2</mat-icon>
    </button> -->

    <button
      mat-icon-button
      class="footer-icon"
      aria-label="bearbeiten"
      matTooltip="Bearbeiten"
      (click)="edit()"
      [disabled]="data?.processedInBackend"
      *ngIf="isVisible(visibility.EditButton)"
    >
      <mat-icon>edit</mat-icon>
    </button>

    <button
      mat-icon-button
      class="footer-icon"
      aria-label="loeschen"
      matTooltip="Löschen"
      (click)="delete()"
      [disabled]="data?.processedInBackend"
      *ngIf="isVisible(visibility.DeleteButton)"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      mat-icon-button
      class="footer-icon"
      style="margin-right: 20px"
      aria-label="Schicht hinzufügen"
      matTooltip="Schicht hinzufügen"
      (click)="addWorkPackage()"
      [disabled]="data?.processedInBackend"
      *ngIf="isVisible(visibility.AddButton)"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-footer>
</mat-card>
