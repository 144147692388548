<mat-card class="calendar-container">
  <div class="calendar-header">
    <button mat-button (click)="prevMonth()">Previous</button>
    <h2>{{ currentMonth | date: 'MMMM yyyy' }}</h2>
    <button mat-button (click)="nextMonth()">Next</button>
    <button mat-button (click)="goToToday()">Today</button>
  </div>
  <div class="calendar-grid">
    <div class="day-header" *ngFor="let day of daysOfWeek">{{ day }}</div>
    <div class="day-cell" 
         *ngFor="let day of calendarDays" 
         [ngClass]="{'today': isToday(day.date), 'has-events': hasEvents(day.date), 'other-month': !day.currentMonth, 'selected': isSelectedDate(day.date)}"
         (click)="selectDate(day.date)"
         (contextmenu)="onRightClick($event, day.date)">
      <span>{{ day.date.getDate() }}</span>
      <div class="events">
        <ng-container *ngFor="let dot of getEventDots(day.date).dots; let i = index">
          <span class="event-dot" 
                [style.background-color]="getDotColor(dot.type)" 
                (click)="editEvent(dot, day.date, $event)"></span>
        </ng-container>
        <span class="more-dots" *ngIf="getEventDots(day.date).extra">...</span>
      </div>
    </div>
  </div>
</mat-card>

<mat-card class="events-container" *ngIf="selectedDate">
  <h3>Events on {{ selectedDate | date: 'fullDate' }}</h3>
  <div *ngFor="let event of getEvents(selectedDate)">
    <p>{{ event.title }} ({{ event.start | date: 'shortTime' }} - {{ event.end | date: 'shortTime' }})</p>
  </div>
</mat-card>

<!-- an hidden div is created to set the position of appearance of the menu-->
<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="rightMenu"
></div>

<!-- standard material menu -->
<mat-menu #rightMenu="matMenu">
  <button mat-menu-item (click)="openEventDialog(selectedDate)">
    Add Event
  </button>
  <button mat-menu-item *ngIf="selectedEvent" (click)="openEventDialog(selectedDate, selectedEvent)">Edit Event</button>
</mat-menu>
