import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  CommonFunctionsService,
  DialogType,
} from 'src/app/services/common-functions.service';
import {
  AddParticipantsDialogData,
  AddWpDialogData,
  IEvent,
  IWorkPackage,
  IComponentChanged,
  Sub_Participant,
  EventViewMode,
  EventState,
  IParticipant,
  AbortShiftDialogData,
  User,
  WorkPackageKind,
  ParticipantState,
  WpViewTitle,
  Users,
  QrCodeDialogData,
  CamScanDialogData,
  QrCodeTrigger,
} from 'src/app/services/definitions.service';
import { AddParticipantsComponent } from '../../dialogs/add-participants/add-participants.component';
import { MatDialog } from '@angular/material/dialog';
import { DataModelService } from 'src/app/services/data-model.service';
import { LogService } from 'src/app/services/log.service';
import { AddWpComponent } from '../../dialogs/add-wp/add-wp/add-wp.component';
import { MatAccordion } from '@angular/material/expansion';
import { ShiftAbortComponent } from 'src/app/dialogs/shift-abort/shift-abort.component';
import { WpKindPipe } from 'src/app/pipes/wp-kind.pipe';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { QrCodeDialog } from 'src/app/dialogs/qr-code/qr-code.component';
import { CamScanDialog } from 'src/app/dialogs/cam-scan/cam-scan-dialog/cam-scan-dialog.component';

enum VisibilityIndex {
  Overall = 0,
  PanelDesc1 = 1,
  PanelDesc2 = 2,
  FullInfoTable = 3,
  SmallInfoTable = 4,
  ParticipantsComponents = 5,
  ParticipantJoin = 6,
  EditButton = 7,
  DeleteButton = 8,
  AddButton = 9,
  PanelDesc3 = 10,
  ParticipantsCount = 11,
  CallShiftOrganizerButton = 12,
  ConstructionDismantlingTableRows = 13,
  ButtonStartConstrDismant = 14,
  ParticipantJoinHints = 15,
  FreigabeTooggle = 16,
  Ue18Text = 17,
  QrCodeFooter = 18,
  QrCodeButton = 19,
  ContrDismPresenceScanBtn = 20,
}

@Component({
  selector: 'wp-view',
  templateUrl: './wp-view.component.html',
  styleUrls: ['./wp-view.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class WpViewComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() data: IWorkPackage;
  @Input() users: User[];
  @Input() accountingYear: any;
  @Input() userOptions: any[];
  @Input() event: IEvent;
  @Input() viewMode: EventViewMode;
  @Output() componentChanged = new EventEmitter<IComponentChanged>();
  @Output() componentLoaded = new EventEmitter<boolean>();
  panelOpenState = false;
  participatesThisShift: boolean = false;
  wpKindEnum = WorkPackageKind;
  public releaseForUsersChecked = true;

  public enumViewMode = EventViewMode;
  public visibility = VisibilityIndex;
  private userData: User;

  constructor(
    private dataModel: DataModelService,
    private common: CommonFunctionsService,
    private log: LogService,
    public dialog: MatDialog,
    private wpPipe: WpKindPipe,
    private authService: AuthService
  ) {
    // Assign the data to the data source for the table to render
    this.common.registerEventEmitterHandlerUserData(async (data: User) => {
      this.userData = data;
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.update();
    if (this.data) {
      //To avoid: Expression has changed after it was checked
      setTimeout(() => {
        if (this.data?.releasedForUsers) {
          this.releaseForUsersChecked = this.data.releasedForUsers;
        } else {
          this.releaseForUsersChecked = false;
        }
      }, 500);
    }
  }

  //Gets the state of this event: not started, running, past
  public getRunningState(): string {
    return this.common.getRunningState(this.data);
  }

  //Load all work packages of an event
  public async showParticipantsEditDialog() {
    var path = `${this.data.path}/${Sub_Participant()}`;
    var loaded = await this.dataModel.get(path);
    if (!loaded.result) {
      this.common.showErrorToast('Teilnehmer konnten nicht geladen werden');
      this.log.error(loaded.errorMessage);
    } else {
      var loadedParticipants = loaded.data;

      var data: AddParticipantsDialogData = {
        result: 0,
        allUserData: this.users,
        workPackage: this.data,
        path: this.data.path,
        parentPath: this.data.parent,
        id: this.data.id,
        participants: loadedParticipants,
      };
      this.dialog
        .open(AddParticipantsComponent, {
          width: '90%',
          data: data,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result.result == 1) {
            this.update(true);
          }
        });
    }
  }

  // Loads all participants
  private async update(emitChange: boolean = false) {
    var path = `${this.data.path}/${Sub_Participant()}`;
    var loaded = await this.dataModel.get(path, false, true);
    if (!loaded.result) {
      this.common.showErrorToast('Teilnehmer konnten nicht geladen werden');
      this.log.error(loaded.errorMessage);
    } else {
      var loadedParticipants: IParticipant[] = loaded.data;

      //exclude the one that have ended the shift eralier
      this.data.participants = loadedParticipants.filter((x) => {
        return x.state != ParticipantState.ENDED_EARLIER;
      });
      this.emitLoaded();

      //check if current user is registered for this workPackage/shift
      this.searchUserInParticipantsList();
      if (emitChange) {
        this.emitChange();
      }
    }
  }

  //Edit a work package
  public async edit() {
    if (this.event == undefined) {
      this.common.showErrorToast('Event hat keine Daten');
      return;
    }
    if (this.accountingYear == undefined) {
      this.common.showErrorToast('Daten zum Abrechnungsjahr nicht geladen');
      return;
    }
    //check if construction- and/or dismantling-shift is already present
    var constructionAlreadyCreated = this.common.isWpKindAlreadyAvailable(
      this.event.workPackages,
      WorkPackageKind.Construction
    );
    var dismantlingAlreadyCreated = this.common.isWpKindAlreadyAvailable(
      this.event.workPackages,
      WorkPackageKind.Dismantling
    );

    var data: AddWpDialogData = {
      result: 0,
      allUserData: this.users,
      userOptions: this.userOptions,
      path: this.data.path,
      parentPath: this.data.parent,
      type: this.data.type,
      minParticipants: this.data.minParticipants,
      id: this.data.id,
      responsiblePerson: this.data.responsiblePerson,
      responsiblePersonId: this.data.responsiblePersonUid,
      start: this.data.start,
      end: this.data.end,
      eventData: this.event,
      kind: this.data.kind,
      constructionShiftPresent: constructionAlreadyCreated,
      dismantlingShiftPresent: dismantlingAlreadyCreated,
      isUe18: this.data.isUe18,
    };

    this.dialog
      .open(AddWpComponent, {
        width: '550px',
        data: data,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result.result == 1) {
          this.emitChange();
        }
      });
  }

  //Raised on button click to delete an work package
  public async delete() {
    this.common
      .openDialog(
        'Löschen',
        'Schicht wirklich löschen?',
        DialogType.INFO,
        'Nein',
        'Ja'
      )
      .afterClosed()
      .subscribe(async (result) => {
        if (result.result == 1) {
          //delete all participants of work packages
          var deletedParticipants = await this.dataModel.deleteParticipants(
            this.data.path
          );
          if (!deletedParticipants.result) {
            this.log.error(
              'Failed to delete participants of work package: ' + this.data.id
            );
          }
          //delete work package
          var ret = await this.dataModel.deleteGeneric(this.data.path);
          if (ret) {
            this.common.showSuccessToast('Schicht gelöscht');
          } else {
            this.common.showErrorToast('Schicht konnte nicht gelöscht werden');
          }
          this.emitChange();
        }
      });
  }

  //Checks if this user is registered for this shift
  public isRegisteredForShift() {
    return this.participatesThisShift;
  }

  //unregister from shift
  public async unregisterForWorkPackage() {
    var eventOrganizer = this.findEventOrganizer();
    var shiftOrganizer = this.findWpOrganizer();
    var nameAbortee = `${this.common.getPrettyPrintedUserName(this.userData)}`;
    var isConstructionOrDismantling = this.isConstructionOrDismantling();

    //Wenn Auf- oder Abbau:
    if (isConstructionOrDismantling) {
      //  - Wenn aktuelle Zeit < Start der Schicht: Ganz normal verfahren und Shift-Abort Dialog anzeigen
      //  - Wenn aktuelle Zeit innerhalb Schicht-Zeit: Nichts tun. Also auch keinen Button zur abmeldung anzeigen. Admin muss das klaeren.
      //  - Wenn aktuelle Zeit > Schicht-Ende: Keinen abmelde-button anzeigen
      if (this.getRunningState() == EventState.NotBegan) {
        //do nothing (pass)
      } else if (this.getRunningState() == EventState.Running) {
        //do nothing (return)
        return;
      } else {
        // do nothing
        return;
      }
      //Wenn normale Schicht:
    } else {
      //  - Wenn aktuelle Zeit < Start der Schicht: Normal verfahren. Shift abort dialog zeigen.
      //  - Wenn aktuelle Zeit innerhalb Schicht-Zeit: Schicht schliessen bzw. den Endzeitpunkt auf den aktuellen Zeitpunkt setzen.
      //  - Wenn aktuelle Zeit > Schicht-Ende: Out of scope

      if (this.getRunningState() == EventState.NotBegan) {
        //do nothing (pass)
      } else if (this.getRunningState() == EventState.Running) {
        var now = firebase.firestore.Timestamp.fromDate(new Date());

        //find particapant data
        var participant = this.searchUserInParticipantsList();
        if (participant) {
          participant.end = now;
          participant.state = ParticipantState.ENDED_EARLIER;
          var updatedParticipant = await this.dataModel.updateParticipant(
            participant
          );
          if (!updatedParticipant.result) {
            this.log.error(
              'Failed to update participants data. Id: ' + participant.id
            );
            this.common.showErrorToast(
              'Schicht-Daten konnten nicht gespeichert werden'
            );
          } else {
            this.common.showSuccessToast('Schicht erfolgreich beendet');
            this.update(true);
          }
        } else {
          this.common.showErrorToast(
            'Schicht-Daten konnten nicht geladen werden'
          );
        }
        return;
      } else {
        // do nothing
        return;
      }
    }

    var data: AbortShiftDialogData = {
      decisionYes: false,
      userDataOrganizer: eventOrganizer,
      userDataShiftRespPerson: shiftOrganizer,
      eventData: this.event,
      shiftData: this.data,
      aborteeName: nameAbortee,
      shiftOrganizerPhoneNumber: this.getShiftOrganizerTelNumber(),
    };
    var dialogResult: AbortShiftDialogData = await this.dialog
      .open(ShiftAbortComponent, {
        width: '90%',
        data: data,
      })
      .afterClosed()
      .toPromise();

    if (dialogResult.decisionYes) {
      if (this.data.participants) {
        var found = this.searchUserInParticipantsList();
        if (found) {
          var set = await this.dataModel.deleteGeneric(found.path);
          if (set.result) {
            this.common.showSuccessToast('Abmeldung Erfolgreich');
            this.update(true);
            return;
          }
        }
      }
      this.common.showErrorToast('Konnte dich nicht abmelden');
    }
  }

  //Registers this user for the shift
  public async registerForWorkPackage() {
    var wp = this.data;

    //Note: this is currently disabled
    //If construction or dismantling, start showing qr code scan
    // if (this.isConstructionOrDismantling()) {
    //   wp = await this.handleQrCodeShiftRegistration(wp);
    //   if (!wp) {
    //     return;
    //   }
    // }

    //initial check
    if (!this.userData || !wp) {
      this.common.showErrorToast(
        'Deine User-Daten konnten nicht geladen werden'
      );
      return;
    }

    var firstName = this.common.getPrettyPrintedFirstName(this.userData);
    var lastName = this.common.getPrettyPrintedLastName(this.userData);
    var startDate = wp.start;
    var endDate = wp.end;

    //if the shift is already running, take the current time as the start time
    if (this.getRunningState() == EventState.Running)
      startDate = firebase.firestore.Timestamp.fromDate(new Date());

    //check if already added (in case of early abortion)
    var existing = await this.dataModel.entryExisting(
      wp.path + '/' + Sub_Participant(),
      this.userData.uid
    );
    if (existing) {
      this.common.showErrorToast(
        'Du bist bereits für diese Schicht angemeldet. Eventuell hast du sie schon vorzeitig beendet. Melde dich beim Schichtleiter.',
        10000
      );
      return;
    }

    //leave present flag to false. Admin shall define if the person is at his working place.
    var participantData: IParticipant = {
      state: ParticipantState.PARTICIPATING,
      firstName: firstName,
      lastName: lastName,
      userId: this.userData.uid,
      start: startDate,
      end: endDate,
      present: false,
    };

    if (this.isConstructionOrDismantling()) {
      participantData.present = true;
    }

    var path = wp.path; //path to work package
    var set = await this.dataModel.addParticipant(path, participantData);
    if (set.result) {
      this.common.showSuccessToast('Anmeldung Erfolgreich');
      this.update(true);
    } else {
      this.common.showErrorToast('Anmeldung fehlgeschlagen');
    }
  }

  //Handles the registration for a construction or dismantling shift.
  private async handleQrCodeShiftRegistration(
    wp: IWorkPackage
  ): Promise<IWorkPackage> {
    var wpPath = '';
    var res = await this.showCamScanDialog(
      QrCodeTrigger.CONSTR_DEMANTL_USER_PRESENT
    );
    if (res.decisionYes) {
      wpPath = res.payload;
    } else {
      return;
    }
    return await this.checkWp(wpPath);
  }

  //Handles the registration for a construction or dismantling shift.
  public async markUserAsPresent() {
    var wpPath = '';
    var res = await this.showCamScanDialog(
      QrCodeTrigger.CONSTR_DEMANTL_USER_PRESENT
    );
    if (res.decisionYes) {
      wpPath = res.payload;
    } else {
      return;
    }
    var wpLoaded = await this.checkWp(wpPath);
    if (wpLoaded == undefined) return;

    //mark all participants as started/ended
    if (this.data.participants) {
      //find participant
      var foundParticipant = this.data.participants.find((participant) => {
        if (participant.userId == this.userData.uid) {
          return true;
        }
      });
      if (!foundParticipant) {
        this.log.error('Participant not found that is this user');
        this.common.showErrorToast('Konnte dich nicht als Teilnehmer finden');
        return;
      }

      foundParticipant.present = true;
      foundParticipant.start = firebase.firestore.Timestamp.fromDate(
        new Date()
      );
      var updatedParticipant = await this.dataModel.updateParticipant(
        foundParticipant
      );

      if (!updatedParticipant.result) {
        this.log.error(
          'Failed to update participants data. Id: ' + foundParticipant.id
        );
        this.common.showErrorToast('Konnte dich nicht als anwesend melden');
      } else {
        this.common.showSuccessToast('Du bist jetzt als anwesend gemeldet');
      }
    } else {
      this.log.error('No participants found in shift');
      this.common.showErrorToast('Keine Teilnehmer gefunden');
    }
  }

  //Check if a work package is valid for registration
  private async checkWp(wpPath: string): Promise<IWorkPackage | undefined> {
    var wpLoaded = await this.dataModel.getByPath(wpPath);
    if (!wpLoaded.result) {
      this.log.error('Error loading WP by path:' + wpPath);
      this.common.showErrorToast('Schicht konnte nicht geladen werden.');
      return undefined;
    }
    var loadedWp: IWorkPackage = wpLoaded.data;
    var runningState = this.common.getRunningState(loadedWp);

    //check work package
    if (!loadedWp.releasedForUsers) {
      this.common.showErrorToast('Schicht nicht freigegeben.');
      return undefined;
    }
    if (runningState == EventState.Past) {
      this.common.showErrorToast('Schicht bereits zu Ende.');
      return undefined;
    }
    if (runningState == EventState.NotBegan) {
      this.common.showErrorToast('Schicht noch nicht begonnen.');
      return undefined;
    }
    return loadedWp;
  }

  //Signals the parent that something has changed in this component
  private emitChange() {
    var changed: IComponentChanged = { id: this.data.id };
    this.componentChanged.emit(changed);
  }

  //Emit that all data of this view has been loaded
  private emitLoaded() {
    this.componentLoaded.emit(true);
  }

  //Gets the color for the panel
  public getPanelColorClass(): string {
    var ret = '';
    if (this.panelOpenState) {
      ret = 'panel-open';
    }
    if (this.data) {
      var currentCount = this.data.participants?.length;
      var max = this.data.minParticipants;
      if (currentCount < max) {
        ret = 'panel-to-less-participants';
        //exclude 'Aufbau' and 'Abbau'
        if (this.isConstructionOrDismantling()) ret = '';
      }
      if (
        this.viewMode == EventViewMode.Participant ||
        this.viewMode == EventViewMode.Reader
      ) {
        ret = '';
      }
    }
    return ret;
  }

  public onComponentChanged() {
    this.update(true);
  }

  public getDayName(date: Date) {
    return this.common.getDayName(date);
  }

  //Gets the panel header color depending on the running state
  public getHeaderColorClass() {
    switch (this.getRunningState()) {
      case EventState.Past:
        return 'panel-header-gray';
      case EventState.Running:
        return 'panel-header-green';
      default:
        return '';
    }
  }

  //Gets the title text for a work package view
  public getTitleText(): WpViewTitle {
    var ret: WpViewTitle = {
      title: this.data.type,
      timeOfShift: this.getTimeOfShiftFromTo(this.data.start, this.data.end),
    };

    switch (this.viewMode) {
      case EventViewMode.Participant:
        if (!this.isConstructionOrDismantling()) {
          ret.freePlaces = this.getFreePlacesText();
        }
        break;
      case EventViewMode.Admin:
        ret.amountParticipants = this.data.participants?.length;
        if (!this.isConstructionOrDismantling()) {
          ret.amountMinParticipants = this.data.minParticipants;
        }
        break;
      case EventViewMode.Reader:
      case EventViewMode.SimplifiedAdmin:
        ret.dayName = this.getDayName(this.data.start.toDate());
        break;
    }
    if (this.shiftHasEnded()) ret.state = 'beendet';
    else if (this.getRunningState() == EventState.Running)
      ret.state = 'läuft gerade';
    return ret;
  }

  /**
   * Gets the start and end time of a shift.
   *
   * This method is responsible for retrieving the start and end time of a given shift.
   * It assumes that the shift object has 'start' and 'end' properties that are Firestore timestamps.
   *
   */
  private getTimeOfShiftFromTo(
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp
  ): string {
    var s = this.common.firebaseTimestampToTime(start);
    var e = this.common.firebaseTimestampToTime(end);
    return `${s} bis ${e}`;
  }

  private getFreePlacesText() {
    var free = this.data.minParticipants - this.data.participants?.length;
    if (free <= 0) {
      return `Schicht voll`;
    }
    if (free == 1) {
      return `${free} Platz frei`;
    } else {
      return `${free} Plätze frei`;
    }
  }

  //Finds this user in the participants list
  private searchUserInParticipantsList(): IParticipant | undefined {
    var found = this.data.participants?.find(
      (x) => x.userId == this.userData.uid
    );
    this.participatesThisShift = found != undefined;

    //check if responsible person for this shift
    if (this.data.responsiblePersonUid == this.userData.uid)
      this.participatesThisShift = true;

    return found;
  }

  //Finds the organizers data
  private findEventOrganizer(): User | undefined {
    if (this.event && this.event?.responsiblePersonId && this.users) {
      return this.users.find((x) => x.uid == this.event.responsiblePersonId);
    }
    this.log.error("Unable to find Event Organizer's data");
    return undefined;
  }

  //Finds the organizers data
  private findWpOrganizer(): User | undefined {
    if (this.data && this.users) {
      return this.users.find((x) => x.uid == this.data.responsiblePersonUid);
    }
    this.log.error("Unable to find WorkPackage Organizer's data");
    return undefined;
  }

  //checks if current user is the shift organizer
  private isShiftOrganizer(): boolean {
    return this.common.isShiftOrganizer(this.data, this.userData);
  }

  public callShiftOrganizer(telNumber: number) {
    window.open(`tel:${telNumber}`);
  }

  //Checks if a user has a telephone number set
  public getShiftOrganizerTelNumber(): string | undefined {
    var shiftOrganizer = this.findWpOrganizer();
    if (shiftOrganizer) {
      if (shiftOrganizer.contact.telMobile) {
        return shiftOrganizer.contact.telMobile;
      }
      if (shiftOrganizer.contact.telPrivate) {
        return shiftOrganizer.contact.telPrivate;
      }
    }
    return undefined;
  }

  //Starts/Ends Aufbau/Abbau
  //Sets the start time of the shift to now and the end time to 4 hours later.
  public startEndConstructionDismantling(kind: WorkPackageKind) {
    var title = '';
    var msg = '';

    if (this.data.running) {
      title = `${this.wpPipe.transform(kind, 'pipeFilter')} beenden`;
      msg = `Willst du den ${this.wpPipe.transform(
        kind,
        'pipeFilter'
      )} beenden?`;
    } else {
      title = `${this.wpPipe.transform(kind, 'pipeFilter')} starten`;
      msg = `Willst du den ${this.wpPipe.transform(
        kind,
        'pipeFilter'
      )} starten?`;
    }

    //show dialog
    this.common
      .openDialog(title, msg, DialogType.INFO, 'Nein', 'Ja', true)
      .afterClosed()
      .subscribe(async (result) => {
        if (result.result == 1) {
          var ret = await this.dataModel.markWorkPackageStarted(
            this.data.path,
            !this.data.running
          );
          var now = firebase.firestore.Timestamp.fromDate(new Date());
          var failedToUpdateAnyParticipant = false;

          //mark all participants as started/ended
          if (this.data.participants) {
            this.data.participants.forEach(async (participant) => {
              if (this.data.running) participant.end = now;
              else participant.start = now;
              var updatedParticipant = await this.dataModel.updateParticipant(
                participant
              );
              if (!updatedParticipant.result) {
                this.log.error(
                  'Failed to update participants data. Id: ' + participant.id
                );
                failedToUpdateAnyParticipant = true;
              }
            });
          }

          var text = this.data.running ? 'beendet' : 'gestartet';
          if (ret) {
            await this.common.showSuccessToast(
              `${this.wpPipe.transform(
                kind,
                'pipeFilter'
              )} erfolgreich ${text}`,
              3000,
              true
            );

            //show QR Code dialog (in case the event was started)
            if (!this.data.running) {
              this.showQrCodeForPresenceMarker();
            }
          } else {
            await this.common.showErrorToast(
              `${this.wpPipe.transform(
                kind,
                'pipeFilter'
              )} konnte nicht ${text} werden`,
              5000,
              true
            );
          }

          if (failedToUpdateAnyParticipant) {
            await this.common.showErrorToast(
              'Nicht alle Teilnehmer konnten upgedated werden',
              5000,
              true
            );
          }
          //reload
          this.update(true);
        }
      });
  }

  //Checks if the work package is of kind 'Aufbau (COnstruction)' or 'Abbau (Dismantling)'.
  public isConstructionOrDismantling(): boolean {
    if (
      this.data?.kind == WorkPackageKind.Construction ||
      this.data?.kind == WorkPackageKind.Dismantling
    )
      return true;
    return false;
  }

  //Checks if the shift has ended
  private shiftHasEnded(): boolean {
    var now = new Date();
    var shiftTime = new Date(this.data.end.seconds * 1000);
    return shiftTime.getTime() < now.getTime();
  }

  //Checks if the shift is running
  public isShiftRunning(): boolean {
    var now = new Date();
    var shiftStart = new Date(this.data.start.seconds * 1000);
    var shiftEnd = new Date(this.data.end.seconds * 1000);
    return (
      now.getTime() >= shiftStart.getTime() &&
      now.getTime() <= shiftEnd.getTime()
    );
  }

  //Raised when 'Freigabe' toggle button is toggled
  public async changeReleaseForUsers(event) {
    if (event) {
      var released = event.checked;
      var ret = await this.dataModel.setWpReleasedForUsers(
        this.data.path,
        released
      );
      if (!ret) {
        this.common.showErrorToast(ret.errorMessage);
      }
    }
  }

  //Decide if to show the participate button
  public showParticipateButton(): boolean {
    //only these are allowed to participate
    if (
      this.viewMode == EventViewMode.Participant ||
      this.viewMode == EventViewMode.Reader
    ) {
      //if user is shift organizer, hide button
      if (this.data.responsiblePersonUid == this.userData.uid) return false;

      //if work package is only for adults, and current user is under 18, hide
      if(this.data.isUe18 && !this.common.isOver18(this.userData?.personalData?.birthDate))
        return false;

      //if it is an construction or dismantling shift
      if (this.isConstructionOrDismantling()) {
        //check if shift is over. If yes, hide button
        if (this.shiftHasEnded()) return false;

        //Participation at that time via the qr code. Code to scan qr code is implemented in register function.
        if (this.isShiftRunning()) {
          //return false to hide (intended) the shift abort button
          if (this.isRegisteredForShift()) return false;

          //to show shift register button
          if (!this.isRegisteredForShift()) return true;
        }
        return true;
      } else {
        //If shift is full and the user is not assigned to this shift, dont show button to participate
        //Note: This seems weired, but the behaviour of the button changes due to the participation state. The button shall be shown to allow to unregister.
        if (
          this.data?.participants?.length >= this.data?.minParticipants &&
          !this.participatesThisShift
        ) {
          return false;
        }

        //running: don't show (admin has to handle abortion or registration of user) or if shift has ended, hide button
        if (this.shiftHasEnded()) return false;
        return true;
      }
    }
    return false;
  }

  //Show a button to scan a qr code, so that a user can set himself as present at th shift
  public showPresenceRegisterButton(): boolean {
    //only these are allowed to participate
    if (
      this.viewMode == EventViewMode.Participant ||
      this.viewMode == EventViewMode.Reader
    ) {
      //if user is shift organizer, hide button
      if (this.data.responsiblePersonUid == this.userData.uid) return false;
      if (this.shiftHasEnded()) return false;

      //if it is an construction or dismantling shift
      if (
        this.isConstructionOrDismantling() &&
        this.isShiftRunning() &&
        this.isRegisteredForShift()
      )
        return true;
    }
    return false;
  }

  /**
   * Checks if the current user is an events admin.
   * @returns {boolean} Returns true if the user is an events admin, false otherwise.
   */
  public onlyEventsAdmin(): boolean {
    return this.authService.userIsEventsAdmin();
  }

  //Shows the qr code dialog for construction and dismantling registration
  private showQrCode(trigger: QrCodeTrigger) {
    //open dialog
    var data: QrCodeDialogData = {
      title: this.data.type,
      decisionYes: true,
      qrCodeLink: this.data.path,
      qrCodeTrigger: trigger,
    };
    this.dialog
      .open(QrCodeDialog, {
        width: '500px',
        height: 'auto',
        data: data,
        autoFocus: true,
        disableClose: false,
      })
      .afterClosed();
  }

  public showQrCodeForPresenceMarker() {
    this.showQrCode(QrCodeTrigger.CONSTR_DEMANTL_USER_PRESENT);
  }

  //Shows a dialog to scan a qr code
  public showCamScanDialog(qrCodeTrigger: QrCodeTrigger) {
    var data: CamScanDialogData = {
      title: 'Bitte scanne den QR-Code',
      decisionYes: true,
      qrCodeTrigger: qrCodeTrigger,
    };

    //open dialog
    return new Promise<CamScanDialogData>((resolve, reject) => {
      this.dialog
        .open(CamScanDialog, {
          width: '500px',
          height: 'auto',
          data: data,
          autoFocus: true,
          disableClose: false,
        })
        .afterClosed()
        .subscribe((data: QrCodeDialogData) => {
          resolve(data);
        });
    });
  }

  //Controls the visibility of specific ui elements depending on th view mode
  public isVisible(index: VisibilityIndex): boolean {
    switch (index) {
      case VisibilityIndex.PanelDesc1:
        return this.viewMode == EventViewMode.Admin || this.isShiftOrganizer();
      case VisibilityIndex.PanelDesc2:
        return this.viewMode == EventViewMode.SimplifiedAdmin;
      case VisibilityIndex.PanelDesc3:
        return (
          this.viewMode == EventViewMode.Participant ||
          this.viewMode == EventViewMode.Reader
        );
      case VisibilityIndex.FullInfoTable:
        return this.viewMode != EventViewMode.Participant;
      case VisibilityIndex.SmallInfoTable:
        return this.viewMode == EventViewMode.Participant;
      case VisibilityIndex.ParticipantsComponents:
        return (
          this.viewMode != EventViewMode.Participant &&
          this.viewMode != EventViewMode.Reader
        );
      case VisibilityIndex.ParticipantJoin:
        return this.showParticipateButton();
      case VisibilityIndex.ContrDismPresenceScanBtn:
        return this.showPresenceRegisterButton();
      case VisibilityIndex.ParticipantJoinHints:
        if (
          this.viewMode == EventViewMode.Participant ||
          this.viewMode == EventViewMode.Reader
        ) {
          if (this.isConstructionOrDismantling()) {
            return true; //hints will decide by themself if to show (in html)
          } else {
            if (this.isShiftRunning()) return false;
            if (this.shiftHasEnded()) return false;
            return true;
          }
        }
        return false;
      case VisibilityIndex.AddButton:
        return (
          this.viewMode == this.enumViewMode.Admin &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case VisibilityIndex.DeleteButton:
        return (
          this.viewMode == this.enumViewMode.Admin && this.onlyEventsAdmin()
        );
      case VisibilityIndex.EditButton:
        return (
          this.viewMode == this.enumViewMode.Admin &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case VisibilityIndex.ParticipantsCount:
        return this.viewMode != this.enumViewMode.Reader;
      case VisibilityIndex.CallShiftOrganizerButton:
        return this.viewMode == this.enumViewMode.Reader;
      case VisibilityIndex.FreigabeTooggle:
        return (
          this.viewMode == this.enumViewMode.Admin &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case VisibilityIndex.ConstructionDismantlingTableRows:
        return this.data.kind == WorkPackageKind.Standard;
      case VisibilityIndex.Ue18Text:
        return this.data?.isUe18 != undefined && this.data?.isUe18;
      case VisibilityIndex.QrCodeFooter:
        return (
          this.viewMode == this.enumViewMode.Admin &&
          (this.onlyEventsAdmin() || this.isShiftOrganizer())
        );
      case VisibilityIndex.QrCodeButton:
        return (
          this.viewMode == this.enumViewMode.SimplifiedAdmin 
        );
      case VisibilityIndex.ButtonStartConstrDismant:
        if (
          ((this.viewMode == this.enumViewMode.Admin ||
            this.viewMode == this.enumViewMode.SimplifiedAdmin) &&
            this.onlyEventsAdmin()) ||
          this.isShiftOrganizer()
        ) {
          if (this.isConstructionOrDismantling()) {
            return true;
          }
        }
        return false;
      default:
        return false;
    }
  }
}
