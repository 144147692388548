<div class="text">
  <p>Gib nun die Anzahl der Punkte ein, die du einlösen möchest.</p>
</div>

<div style="text-align: center">
  <form [formGroup]="myForm" *ngIf="!qrCodeGenerated">
    <!--Amount-->
    <div style="width: 100%; text-align: center">
      <mat-form-field class="form-fields">
        <mat-label
          >Anzahl Punkte (max:
          {{ totalUnlockedEncouragementPoints }})</mat-label
        >
        <input
          matInput
          type="number"
          placeholder="Anzahl Punkte"
          [formControl]="amountFormControl"
          min="1"
        />
        <mat-error *ngIf="amountFormControl.invalid"
          >Es können nur {{ totalUnlockedEncouragementPoints }} Punkte entnommen
          werden</mat-error
        >
      </mat-form-field>
    </div>

    <!--Comment-->
    <div style="width: 100%">
      <mat-form-field class="form-fields">
        <mat-label>Kommentar</mat-label>
        <input matInput [formControl]="commentFormControl" />
      </mat-form-field>
    </div>

    <button
      class="button"
      type="submit"
      style="margin-top: 20px"
      [disabled]="
        !myForm.valid ||
        amountFormControl.value > totalUnlockedEncouragementPoints
      "
      (click)="generateQrCode()"
      [ngStyle]="{
        'background-color':
          !myForm.valid ||
          amountFormControl.value > totalUnlockedEncouragementPoints
            ? '#d8d8d8'
            : ''
      }"
    >
      Einlösen
    </button>
  </form>
</div>

<!--For testing-->
<!-- <button
class="button"
style="width: 100%; max-width: 300px"
(click)="test()"
>
Test
</button> -->

<!--Source: https://www.npmjs.com/package/angular2-qrcode-->
<div style="text-align: center; padding-top: 20px" *ngIf="qrCodeGenerated">
  <qr-code [value]="qrCodeSource" [size]="300"></qr-code>

  <div style="text-align: center; padding-top: 20px">
    <button
      class="button"
      type="submit"
      style="width: 100%; max-width: 300px"
      (click)="qrCodeGenerated = false"
    >
      Zurück
    </button>
  </div>
</div>

<button
  *ngIf="userSpecificFinishedTransfers.length > 0"
  mat-fab
  class="history-btn"
  color="primary"
  aria-label="Punkte-Historie"
  (click)="showPointsHistory()"
>
  <mat-icon class="history-icon">history</mat-icon>
</button>
