import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import { DataModelService } from 'src/app/services/data-model.service';
import { AddEventDialogData, User } from 'src/app/services/definitions.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.css'],
})
export class AddEventComponent implements OnInit {
  title: string = 'Event hinzufügen';
  buttonOk: string = 'Erstellen';
  modifying: boolean = false;
  userSelectorFormControl = new FormControl('');
  nameFormControl = new FormControl('', [Validators.required]);
  descriptionFormControl = new FormControl('');
  houseNumberFormControl = new FormControl('', [Validators.required]);
  streetFormControl = new FormControl('', [Validators.required]);
  locationNameFormControl = new FormControl('', [Validators.required]);
  cityFormControl = new FormControl('', [Validators.required]);
  plzNumberFormControl = new FormControl(71643, [Validators.required]);
  startDateFormControl = new FormControl();
  endDateFormControl = new FormControl();

  myForm: FormGroup;
  minDate: Date;
  maxDate: Date;
  startDate: Date;
  userData: User;
  dataLoaded: boolean;
  allUsersData: User[];
  options: User[];
  removePointsActive: boolean = false;
  filteredNameOptions: Observable<User[]>;

  constructor(
    public dialogRef: MatDialogRef<AddEventDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: AddEventDialogData,
    private commonFunctions: CommonFunctionsService,
    private dataModel: DataModelService,
    fb: FormBuilder
  ) {
    this.allUsersData = data.allUserData;
    this.options = data.userOptions;
    this.filteredNameOptions = this.userSelectorFormControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );

    this.myForm = fb.group({
      name: this.nameFormControl,
      description: this.descriptionFormControl,
      houseNumber: this.houseNumberFormControl,
      street: this.streetFormControl,
      locationName: this.locationNameFormControl,
      city: this.cityFormControl,
      plz: this.plzNumberFormControl,
      start: this.startDateFormControl,
      end: this.endDateFormControl,
      responsiblePerson: this.userSelectorFormControl,
    });

    //if data is assigned, then set to form controls
    this.initModification(data);

    // Set the minimum to now and the maximum to two years in the future
    const currentYear = new Date().getFullYear();
    this.startDate = new Date(Date.now());
    this.minDate = this.startDate;
    this.maxDate = new Date(currentYear, 12, 31);
  }

  ngOnInit(): void {}

  //Init the event modification
  private async initModification(data: AddEventDialogData) {
    if (data.id) {
      this.title = 'Event ändern';

      //load user by id and set as value
      if (data.responsiblePersonId) {
        var foundUser = this.commonFunctions.findUser(
          data.allUserData,
          data.responsiblePersonId
        );
        if (foundUser != undefined) {
          this.myForm.controls['responsiblePerson'].setValue(foundUser);
        }
      }
      
      this.myForm.controls['name'].setValue(data.name);
      this.myForm.controls['description'].setValue(data.description);
      this.myForm.controls['street'].setValue(data.street);
      this.myForm.controls['locationName'].setValue(data.locationName);
      this.myForm.controls['houseNumber'].setValue(data.houseNumber);
      this.myForm.controls['plz'].setValue(data.plz);
      this.myForm.controls['city'].setValue(data.city);
      this.myForm.controls['start'].setValue(
        new Date(data.start.seconds * 1000)
      );
      this.myForm.controls['end'].setValue(new Date(data.end.seconds * 1000));
      this.buttonOk = 'Ändern';
      this.modifying = true;
    }
  }

  //Adds or modifies a event
  public async setEvent() {
    var name = this.myForm.get('name').value;
    var description = this.myForm.get('description').value;
    var street = this.myForm.get('street').value;
    var locationName = this.myForm.get('locationName').value;
    var respPersonUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.myForm.get('responsiblePerson').value
    );
    var respPersonUserId = this.myForm.get('responsiblePerson').value.uid;
    if (!respPersonUserId) {
      respPersonUserId = '';
    }
    var houseNumber = this.myForm.get('houseNumber').value;
    var plz = this.myForm.get('plz').value;
    var city = this.myForm.get('city').value;
    var start: Date = this.myForm.get('start').value;
    var end: Date = this.myForm.get('end').value;

    //set end date to the end of the day
    end.setHours(23, 59, 59);
    var eventId = '';
    var update = false;

    if (this.modifying) {
      eventId = this.data.id;
      update = true;
    }
    var event = await this.dataModel.setEvent(
      this.data.idAccountingYear,
      name,
      description,
      locationName,
      houseNumber,
      street,
      city,
      plz,
      respPersonUserName,
      respPersonUserId,
      start,
      end,
      eventId,
      update
    );

    if (event.result) {
      var msg = 'Event erfolgreich hinzugefügt';
      if (this.modifying) {
        msg = 'Event erfolgreich angepasst';
      }
      this.commonFunctions.showSuccessToast(msg, 1500);
    } else {
      var msg = 'Event konnte nicht hinzugefügt werden';
      if (this.modifying) {
        msg = 'Event konnte nicht angepasst werden';
      }
      this.commonFunctions.showErrorToast(msg);
    }
    this.data.result = 1;
    this.dialogRef.close(this.data);
  }

  //Filter input by name
  private _filter(value: string): User[] {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();

      return this.options.filter((option: User) =>
        this.displayFn(option).toLowerCase().includes(filterValue)
      );
    }
  }

  displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }
}
