<h1 mat-dialog-title style="text-align: center">{{title}}</h1>
<hr style="opacity: 0.2" />
<!-- <div style="text-align: center; font-size: 0.8em; margin-bottom: 30px">
  <p>Den Zugangscode hast du per E-Mail erhalten.</p>
</div> -->
<div mat-dialog-content>
  <!--https://www.npmjs.com/package/angular-code-input-->
  <div style="margin-bottom: 30px">
    <code-input
      #codeInput
      [isCodeHidden]="false"
      [codeLength]="codeLength"
      [initialFocusField]="0"
      (codeChanged)="onCodeChanged($event)"
      (codeCompleted)="onCodeCompleted($event)"
    >
    </code-input>
  </div>
  <button
    class="button"
    (click)="onNoClick()"
    [ngStyle]="{ 'background-color': false ? '#d8d8d8' : '' }"
  >
    Schliessen
  </button>
</div>
