<h1 mat-dialog-title>{{ data.title }}</h1>
<hr style="opacity: 0.2" />
<div mat-dialog-content>
  <p>{{ data.contentText }}</p>
</div>
<hr style="opacity: 0.2" />
<div mat-dialog-actions class="dialog-btn-container">
  <button
    mat-raised-button
    type="reset"
    class="dialog-btn"
    (click)="onNoClick()"
    *ngIf="data.buttonNoName != ''"
  >
    {{ data.buttonNoName }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="dialog-btn"
    (click)="onYesClick()"
    cdkFocusInitial
  >
    {{ data.buttonYesName }}
  </button>
</div>
