import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ThreadSafeService {
  private variableSubject = new BehaviorSubject<ComponentState>(
    new ComponentState('default', [], false)
  );
  variable$ = this.variableSubject.asObservable();

  getVariable(): ComponentState {
    // console.log(`(TSS ${this.variableSubject.getValue().componentName}) getVariable`);
    return this.variableSubject.getValue();
  }

  setSubComponentsIdsList(
    subComponentIds: string[],
    id: string = undefined
  ): void {
    // console.log(
    //   `(TSS ${
    //     this.variableSubject.getValue().componentName
    //   }) setSubComponentsIdsList: ${subComponentIds}`
    // );

    var current = this.getVariable();
    current.subComponentIdsList = subComponentIds;
    current.initialized = true;
    this.variableSubject.next(current);
  }

  setSubComponentLoaded(subComponentId: string): void {
    // console.log(
    //   `(TSS ${this.variableSubject.getValue().componentName}) setSubComponentLoaded: ${subComponentId}`
    // );
    var current = this.getVariable();
    current.markSubComponetLoaded(subComponentId);
    this.variableSubject.next(current);
  }

  setComponentDataLoaded(): void {
    // console.log(
    //   `(TSS ${this.variableSubject.getValue().componentName}) setComponentDataLoaded`
    // );
    var current = this.getVariable();
    current.setComponentDataLoaded(true);
    this.variableSubject.next(current);
  }

  setName(name: string) {
    var current = this.getVariable();
    current.componentName = name;
    this.variableSubject.next(current);
  }

  getId(): string {
    return this.getVariable().id;
  }

  setId(id: string): void {
    var current = this.getVariable();
    current.id = id;
    this.variableSubject.next(current);
  }
}

export class ComponentState {
  public initialized: boolean = false;

  constructor(
    public componentName: string,
    public subComponentIdsList: string[],
    public componentDataLoaded: boolean,
    public id: string = undefined
  ) {
    if (id == undefined) {
      this.id = this.generateGUID();
    } else {
      this.id = id;
    }
  }

  markSubComponetLoaded(subComponentId: string): void {
    this.subComponentIdsList = this.subComponentIdsList.filter(
      (item) => item !== subComponentId
    );
  }

  allSubComponentsLoaded(): boolean {
    return this.initialized && this.subComponentIdsList.length == 0;
  }

  setComponentDataLoaded(value: boolean): void {
    this.componentDataLoaded = value;
  }

  doneLoading(): boolean {
    return this.componentDataLoaded && this.allSubComponentsLoaded();
  }

  private generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
