import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CodeInputComponent } from 'angular-code-input';

export interface OneTimeAccessCodeDialogData {
  result: string;
  userEmail: string;
  title: string;
  codeLength: number;
}

@Component({
  selector: 'one-time-access-code',
  templateUrl: 'one-time-access-code.html',
  styleUrls: ['one-time-access-code.css'],
})
export class OneTimeAccessCodeDialog {
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  public title: string = '';
  public codeLength : number = 4;

  constructor(
    public dialogRef: MatDialogRef<OneTimeAccessCodeDialog>,
    @Inject(MAT_DIALOG_DATA) public data: OneTimeAccessCodeDialogData
  ) {
    this.title = data.title;
    this.codeLength = data.codeLength;
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {}

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.data.result = code;
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.data.result = undefined;
    this.dialogRef.close(this.data);
  }
}
