import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import { EncouragementPointEntryAction } from '../services/data-model.service';
import firebase from 'firebase/app';
import 'firebase/firestore';

export class BottomSheetData {
  public payload: any;
  public type: ViewDataType;

  constructor(data: any, type: ViewDataType) {
    this.payload = data;
    this.type = type;
  }
}

export enum ViewDataType {
  ENCOURAGEMENT_POINTS,
  WORK_HOURS,
  POINT_TRANSFER_SENDER,
  POINT_TRANSFER_RECEIVER,
}

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.css'],
})
export class BottomSheetComponent implements OnInit {
  public maxEntries: number = 8;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    commonFunctions: CommonFunctionsService
  ) {
    if (
      !data ||
      !data.payload ||
      !Array.isArray(data?.payload) ||
      data.payload.length == 0
    ) {
      let msg = 'Keine Daten vorhanden';
      commonFunctions.showToastMessage(DialogType.INFO, msg);
      this.bottomSheetRef.dismiss();
    }
  }

  ngOnInit(): void {}

  openLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  isViewTypeOther(): boolean {
    return (
      this.data?.type == ViewDataType.ENCOURAGEMENT_POINTS ||
      this.data?.type == ViewDataType.WORK_HOURS
    );
  }

  isViewTypePointTransferSender(): boolean {
    return this.data?.type == ViewDataType.POINT_TRANSFER_SENDER;
  }

  isViewTypePointTransferReceiver(): boolean {
    return this.data?.type == ViewDataType.POINT_TRANSFER_RECEIVER;
  }

  isViewTypePointTransfer(): boolean {
    return (
      this.data?.type == ViewDataType.POINT_TRANSFER_RECEIVER ||
      this.data?.type == ViewDataType.POINT_TRANSFER_SENDER
    );
  }

  isAdd(action: EncouragementPointEntryAction) {
    return action == EncouragementPointEntryAction.ADD;
  }

  showLockedIcon(dataEntry) {
    if (dataEntry && dataEntry.hasOwnProperty('unlockedAt')) {
      var currentDate = firebase.firestore.Timestamp.fromDate(new Date());
      var unlockedAt = dataEntry.unlockedAt;
      if (currentDate <= unlockedAt) {
        return true;
      }
    }
    return false;
  }
}
