import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CommonFunctionsService,
  DialogType,
} from 'src/app/services/common-functions.service';
import { CamScanDialogData } from 'src/app/services/definitions.service';

@Component({
  selector: 'app-cam-scan-dialog',
  templateUrl: './cam-scan-dialog.component.html',
  styleUrls: ['./cam-scan-dialog.component.css'],
})
export class CamScanDialog implements OnInit {
  public scannerEnabled: boolean = true;
  public camInitialized: boolean = false;
  public showAskPermissionButton: boolean = false;
  public foundCameras: any[];
  private currentCamIx: number = 0;
  private readonly KEY_CAM_IX: string = 'last_camera_ix';
  private scanAllowed: boolean = true;
  public title: string = '';

  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;

  constructor(
    private commonFunctions: CommonFunctionsService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<CamScanDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: CamScanDialogData
  ) {
    this.scannerEnabled = true;
    this.title = data.title;
    this.spinner.show();
  }

  onNoClick(): void {
    this.data.decisionYes = false;
    this.dialogRef.close(this.data);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.scannerEnabled = false;
    this.scanner?.ngOnDestroy();
  }

  ngAfterViewInit() {
    this.scanner.reset();
    this.scanner.restart();
    this.askForPermission();
  }

  public scanSuccess(result: string) {}

  public scanFailure(event) {}

  public scanError(err: any) {}

  public scanComplete(val: any) {
    if (val && this.scanAllowed) {
      this.scanAllowed = false;
      var data = val.text;
      if (data) {
        var splitted = data.split(';');
        //check consistency
        if (splitted.length == 2) {
          var trigger = splitted[0];
          var payload = splitted[1];

          if (trigger != this.data.qrCodeTrigger) {
            this.commonFunctions.showErrorToast('Unbekannter QR Code');
            this.scanThreshold();
            return;
          }

          this.data.decisionYes = true;
          this.data.payload = payload;
          this.dialogRef.close(this.data);
        }
      } else {
        this.commonFunctions.showErrorToast('Ungültiger QR-Code');
      }
    }
  }

  // asks for the permission to use the camera
  public async askForPermission() {
    var failed: boolean = false;
    var error = '';
    //Ask for permission
    await navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        /* use the stream */
      })
      .catch(function (err) {
        /* handle the error */
        failed = true;
        error = err;
      });
    if (failed) {
      this.commonFunctions.showErrorToast(
        'Keine Berechtigung zur Nutzung der Kamera'
      );
    }
  }

  //handles the click on 'Kein Kamerabild sichtbar?' icon-button
  public showInfoDialog() {
    this.commonFunctions.openDialog(
      'Info',
      'Versuche die Kamera zu wechseln. Ein reload der Seite kann in manchen Fällen auch helfen. Solltest du ein IPhone besitzen, so funktioniert die Kamera erst ab der iOS Version 14.3.',
      DialogType.INFO
    );
  }

  //Handle the event which includes all available cameras
  public camerasFoundHandler(event) {
    this.foundCameras = event;
    this.handleCamInit();
  }

  //Handles the initiating phase of the camera
  private async handleCamInit() {
    if (!this.camInitialized) {
      this.camInitialized = true;
      var lastIx = localStorage.getItem(this.KEY_CAM_IX);
      if (lastIx) {
        var ixNum = parseInt(lastIx);

        this.spinner.hide();
        await this.delay(1000);
        // this.commonFunctions.showSuccessToast(
        //   'Restoring cam settings to: ' + this.foundCameras[ixNum].label
        // );
        this.scanner.reset();
        this.scanner.device = this.foundCameras[ixNum];
      } else {
        this.spinner.hide();
      }
    }
  }

  //Wait a specific delay
  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  //Handle the event when no camera is available
  public camerasNotFoundHandler(event) {
    this.commonFunctions.showErrorToast('Keine Kamera vorhanden');
    this.spinner.hide();
  }

  //handles camera device change event
  public deviceHasChanged(event) {
    // console.log('Device has changed: ' + event?.label);
  }

  //changes the camera device
  public changeCamera() {
    if (this.foundCameras) {
      var ix = this.currentCamIx + 1;
      if (ix > this.foundCameras.length - 1) {
        ix = 0;
      }
      this.currentCamIx = ix;
      this.scanner.reset();
      this.scanner.device = this.foundCameras[ix];
      //save last selection
      localStorage.setItem(this.KEY_CAM_IX, ix.toString());
    }
  }

  //Disables a the scan to suppress multiple db entry creation
  private scanThreshold() {
    setTimeout(() => {
      this.scanAllowed = true;
    }, 3000);
  }
}
