import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-available',
  templateUrl: './update-available.component.html',
  styleUrls: ['./update-available.component.css'],
})
export class UpdateAvailableComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onYesClick() {
    this.data.result = 1;
    this.dialogRef.close(this.data);
  }

  onNoClick() {
    this.data.result = undefined;
    this.dialogRef.close(this.data);
  }
}
