import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { CommonFunctionsService } from '../services/common-functions.service';
import { DataModelService } from '../services/data-model.service';
import { SiteURL, User } from '../services/definitions.service';
import { LogService } from '../services/log.service';

@Component({
  selector: 'app-id-card',
  templateUrl: './id-card.component.html',
  styleUrls: ['./id-card.component.css'],
})
export class IdCardComponent implements OnInit {
  @ViewChild('fileInput') el: ElementRef;
  avatarImageUrl: string = '../../../../../assets/images/avatar.png';
  imageUrl: any = this.avatarImageUrl;
  editFile: boolean = true;
  removeUpload: boolean = false;
  submitted: boolean;
  customImageSet: boolean = true;

  public userData: User;
  form = new FormGroup({});
  entryDateFormControl = new FormControl('');
  leavingDateFormControl = new FormControl('');
  membershipNumberFormControl = new FormControl('');
  stateFormControl = new FormControl('');
  typeFormControl = new FormControl('');
  firstNameFormControl = new FormControl('');
  lastNameFormControl = new FormControl('');
  birthdayFormControl = new FormControl('');
  showHoverImage: boolean = false;

  constructor(
    public fb: FormBuilder,
    private cd: ChangeDetectorRef,
    public commonFunctions: CommonFunctionsService,
    public http: HttpClient,
    private modelService: DataModelService,
    private authService: AuthService,
    private log: LogService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      entryDate: this.entryDateFormControl,
      leavingDate: this.leavingDateFormControl,
      membershipNumber: this.membershipNumberFormControl,
      state: this.stateFormControl,
      type: this.typeFormControl,
      firstName: this.firstNameFormControl,
      lastName: this.lastNameFormControl,
      birthday: this.birthdayFormControl,
    });
    this.spinner.show();
    this.commonFunctions.registerEventEmitterHandlerUserData((userData: User) => {
      this.userData = userData;
      this.fillData(this.userData);
      this.spinner.hide();
    });
  }

  //Called when uploading a file by clicking on the image was triggered
  public uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;

        //check image
        var imageOk = this.checkImage(file.size, file.type);
        if (!imageOk) {
          return;
        }

        this.editFile = false;
        this.removeUpload = true;

        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('tz', new Date().toISOString());
        var file_data = formData;

        this.spinner.show();

        // return;
        this.http
          .post(SiteURL + '/backend/upload-image.php', file_data)
          .subscribe(
            (res) => {
              if (res) {
                if (res['path']) {
                  //altes image vom server loeschen
                  this.removeOldFileFromServer();

                  //filepfad in userdaten speichern
                  this.modelService.updateUser(
                    this.authService.getCurrentUserId(),
                    {
                      'personalData.profilePic': res['path'],
                    }
                  );
                  this.commonFunctions.showSuccessToast(
                    'Profilbild erfolgreich hochgeladen'
                  );
                } else {
                  this.log.error(
                    'Pfad des upload image nicht vorhanden',
                    IdCardComponent.name
                  );
                  this.commonFunctions.showErrorToast(
                    'Profilbild konnte nicht in der Datenbank gespeichert werden'
                  );
                }
              } else {
                let msg =
                  'Profilbild konnte nicht hochgeladen werden. Fehler: ' +
                  res['message'];
                this.commonFunctions.showErrorToast(msg);
                this.log.error(msg, IdCardComponent.name);
              }
              this.spinner.hide();
            },
            (err) => {
              //send error response
              this.log.error(
                'Upload des Profilbild fehlgeschlagen',
                err,
                IdCardComponent.name
              );
              this.commonFunctions.showErrorToast(
                'Profilbild konnte nicht hochgeladen werden. Prüfe deine Internetverbindung'
              );
              this.spinner.hide();
            }
          );
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  //Checks the uploaded image
  private checkImage(filesize: any, filetype: any): boolean {
    //check filetype
    switch (filetype) {
      case 'image/jpg':
      case 'image/jpeg':
      case 'image/png':
        break;
      default:
        this.commonFunctions.showErrorToast(
          'Bitte nur jpg | jpeg | png benutzen',
          5000
        );
        return false;
    }

    //check filesize
    let inMB = filesize / 1024 / 1024;
    if (inMB >= 20) {
      this.commonFunctions.showErrorToast(
        'Bild darf max. 20 mb groß sein',
        5000
      );
      return false;
    }
    return true;
  }

  //Fills the user data into the forms
  private fillData(userData: any) {
    this.entryDateFormControl.setValue(
      this.commonFunctions.unixTimeToDate(
        this.commonFunctions.extractData(userData, 'membership.entryDate')
          .seconds
      )
    );
    this.leavingDateFormControl.setValue(
      this.commonFunctions.unixTimeToDate(
        this.commonFunctions.extractData(userData, 'membership.leavingDate')
          .seconds
      )
    );
    this.membershipNumberFormControl.setValue(
      this.commonFunctions.extractData(userData, 'membership.number')
    );
    this.stateFormControl.setValue(
      this.commonFunctions.extractData(userData, 'membership.state')
    );
    this.typeFormControl.setValue(
      this.commonFunctions.extractData(userData, 'membership.type')
    );

    this.birthdayFormControl.setValue(
      this.commonFunctions.unixTimeToDate(
        this.commonFunctions.extractData(userData, 'personalData.birthDate')
          .seconds
      )
    );
    this.firstNameFormControl.setValue(
      this.commonFunctions.extractData(userData, 'personalData.firstName')
    );
    this.lastNameFormControl.setValue(
      this.commonFunctions.extractData(userData, 'personalData.lastName')
    );
    //profile pic
    // var url = this.commonFunctions.extractData(
    //   userData,
    //   'personalData.profilePic'
    // );
    // if (url) this.imageUrl = SiteURL + '/' + url;

    var url = this.buildProfilePicUrl(userData);
    if (url) this.imageUrl = url;
  }

  private buildProfilePicUrl(userData: User) {
    var url = this.commonFunctions.extractData(
      userData,
      'personalData.profilePic'
    );
    if (url) return SiteURL + '/' + url;
    return '';
  }

  private getOldProfilePic(userData: User) {
    var url = this.commonFunctions.extractData(
      userData,
      'personalData.profilePic'
    );
    if (url) return url;
    return '';
  }

  //delete old profile pic from server
  private removeOldFileFromServer() {
    //alten image pfad sichern
    var oldImageUrl: string = this.getOldProfilePic(this.userData);

    console.log('Old path: ' + oldImageUrl);

    //check image url
    if (
      oldImageUrl.endsWith('.jpg') ||
      oldImageUrl.endsWith('.jpeg') ||
      oldImageUrl.endsWith('.png')
    ) {
      let formData = new FormData();
      formData.append('filepath', oldImageUrl);
      var file_data = formData;

      // return;
      this.http
        .post(SiteURL + '/backend/delete-image.php', file_data)
        .subscribe(
          (res) => {
            this.log.debug(
              'Old profile pic successfully removed from server',
              oldImageUrl
            );
          },
          (err) => {
            this.log.error(
              'Old profile pic could not be removed from server',
              err,
              oldImageUrl,
              IdCardComponent.name
            );
          }
        );
    } else {
      this.log.error('Image url not correct: ' + oldImageUrl);
    }
  }

  onHover() {
    this.showHoverImage = true;
  }

  onLeave() {
    this.showHoverImage = false;
  }

  setOpacityOfImage() {
    return this.showHoverImage ? 1 : 0;
  }

  onClick() {
    this.showHoverImage = false;
  }

  // Function to remove uploaded file
  // removeUploadedFile() {
  //   let newFileList = Array.from(this.el.nativeElement.files);
  //   this.imageUrl = this.avatarImageUrl;
  //   this.editFile = true;
  //   this.removeUpload = false;
  // }
}
