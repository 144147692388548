import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { CommonFunctionsService } from './common-functions.service';
import { LogService } from './log.service';
import { AuthService } from './auth.service';
import {
  NotificationType,
  PushNotification,
  PushNotificationContainer,
  SilentActions,
} from './definitions.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject<PushNotificationContainer>(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private commonFunctions: CommonFunctionsService,
    private log: LogService,
    private authenticationService: AuthService,
    public router: Router
  ) {}

  //Starts asking if user wants to receive push notification
  public requestPermission(force: boolean = false) {
    if (this.authenticationService.isLoggedIn()) {
      this.commonFunctions.requestNotificationPermission(
        this.authenticationService.userDetails.uid,
        force
      );
    } else {
      this.commonFunctions.showErrorToast('User nicht eingeloggt');
    }
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: PushNotificationContainer) => {
        console.log('Message received. ', payload);
        this.currentMessage.next(payload);
        this.displayNotification(payload);
      }
    );
  }

  //Shows a push notification
  private displayNotification(notification: PushNotificationContainer) {
    if (!notification) {
      return;
    }
    var data: PushNotification = notification.notification;

    //silent action
    if (data.messageType == NotificationType.SilentAction) {
      this.handleSilentActions();
      return;
    }

    //Standard
    if (data.messageType == NotificationType.Standard) {
      this.commonFunctions.openDialog(data.messageTitle, data.messageBody);
    }
    //message from admin
    else if (data.messageType == NotificationType.MessageFromAdmin) {
      this.commonFunctions.openDialog('Nachricht vom Admin', data.messageBody);
    }
    //general reminder
    else if (data.messageType == NotificationType.GeneralReminder) {
      this.commonFunctions.openDialog('Erinnerung', data.messageBody);
    }

    //route used?
    if (data.route) {
      this.router.navigate(['/' + data.route]);
    }
  }

  //Handles silent actions without any user noitification
  private handleSilentActions(){
    //handle silent actions
    if (this.currentMessage.value.notification.silentActionId == SilentActions.SendLogFilesToDev) {
      this.log.sendLogsToDev(this.authenticationService.getCurrentUserId()).then(
        (fulfilled) => {
          console.log('Logs erfolgreich versandt');
        },
        (rejectMsg) => {
          console.log('Logs nicht versandt: ' + rejectMsg);
        }
      );
    }
    if (this.currentMessage.value.notification.silentActionId == SilentActions.LogOut) {
      this.authenticationService.logout();
    }
    if (this.currentMessage.value.notification.silentActionId == SilentActions.ClearLogFiles) {
      this.log.clearLogs();
    }
  }
}
