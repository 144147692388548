import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CommonFunctionsService } from '../services/common-functions.service';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../services/auth.service';
import 'firebase/firestore';
import 'firebase/auth'; // for authentication
import { LogService } from '../services/log.service';
import { AES, enc } from 'crypto-js';
import {
  DefaultUserPW,
  ErrorCodes,
  IsTestSystemActive,
  IsAdminAccessing,
  Salt,
  ServiceUserEmail,
  ServiceUserPW,
  SetTestSystemActive,
  SiteURL,
  User,
} from '../services/definitions.service';
import { DataModelService } from '../services/data-model.service';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  OneTimeAccessCodeDialog,
  OneTimeAccessCodeDialogData,
} from '../dialogs/one-time-access-code/one-time-access-code';
import { Platform } from '@angular/cdk/platform';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PromptComponent } from '../prompt-component/prompt-component.component';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

enum LoginErrorCode {
  USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PWD = 'auth/wrong-password',
  NO_CONNECTION = 'auth/network-request-failed',
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private maintenanceModeOn = false;
  public hideLogin = false;
  public showInstallText = false;
  private maintenanceModeAccessAllowedUsers = [
    'ralf.schmidling@gmail.com',
    'ralf.schmidling@googlemail.com',
    'jessica.jupe@web.de',
  ];
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  loginForm = new FormGroup({
    email: this.emailFormControl,
  });
  matcher = new MyErrorStateMatcher();

  //Source: https://github.com/AnthonyNahas/ngx-auth-firebaseui

  constructor(
    public dialog: MatDialog,
    public angularFireAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    public formBuilder: FormBuilder,
    public router: Router,
    public commonFunctions: CommonFunctionsService,
    private authService: AuthService,
    private dataModel: DataModelService,
    private http: HttpClient,
    private log: LogService,
    private spinner: NgxSpinnerService,
    private platform: Platform,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn) {
      this.router.navigate(['/home']);
    }
    this.checkInstalledApp();
  }

  //start login procedure
  login() {
    if (this.authService.isLoginBlocked()) {
      this.commonFunctions.showErrorToast(this.authService.blockReason, 10000);
      return;
    }
    var userEmail: string = this.loginForm.get('email').value;

    //trim email an to lower
    userEmail = userEmail.toLowerCase().trim();

    //Check if access is restricted -----------------------------------------------
    if (this.maintenanceModeOn) {
      if (!this.maintenanceModeAccessAllowedUsers.includes(userEmail)) {
        this.commonFunctions.showErrorToast(
          'Es werden gerade Wartungsarbeiten durchgeführt'
        );
        return;
      }
    }
    //------------------------------------------------------------------------------

    this.spinner.show();
    this.passwordlessLogin(userEmail)
      .then((done) => {})
      .catch((err) => {
        this.commonFunctions.showErrorToast('Fehler beim Login');
        this.spinner.hide();
      })
      .finally(() => {
        this.spinner.hide();
      });
  }

  //initialize password-less login
  private async passwordlessLogin(userEmail: string): Promise<boolean> {
    //1. Sign in with service user to get access to the db -------------------------
    var loginOk: boolean = await this.loginWithServiceUser();
    if (!loginOk) return false;
    var shownErrorAlready = false;

    //2. check if user exists in db --------------------------------------------------
    var userData: any = await this.dataModel
      .loadUserByEmail(userEmail)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (typeof err === 'string') {
          this.commonFunctions.showErrorToast(err.toString());
        } else {
          this.log.error('Could not login', err);
          this.commonFunctions.showErrorToast('Login fehlgeschlagen');
        }
        shownErrorAlready = true;
      });
    if (userData == undefined) {
      if (!shownErrorAlready) {
        this.commonFunctions.showErrorToast('Login fehlgeschlagen');
      }
      await this.authService.logout();
      return false;
    }

    //2. generate code (access code + timestamp + random string) and encrypt it ---------------
    var ac = this.commonFunctions.makeRandomNumber(4);
    var dbUpdated = await this.handleOneTimeAccessCode(userData.uid, ac);

    //directly log-out because user has to enter one time code
    await this.authService.logout();

    if (!dbUpdated) return false;

    //3. Send email to user ---------------------------------------------------------------------
    await this.sendAccessCodeMail(ac, userEmail);
    return true;
  }

  //loads the encrypted one time access code from the db and checks against the entered one
  private async checkOneTimeAccessCode(
    enteredOneTimeAccessCode: number,
    userEmail: string
  ): Promise<boolean> {
    this.spinner.show();
    var encryptedCode: string = '';

    //sign in with service user to get access to db
    var loginOk: boolean = await this.loginWithServiceUser();
    if (!loginOk) return false;

    var userData = await this.loadUserByEmail(userEmail);
    if (userData == undefined) {
      this.log.error('Could not login with default credentials', userEmail);
      this.commonFunctions.showErrorToast('Login fehlgeschlagen');
      this.authService.logout();
      return false;
    }
    var userId = userData.uid;

    //check if random id is in user data
    if ('randomId' in userData) {
      encryptedCode = userData.randomId;
    } else {
      this.commonFunctions.showErrorToast(
        'Login fehlgeschlagen. Versuche es erneut.'
      );
      this.authService.logout();
      return false;
    }

    // Decrypt:
    const decrypted = AES.decrypt(encryptedCode, Salt);
    const decryptedText: string = decrypted.toString(enc.Utf8);

    //extract data and compare
    var splitted = decryptedText.split(':');
    //ensure length
    if (splitted.length != 3) {
      this.log.error(
        'Decrypted one time access code does not have the right length',
        splitted
      );
      this.commonFunctions.showErrorToast('Login fehlgeschlagen', 7000);
      this.authService.logout();
      return false;
    }

    var ts = +splitted[0];
    var accessCode = +splitted[1];
    var tsAsDate = new Date(+ts * 1000);
    var now = new Date();
    var diff = now.getTime() - tsAsDate.getTime();
    var maxDiffMs = 48 * 60 * 60 * 1000; //48 h
    //check if code has expired (48h) (Test mit: test@test.de)
    if (diff >= maxDiffMs) {
      this.commonFunctions.showErrorToast('Dein Zugangscode ist abgelaufen');
      this.authService.logout();
      return false;
    }

    //check if codes match
    if (accessCode != enteredOneTimeAccessCode) {
      this.commonFunctions.showErrorToast('Dein Zugangscode ist falsch');
      this.authService.logout();
      return false;
    }

    //login to fire auth
    userId = await this.loginByEmail(userEmail);
    if (userId == undefined) {
      this.log.error('Could not login with default credentials', userEmail);
      this.commonFunctions.showErrorToast('Login fehlgeschlagen');
      this.authService.logout();
      return false;
    }
    //successfully logged in
    this.router.navigate(['/home']);
    //push notification stuff
    this.commonFunctions.requestNotificationPermission(userId);
    return true;
  }

  //Login with service user
  private async loginWithServiceUser(): Promise<boolean> {
    return await this.angularFireAuth
      .signInWithEmailAndPassword(ServiceUserEmail, ServiceUserPW)
      .then((userCredential) => {
        //ignore email verified property (userCredential.user.emailVerified)
        if (userCredential) {
          return true;
        }
      })
      .catch((error) => {
        var code = error.code;
        var msg = 'Unbekannter Fehler';

        switch (code) {
          case LoginErrorCode.USER_NOT_FOUND.valueOf():
            msg = 'Interner Fehler: Service user nicht vorhanden.';
            break;
          case LoginErrorCode.WRONG_PWD.valueOf():
            msg = 'Passwort falsch';
            break;
          case LoginErrorCode.NO_CONNECTION.valueOf():
            msg = 'Keine Internetverbindung';
            break;
          default:
            break;
        }
        this.commonFunctions.showErrorToast(msg);
        return false;
      });
  }

  //Login by email
  private async loginByEmail(email: string): Promise<string> {
    return await this.angularFireAuth
      .signInWithEmailAndPassword(email, DefaultUserPW)
      .then((userCredential) => {
        if (userCredential) {
          return userCredential.user.uid;
        }
      })
      .catch((error) => {
        var code = error.code;
        var msg = 'Unbekannter Fehler';

        switch (code) {
          case LoginErrorCode.USER_NOT_FOUND.valueOf():
            msg = 'Interner Fehler: User nicht vorhanden.';
            break;
          case LoginErrorCode.WRONG_PWD.valueOf():
            msg = 'Passwort falsch';
            break;
          default:
            break;
        }
        this.commonFunctions.showErrorToast(msg);
        return undefined;
      });
  }

  //Creates the one time access code and saves it to the users db data
  private async handleOneTimeAccessCode(
    userId: string,
    accessCode: string
  ): Promise<boolean> {
    var dbUpdated: boolean = false;
    //timestamp
    var ts = Math.round(new Date().getTime() / 1000);
    //random
    var rndm = this.commonFunctions.makeRandomId(10);
    // final code
    var code = `${ts}:${accessCode}:${rndm}`;
    var encrypted = AES.encrypt(code, Salt).toString();
    //save to database
    await this.dataModel
      .updateUser(userId, { randomId: encrypted })
      .then((done) => {
        dbUpdated = done;
      })
      .catch((error) => {
        this.commonFunctions.showErrorToast(
          'Login fehlgeschlagen. Code: ' +
            ErrorCodes.LOGIN_HANDLE_ONE_TIME_CODE_DB_UPDATE
        );
      });
    return dbUpdated;
  }

  //Load the user data from db
  private async loadUserData(
    uid: string,
    forceFromProduction: boolean = false
  ): Promise<any> {
    return await this.dataModel.loadUser(uid, forceFromProduction, true, false, 500).then(
      (data) => {
        if (data) {
          return data;
        } else {
          this.commonFunctions.showErrorToast('User nicht in Datenbank');
        }
      },
      (rejectMsg) => {
        this.log.error(
          'Userdaten konnten nicht geladen werden.',
          rejectMsg,
          LoginComponent.name
        );
        return undefined;
      }
    );
  }

  //Load the user data from db 
  private async loadUserByEmail(email: string): Promise<any> {
    return await this.dataModel.loadUserByEmail(email).then(
      (data) => {
        if (data) {
          return data;
        } else {
          this.commonFunctions.showErrorToast('User nicht in Datenbank');
        }
      },
      (rejectMsg) => {
        this.log.error(
          'Userdaten konnten nicht geladen werden.',
          rejectMsg,
          LoginComponent.name
        );
        return undefined;
      }
    );
  }

  //Sends an email with the one time access code to the user
  private async sendAccessCodeMail(accessCode: string, userEmail: string) {
    let formData = new FormData();
    userEmail = userEmail.trim().toLowerCase();
    formData.append('receiver_mail', userEmail);
    formData.append('access_code', accessCode);
    var file_data = formData;

    return await this.http
      .post(SiteURL + '/backend/send-mail-access-code.php', file_data)
      .subscribe(
        (res) => {
          if (res && res == 1) {
            this.openAccessCodeEntryDialog(userEmail);
          } else {
            this.log.error(
              'Could not send email with access code to user: ' + userEmail,
              res
            );
            this.commonFunctions.showErrorToast(
              'E-Mail mit Zugangscode konnte nicht versandt werden'
            );
          }
          this.spinner.hide();
          return true;
        },
        (err) => {
          this.log.error(
            'Could not send email with access code to user: ' + userEmail
          );
          this.commonFunctions.showErrorToast(
            'E-Mail mit Zugangscode konnte nicht versandt werden'
          );
          this.spinner.hide();
          return false;
        }
      );
  }

  //shows a dialog where the user can enter the one time access code
  private openAccessCodeEntryDialog(userEmail: string) {
    var data: OneTimeAccessCodeDialogData = {
      result: '',
      userEmail: userEmail,
      title: 'Zugangscode eingeben',
      codeLength: 4,
    };
    this.dialog
      .open(OneTimeAccessCodeDialog, {
        width: '550px',
        height: 'auto',
        data: data,
        autoFocus: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((data: OneTimeAccessCodeDialogData) => {
        if (data?.result != undefined) {
          if (data.result != undefined && parseInt(data.result) != undefined) {
            this.checkOneTimeAccessCodeHelper(+data.result, data.userEmail);
          } else {
            this.commonFunctions.showErrorToast(
              'Der eingegebene Code ist falsch'
            );
            this.spinner.hide();
          }
        }
      });
  }

  //Helper to call checkOneTimeAccessCode() async
  private async checkOneTimeAccessCodeHelper(
    enteredOneTimeAccessCode: number,
    userEmail: string
  ) {
    await this.checkOneTimeAccessCode(enteredOneTimeAccessCode, userEmail);
    this.spinner.hide();
  }

  //shows the code entry dialog
  public showAccessCodeEntryDialog() {
    var userEmail: string = this.loginForm.get('email').value;
    this.openAccessCodeEntryDialog(userEmail);
  }

  //Check if app has been installed
  private checkInstalledApp() {
    const isInStandaloneMode =
      'standalone' in window.navigator && window.navigator['standalone'];

    if (!isInStandaloneMode) {
      if (this.platform.ANDROID) {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          this.hideLogin = true;
          event.preventDefault();
          this.openInstallBottomSheet('android', event);
        });
      }
      if (this.platform.IOS) {
        this.hideLogin = true;
        this.openInstallBottomSheet('ios');
      }
    }

    //if app is installed
    window.addEventListener('appinstalled', (evt) => {
      this.showInstallText = true;
    });
  }

  //Opens the bottom sheet to show the install description
  private async openInstallBottomSheet(
    mobileType: 'ios' | 'android',
    promptEvent: any = undefined
  ) {
    this.bottomSheet.open(PromptComponent, {
      data: { mobileType, promptEvent: promptEvent },
      disableClose: true,
    });
  }

  //Debug login with user 'ralf.schmidling@gmail.com'
  public async debugLogin() {
    var loginOk: boolean = await this.loginWithServiceUser();
    if (!loginOk) return false;

    // load encrypted code from db
    var userId = await this.loginByEmail('ralf.schmidling@gmail.com');
    if (userId == undefined) {
      this.log.error(
        'Could not login with default credentials',
        'ralf.schmidling@gmail.com'
      );
      this.commonFunctions.showErrorToast('Login fehlgeschlagen');
      this.authService.logout();
      return false;
    }

    //load all users data from db
    var userData: any = await this.loadUserData(userId);
    if (userData == undefined) {
      this.log.error('Could load user data from db: ' + userId);
      this.authService.logout();
      setTimeout(() => {
        this.commonFunctions.showErrorToast(
          'Konnte Userdaten nicht laden',
          10000
        );
      }, 1000);
      return false;
    }
    this.router.navigate(['/home']);
  }

  //Allows to Login with an other user into Production system
  public async debugLoginOtherUser() {
    var loginOk: boolean = await this.loginWithServiceUser();
    if (!loginOk) return false;
    SetTestSystemActive(false);

    var userEmail: string = this.loginForm.get('email').value;
    userEmail = userEmail.toLowerCase().trim();

    //load encrypted code from db
    var userId = await this.loginByEmail(userEmail);
    if (userId == undefined) {
      this.log.error('Could not login with default credentials', userEmail);
      this.commonFunctions.showErrorToast('Login fehlgeschlagen');
      this.authService.logout();
      return false;
    }

    //load all users data from db
    var userData: any = await this.loadUserData(userId, true);
    if (userData == undefined) {
      this.log.error('Could load user data from db');
      this.commonFunctions.showErrorToast('Konnte Userdaten nicht laden');
      this.authService.logout();
      return false;
    }
    this.router.navigate(['/home']);
  }

  public isTestingSystemActive() {
    return IsTestSystemActive();
  }

  public isAdminAccessing() {
    return IsAdminAccessing();
  }
}
