import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogType } from '../services/common-functions.service';

export interface UniversalDialogDialogData {
  result: number;
  title: string;
  contentText: string;
  buttonNoName: string;
  buttonYesName: string;
  type: DialogType;
}

@Component({
  selector: 'universal-dialog',
  templateUrl: 'universal-dialog.html',
  styleUrls: ['universal-dialog.css'],
})
export class UniversalDialog {
  constructor(
    public dialogRef: MatDialogRef<UniversalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: UniversalDialogDialogData
  ) {}

  onNoClick(): void {
    this.data.result = 0;
    this.dialogRef.close(this.data);
  }

  onYesClick(): void {
    this.data.result = 1;
    this.dialogRef.close(this.data);
  }
}
