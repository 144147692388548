<div style="text-align: center">
  <p style="font-size: xx-large; margin-bottom: 20px; margin-top: 50px;">
    Datenbank Synchronisation
  </p>
  <p style="font-size: large">
    Bitte lade hier den aktuellen Excel-Export der Mitgliederdatenbank hoch.
  </p>
</div>
<div class="container">
  <div class="center">
    <input
      type="file"
      accept=".csv"
      #fileInput
      (change)="onFileSelected($event)"
      hidden
    />
    <button
      mat-raised-button
      class="btn"
      color="primary"
      (click)="fileInput.click()"
    >
      Datei hochladen
    </button>
  </div>
</div>
