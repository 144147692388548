<div style="text-align: center; height: -webkit-fill-available">
  <!--Source: https://www.kirupa.com/animations/creating_pulsing_circle_animation.htm-->

  <div id="circle-container">
    <div class="circle-item">
      <span class="dot">
        <p class="circle-content blinking-text">
          {{ getUnlockedEncouragementPointsCount() }}
        </p>
      </span>
    </div>
    <div class="circle"></div>
    <!-- <div class="circle" style="animation-delay: 2.5s"></div> -->
  </div>

  <div *ngIf="getLockedEncouragementPointsCount() > 0">
    <div style="text-align: center; display: inline-flex">
      <button
        mat-icon-button
        (click)="showInfoDialog()"
        aria-label="Info wieso Punkte gesperrt sind"
      >
        <mat-icon>info</mat-icon>
      </button>
    </div>

    <div
      style="display: inline-flex; vertical-align: text-top; margin-top: 2px"
    >
      <p style="padding-left: 3px" (click)="showInfoDialog()">
        Gesperrte Punkte: <b>{{ getLockedEncouragementPointsCount() }}</b>
      </p>
    </div>
  </div>
</div>

<button
  *ngIf="usersEncouragementPointsData?.length > 0"
  mat-fab
  class="history-btn"
  color="primary"
  aria-label="Punkte-Historie"
  (click)="showPointsHistory()"
>
  <mat-icon class="history-icon">history</mat-icon>
</button>

<!--https://www.npmjs.com/package/ng-circle-progress-->
<!-- <circle-progress
    [titleColor]="'#545454'"
    [outerStrokeColor]="'#3936e2'"
    [innerStrokeColor]="'#e6e6e6'"
    [backgroundPadding]="50"
    [backgroundStrokeWidth]="0"
    [titleFontSize]="100"
    [animationDuration]="1000"
    [backgroundGradient]="true"
    [backgroundOpacity]="1"
    [outerStrokeWidth]="8"
    [innerStrokeWidth]="4"
    [space]="5"
    [percent]="getUnlockedEncouragementPointsCount()"
    [radius]="140"
    [showSubtitle]="false"
    [showUnits]="false"
    [showBackground]="false"
    [showBackground]="false"
    [responsive]="false"
  ></circle-progress> -->
