import { Pipe, PipeTransform } from '@angular/core';
import { EncouragementPointReason } from '../services/data-model.service';

@Pipe({
  name: 'encPointEntryReason',
})
export class EncPointEntryReasonPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case EncouragementPointReason.STANDARD:
        return 'Standard';
      case EncouragementPointReason.TRANSFER_FROM_WORK_HOUR_BY_YEAR_END:
        return 'Transeriert von Arbeitsstunden aus dem Vorjahr';
      case EncouragementPointReason.POINT_TRANSFER:
        return 'Punkte-Übertrag';
      default:
        return 'Unbekannt';
    }
  }
}
