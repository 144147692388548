import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunctionsService } from '../services/common-functions.service';
import { DataModelService } from '../services/data-model.service';
import {
  AccountingYearWorkHour,
  AccountingYear,
  ProtocolWorkHours,
  Users,
  Sub_WorkHours,
  UserDataTableView,
  WorkHour,
} from '../services/definitions.service';

@Component({
  selector: 'app-protocol-view',
  templateUrl: './protocol-view.component.html',
  styleUrls: ['./protocol-view.component.css'],
})
export class ProtocolViewComponent implements OnInit {
  @ViewChild(MatPaginator) paginatorTable: MatPaginator;
  @ViewChild(MatSort) sortTable: MatSort;
  dataSourceTable: MatTableDataSource<ProtocolWorkHours>;
  displayedColumnsTable: string[] = [
    'sourceUserName',
    'amount',
    'action',
    'actionComment',
    'actionDoneByName',
    'reason',
    'amountEvents',
    'ts',
  ];

  constructor(
    private dataModel: DataModelService,
    private commonFunctions: CommonFunctionsService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner.show();
    this.dataModel
      .loadAllCollectionsData(ProtocolWorkHours())
      .then(
        (data) => {
          //set data source - table 1
          this.dataSourceTable = new MatTableDataSource(data);
          this.dataSourceTable.paginator = this.paginatorTable;
          this.dataSourceTable.sort = this.sortTable;

          //start lazy loading of details
          // this.lazyLoadDetailData();
        },
        (rejected) => {
          //TODO:
        }
      )
      .catch((error) => {})
      .finally(() => {
        this.spinner.hide();
      });
    this.dataSourceTable = new MatTableDataSource([]);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    //table 1
    this.dataSourceTable.paginator = this.paginatorTable;
    this.dataSourceTable.sort = this.sortTable;
  }

  applyFilterTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTable.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceTable.paginator) {
      this.dataSourceTable.paginator.firstPage();
    }
  }

  // //TODO:
  // public async lazyLoadDetailData() {
  //   //get data source
  //   var data = this.dataSourceTable.data;

  //   for (const element of data) {
  //     var idAccountingYear = element.idAccountingYearSetting;
  //     var idWorkHour = element.idWorkHour;
  //     var uid = element.uid;
  //     var wh: WorkHour;
  //     var ay: AccountingYearWorkHour;
  //     var ud: UserDataTableView;

  //     //TODO: das geht so nicht, denn das muss aus den sub collections der user geholt werden
  //     try {
  //       //load work hour data
  //       wh = await this.dataModel.getCollectionItemById(
  //         Sub_WorkHours(),
  //         idWorkHour
  //       );
  //       element['action'] = wh.action;
  //       element['actionComment'] = wh.actionComment;
  //       element['actionDoneByName'] = wh.actionDoneByName;
  //       element['amount'] = wh.amount;
  //       element['reason'] = wh.reason;
  //     } catch (error) {
  //       //no doc found or error
  //     }
  //     try {
  //       //load accounting year data
  //       ay = await this.dataModel.getCollectionItemById(
  //         AccountingYear(),
  //         idAccountingYear
  //       );
  //       element['amountEvents'] = ay.amountEvents;
  //     } catch (error) {
  //       //no doc found or error
  //     }
  //     try {
  //       //load user data
  //       ud = await this.dataModel.getCollectionItemById(Users(), uid);
  //       var name = this.commonFunctions.getPrettyPrintedUserName(ud);
  //       element['sourceUserName'] = name;
  //     } catch (error) {
  //       //no doc found or error
  //     }
  //   }
  // }
}
