<h1 mat-dialog-title>{{ title }}</h1>
<hr style="opacity: 0.2" />
<div mat-dialog-content>
  <form [formGroup]="myForm">
    <!--Schichtname-->
    <mat-form-field class="form-fields">
      <mat-label>Schichtname</mat-label>
      <input matInput [formControl]="typeFormControl" />
    </mat-form-field>

    <!--Min. Participants-->
    <mat-form-field
      class="form-fields"
      *ngIf="currentSelectedWpKind == wpKind.Standard"
    >
      <mat-label>Minmale Teilnehmerzahl</mat-label>
      <input matInput type="number" [formControl]="minParticipants" />
    </mat-form-field>

    <!--Responsible Person-->
    <mat-form-field class="form-fields">
      <mat-label>Verantwortliche Person</mat-label>
      <input
        type="text"
        placeholder=""
        aria-label="Namen eingeben..."
        matInput
        [formControl]="userSelectorFormControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredNameOptions | async"
          [value]="option"
        >
          {{ option.personalData?.firstName }}
          {{ option.personalData?.lastName }} ({{ option.email }})
        </mat-option>
      </mat-autocomplete>
      <button
        *ngIf="userSelectorFormControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="userSelectorFormControl.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!--Time range-->
    <mat-form-field class="form-fields">
      <input
        matInput
        [formControl]="rangeFormControl"
        [owlDateTime]="dt2"
        placeholder="Zeitspanne"
        [selectMode]="'range'"
        [min]="minDate"
        [max]="maxDate"
        [owlDateTimeTrigger]="dt2"
      />
      <owl-date-time #dt2></owl-date-time>
      <mat-icon matSuffix [owlDateTimeTrigger]="dt2">date_range</mat-icon>
    </mat-form-field>

    <!-- Work package kind-->
    <div style="padding-top: 20px">
      <mat-radio-group
        [formControl]="kindFormControl"
        (change)="kindChanged($event)"
      >
        <mat-radio-button
          *ngFor="let item of wpKindEnum"
          [value]="item"
          class="form-fields"
          style="width: 100%"
          color="primary"
        >
          {{ item | wpKind }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Ue18-->
    <div>
      <mat-checkbox
        class="form-fields"
        [formControl]="ue18FormControl"
        (click)="$event.stopPropagation()"
        [checked]="true"
      >
        <span
          class="mat-checkbox-label"
          style="font-size: 1rem; padding-left: 3px"
          ><span style="display: none">&nbsp;</span> Ü18
        </span>
      </mat-checkbox>
    </div>
  </form>
</div>
<hr style="opacity: 0.2" />
<div mat-dialog-actions class="dialog-btn-container">
  <button
    mat-raised-button
    type="reset"
    class="dialog-btn"
    (click)="onNoClick()"
  >
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="primary"
    class="dialog-btn"
    (click)="setWp()"
    [disabled]="!myForm.valid"
  >
    {{ buttonOk }}
  </button>
</div>
