import { Injectable } from '@angular/core';
import { LogConsole } from './log-console.service';
import { LogLocalStorage } from './log-local-storage.service';
import { LogPublisher } from './LogPublisher';

@Injectable({
  providedIn: 'root',
})
export class LogPublisherService {
  constructor() {
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  buildPublishers(): void {
    // Create instance of LogConsole Class
    this.publishers.push(new LogConsole());

    // Create instance of `LogLocalStorage` Class
    this.publishers.push(new LogLocalStorage());
  }
}
