import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import {
  DataModelService,
  EncouragementPointEntryAction,
} from '../services/data-model.service';
import {
  Sub_EncouragementPoints,
  Properties,
  UserDataTableView,
  User,
} from '../services/definitions.service';
import { UniversalDialogDialogData } from '../universal-dialog/universal-dialog';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css'],
})
export class StatisticsComponent implements OnInit {
  public userData: User; //muss public sein
  form = new FormGroup({});
  userDataLoaded: boolean = false;
  userEntryControl = new FormControl();
  options: User[] = [];
  filteredOptions: Observable<string[]>;
  filteredNameOptions: Observable<User[]>;
  allUsersData: User[];
  showTable: boolean;
  allUsersEncouragementPoints: any[] = [];
  givenPoints: any[] = [];
  gotPoints: any[] = [];

  //-------- Table data: Vergebene Punkte ------------
  @ViewChild(MatPaginator) paginatorTable1: MatPaginator;
  @ViewChild(MatSort) sortTable1: MatSort;
  dataSourceTable1: MatTableDataSource<UserDataTableView>;
  displayedColumnsTable1: string[] = [
    'amount',
    'reason',
    'comment',
    'givenTo',
    'timestamp',
  ];
  //---------------------------------------------------

  //-------- Table data: Erhaltene Punkte ------------
  @ViewChild(MatPaginator) paginatorTable2: MatPaginator;
  @ViewChild(MatSort) sortTable2: MatSort;
  dataSourceTable2: MatTableDataSource<UserDataTableView>;
  displayedColumnsTable2: string[] = [
    'amount',
    'reason',
    'comment',
    'gotFrom',
    'timestamp',
  ];
  //---------------------------------------------------

  constructor(
    private dataModel: DataModelService,
    private commonFunctions: CommonFunctionsService,
    private spinnerService: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private router: Router
  ) {
    spinnerService.show();

    //check if in mobile mode
    if (this.deviceService.isMobile()) {
      commonFunctions
        .openDialog(
          'Achtung',
          'Diese Seite ist nicht für die Mobile Ansicht optimiert. Bitte öffne sie auf dem PC.',
          DialogType.INFO,
          'Abbrechen',
          'Fortfahren'
        )
        .afterClosed()
        .subscribe((result: UniversalDialogDialogData) => {
          if (result.result != 1) {
            this.router.navigateByUrl('/');
            return;
          }
        });
    }

    commonFunctions.registerEventEmitterHandlerUserData((userData: User) => {
      this.userData = userData;
    });
    this.dataSourceTable1 = new MatTableDataSource([]);
    this.dataSourceTable2 = new MatTableDataSource([]);
  }

  ngAfterViewInit() {
    //table 1
    this.dataSourceTable1.paginator = this.paginatorTable1;
    this.dataSourceTable1.sort = this.sortTable1;
    //table 2
    this.dataSourceTable2.paginator = this.paginatorTable2;
    this.dataSourceTable2.sort = this.sortTable2;
  }

  applyFilterTable1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTable1.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceTable1.paginator) {
      this.dataSourceTable1.paginator.firstPage();
    }
  }

  applyFilterTable2(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceTable2.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceTable2.paginator) {
      this.dataSourceTable2.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.commonFunctions.loadAllUsers(
      (res: boolean, allUsersData: User[], options: User[]) => {
        if (res) {
          this.allUsersData = allUsersData;
          this.options = options;
          this.userDataLoaded = true;

          this.filteredNameOptions = this.userEntryControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || ''))
          );

          this.loadAllUsersEncouragementPoints(() => {
            //pre-select current user
            var thisUserId = this.userData.uid;
            var found = this.options.find((x) => {
              return x['uid'] == thisUserId;
            });
            if (found) {
              this.userEntryControl.setValue(found);
              this.optionSelected(found);
            }
          });
        }
      }
    );
  }

  //Filter input by name
  private _filter(value: string): User[] {
    if (typeof value == 'string') {
      const filterValue = value.toLowerCase();

      return this.options.filter((option: User) =>
        this.displayFn(option).toLowerCase().includes(filterValue)
      );
    }
  }

  //Loads all users encouragement points
  public loadAllUsersEncouragementPoints(callbackFn) {
    var errors = [];
    var itemsProcessed = 0;

    //find selected user id in 'allUsersData'
    this.allUsersData.forEach((item, index, array) => {
      var uid = item.uid;

      //load encouragement points
      this.dataModel
        .getUserSubcollectionDoc(uid, Sub_EncouragementPoints())
        .then(
          (value) => {
            //den namen extrahieren und den daten anhaengen
            var userName = this.commonFunctions.getPrettyPrintedUserName(item);
            value.forEach((x) => {
              x[Properties.SourceUserName] = userName;
              x[Properties.SourceUserId] = uid;
            });
            this.allUsersEncouragementPoints =
              this.allUsersEncouragementPoints.concat(value);
          },
          (rejectedReason) => {
            errors.push(item.email);
          }
        )
        .catch((error) => {
          errors.push(item.email);
        })
        .finally(() => {
          itemsProcessed++;
          if (itemsProcessed === array.length) {
            this.spinnerService.hide();
            callbackFn();

            if (errors.length > 0) {
              var preText =
                'Förderpunkte konnten nicht geladen werden für user: ';
              this.commonFunctions.openDialog(
                'Laden fehlgeschlagen',
                preText + errors.join(', '),
                DialogType.ERROR
              );
            }
          }
        });
    });
  }

  public displayFn(user: User): string {
    if (!user) {
      return '';
    }
    var firstName = user?.personalData?.firstName;
    var lastName = user?.personalData?.lastName;
    var fullName = firstName + ' ' + lastName;
    return fullName && fullName ? fullName : '';
  }

  //name selection done
  public optionSelected(val: any) {
    var uid = val.uid;
    var itemsProcessed = 0;
    this.gotPoints = [];
    this.givenPoints = [];

    //filter data
    this.allUsersEncouragementPoints.forEach((item, ix, arr) => {
      itemsProcessed++;

      //Vergebene Punkte - Suche alle Eintraege bei denen das property "actionDoneBy" == der uid des ausgewaehlten users entspricht und Action == Hinzugefuegt
      if (
        item[Properties.ActionDoneBy] == uid &&
        item[Properties.Action] == EncouragementPointEntryAction.ADD
      ) {
        this.givenPoints.push(item);
      }
      //got points
      if (item[Properties.SourceUserId] == uid) {
        this.gotPoints.push(item);
      }

      if (itemsProcessed === arr.length) {
        //set data source - table 1
        this.dataSourceTable1 = new MatTableDataSource(this.givenPoints);
        this.dataSourceTable1.paginator = this.paginatorTable1;
        this.dataSourceTable1.sort = this.sortTable1;
        //set data source - table 2
        this.dataSourceTable2 = new MatTableDataSource(this.gotPoints);
        this.dataSourceTable2.paginator = this.paginatorTable2;
        this.dataSourceTable2.sort = this.sortTable2;

        this.showTable = true;
      }
    });
  }

  public sumGivenPoints() {
    if (this.givenPoints) {
      var sum = 0;
      this.givenPoints.forEach((element) => {
        if (Properties.Amount in element) {
          sum += +element[Properties.Amount];
        }
      });
      return sum;
    }
    return 0;
  }

  public sumGotPoints() {
    if (this.gotPoints) {
      var sum = 0;
      this.gotPoints.forEach((element) => {
        if (Properties.Amount in element) {
          sum += +element[Properties.Amount];
        }
      });
      return sum;
    }
    return 0;
  }
}
