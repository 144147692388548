import { Injectable } from '@angular/core';
import {
  EncouragementPointEntryAction,
  EncouragementPointReason,
} from './data-model.service';
import {
  GetAmountWorkHoursForOneEncouragementPoint,
  GetMinPointsWorkHoursForEncouragementModel,
  GetServiceUserEmail,
  GetServiceUserPW,
  GetDefaultUserPW,
} from './../shared/shared.js';
import firebase from 'firebase/app';

//Mindestanzahl an Arbeitsstunden die man erreichen muss um am Förderprogramm teilnehmen zu können
export const MinPointsWorkHoursForEncouragementModel =
  GetMinPointsWorkHoursForEncouragementModel();

//Anzahl der Arbeitsstunden die einen Förderpunkt generieren
export const AmountWorkHoursForOneEncouragementPoint =
  GetAmountWorkHoursForOneEncouragementPoint();

export const SiteURL = 'https://residenz-app.club';

export const Salt = '4HZI^9V@zJxbmCU&42%dj7!Zk1$Row4Wf46CO7%G7k';

export const HiddenFunctionsPW = 94610538;

export const TestSystemPW = 8967;

export const TestSystemPWAdmin = 5643;

export const DefaultUserPW = GetDefaultUserPW();

export const ServiceUserEmail = GetServiceUserEmail();
export const ServiceUserPW = GetServiceUserPW();

export enum ErrorCodes {
  INITIAL_DATASTRUCTURE_FAILED_TO_CREATE = 5001,
  ERROR_LOADING_USER_DATA = 5002,

  LOGIN_HANDLE_ONE_TIME_CODE_DB_UPDATE = 6001,
}

export const LOC_STOR_TEST_SYSTEM = 'test_system_active';
export const LOC_STOR_ADMIN_ACCESS = 'test_system_used_by_admin';

//Is test system active?
export var IsTestSystemActive = () => {
  var val = localStorage.getItem(LOC_STOR_TEST_SYSTEM);
  if (val != null) {
    return val.toLowerCase() == 'true' ? true : false;
  }
  return false;
};

//Set if test system shall be active
export var SetTestSystemActive = (val: boolean) => {
  localStorage.setItem(LOC_STOR_TEST_SYSTEM, String(val));
};

export var SetTestSystemUsedByAdmin = (val: boolean) => {
  localStorage.setItem(LOC_STOR_ADMIN_ACCESS, String(val));
};

export var IsAdminAccessing = () => {
  var val = localStorage.getItem(LOC_STOR_ADMIN_ACCESS);
  if (val != null) {
    return val.toLowerCase() == 'true' ? true : false;
  }
  return false;
};

export const Users = () => {
  return !IsTestSystemActive() ? 'users' : 'test_users';
};
export const Sub_WorkHours = () => {
  return 'workHours';
};
export const Sub_EncouragementPoints = () => {
  return 'encouragementPoints';
};
export const ProtocolWorkHours = () => {
  return !IsTestSystemActive() ? 'protocolWorkHours' : 'test_protocolWorkHours';
};
export const ProtocolEncouragementPoints = () => {
  return !IsTestSystemActive()
    ? 'protocolEncouragementPoints'
    : 'test_protocolEncouragementPoints';
};
export const AccountingYear = () => {
  return !IsTestSystemActive()
    ? 'accountingYearWorkHour'
    : 'test_accountingYearWorkHour';
};
export const PointTransfer = () => {
  return !IsTestSystemActive() ? 'pointTransfer' : 'test_pointTransfer';
};
export const Event = () => {
  return !IsTestSystemActive() ? 'event' : 'test_event';
};
export const Sub_WorkPackage = () => {
  return 'workPackage';
};
export const Sub_Participant = () => {
  return 'participant';
};
export const Settings = () => {
  return 'settings';
};

export enum Route {
  UNDEFINED = 'undefined',
  POINTS_ACCOUNT_ADMIN = 'point-accounts-admin',
  WORK_HOURS_ADMIN = 'working-hour-admin',
  ID_CARD = 'id-card',
  ENC_POINTS = '',
  WORK_HOURS = 'working-hours',
  SETTINGS = 'settings',
  POINT_RECEIVE = 'point-receive',
  POINT_SEND = 'point-send',
  STATISTICS = 'statistics',
  PROTOCOL_VIEW = 'protocol-view',
  EVENTS_ADMIN = 'events-admin',
  EVENTS = 'events',
}

export enum TransferState {
  UNDEFINED = 'undefined',
  PENDING = 'pending',
  DECLINED = 'declined',
  FINISHED = 'finished',
}

export enum ParticipantState {
  UNDEFINED = 0,
  PARTICIPATING = 1,
  ENDED_EARLIER = 2,
}

export interface User {
  contact: UserContact;
  displayName: string;
  email: string;
  membership: UserMembership;
  notificationIds?: string[];
  personalData: UserPersonalData;
  rights: number[];
  sumEncouragementPoints: number;
  sumWorkHours: number;
  userGroups: number[];
  uid: string;
}

export interface UserContact {
  emailCompany?: string;
  emailPrivate: string;
  telCompany?: string;
  telMobile?: string;
  telPrivate?: string;
}

export interface UserMembership {
  entryDate?: firebase.firestore.Timestamp;
  leavingDate?: firebase.firestore.Timestamp;
  number: number;
  participatesEncouragementModel: boolean;
  participatesWorkHoursModel: boolean;
  state?: string;
  type?: string;
}

export interface UserPersonalData {
  birthDate: firebase.firestore.Timestamp;
  firstName: string;
  lastName: string;
  profilePic?: string;
  street: string;
  houseNumber: string;
  plz: number;
  city: string;
}

export interface IDateRange {
  start: Date;
  end: Date;
  valid: boolean;
}

export enum WorkPackageKind {
  Standard = 0,
  Construction = 1,
  Dismantling = 2,
}

export interface ISettings {
  maintenanceMode: boolean;
}

export interface IComponentChanged {
  id?: string;
}

export interface IEventChanged {
  event: IEvent;
}

export interface IHelperPlacesFree {
  id?: string;
  free: boolean;
}

export interface IEvent {
  idAccountingYear: string;
  name: string;
  locationName: string;
  description: string;
  houseNumber: string;
  street: string;
  plz: number;
  city: string;
  responsiblePerson: string;
  responsiblePersonId: string;
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp;
  initialPath: string;
  path?: string;
  parent?: string;
  id?: string;
  workPackages?: IWorkPackage[];
  processedInBackend?: boolean;
  releasedForUsers?: boolean;
  numericCode?: number;
}

export interface IWorkPackage {
  type: string;
  minParticipants: number;
  responsiblePerson: string;
  responsiblePersonUid: string;
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp;
  path?: string;
  parent?: string;
  id?: string;
  participants?: IParticipant[];
  kind: WorkPackageKind;
  running?: boolean; //indicates (in case kind == construction or dismantling) if the shift has been started and is running
  releasedForUsers?: boolean;
  isUe18: boolean; //Indicates if this shift is just for users that are above the age of 18
}

export enum NotificationType {
  Standard = 0,
  MessageFromAdmin = 1,
  MessageFromEventOrganizer = 2,
  MessageFromShiftOrganizer = 3,
  SystemMessage = 4,
  GeneralReminder = 5,
  SilentAction = 100,
}

export enum SilentActions {
  SendLogFilesToDev = 0,
  LogOut = 1,
  ClearLogFiles = 2,
}

export interface PushNotificationContainer {
  fcmMessageId: string;
  from: string;
  priority: string;
  notification: PushNotification;
}

export interface PushNotification {
  date: Date;
  notificationTitle: string;
  notificationBody: string;
  messageTitle?: string;
  messageBody?: string;
  senderUid?: string;
  senderName?: string;
  messageType: NotificationType;
  route?: string;
  silentActionId?: number;
}

export interface DateGroupedWorkPackages {
  date: Date;
  workPackages?: IWorkPackage[];
}

export interface TypeGroupedWorkPackages {
  topic: string;
  workPackages?: IWorkPackage[];
}

export interface IParticipant {
  state: ParticipantState;
  firstName: string;
  lastName: string;
  userId: string;
  start: firebase.firestore.Timestamp;
  end: firebase.firestore.Timestamp;
  path?: string;
  parent?: string;
  id?: string;
  present: boolean;
}

export interface UserDataTableView {
  amount: number;
  action: number;
  reason: string;
  comment: string;
  doneby: string;
  timestamp: string;
  id: string;
}

export interface MethodReturn {
  result: boolean;
  errorMessage?: string;
  data?: any;
  documentPath?: string;
  parentPath?: string;
  id?: string;
}

export interface AccountingYearWorkHour {
  amountEvents: number;
  end: string;
  start: string;
}

export interface ProtocolWorkHours {
  action: number;
  idAccountingYearSetting: string;
  idWorkHour: string;
  ts: any;
  uid: string;
}

export interface WorkHour {
  action: number;
  accountingYearSetting: string;
  actionComment: string;
  actionDoneBy: string;
  actionDoneByName: string;
  amount: number;
  reason: number;
  timestamp: string;
}

export interface PointTransferTransaction {
  done: boolean;
  succeed: boolean;
  message: string;
}

export interface PointTransfer {
  amountPoints: number;
  comment: string;
  receiverUserId: string;
  receiverUserName: string;
  senderUserId: string;
  senderUserName: string;
  state: string;
  timestamp: firebase.firestore.Timestamp;
  id?: string;
}

export enum Properties {
  Action = 'action',
  ActionDoneBy = 'actionDoneBy',
  SourceUserName = 'sourceUserName',
  SourceUserId = 'sourceUserId',
  Amount = 'amount',
  SenderUserId = 'senderUserId',
  ReceiverUserId = 'receiverUserId',
  AmountPoints = 'amountPoints',
  State = 'state',
  Comment = 'comment',
  ReceiverUserName = 'receiverUserName',
  SenderUserName = 'senderUserName',
  ID = 'id',
  UID = 'uid',
  Timestamp = 'timestamp',
  Email = 'email',
  RandomId = 'randomId',
  CollectionPath = 'collectionPath',
  Path = 'path',
  ParentPath = 'parent',
  UserId = 'userId',
  IdWorkHour = 'idWorkHour',
  IdEncouragementPoint = 'idEncouragementPoint',
  Start = 'start',
  End = 'end',
  ChangedTimes = 'changedTimes',
}

export enum EventState {
  Running = 'Aktuell laufend',
  NotBegan = 'Hat noch nicht begonnen',
  Past = 'Beendet',
}

export enum EventViewMode {
  Reader = 0, //kann event nur sehen
  Participant = 1, //kann sich z.b. fuer Schichten anmelden
  Admin = 2,
  SimplifiedAdmin = 3, //vereinfachte Ansicht fuer mobile und waehrend der Veranstaltung
}

export enum Times {
  TwelveYears = 378432000000,
  ThirteenYears = 409968000000,
  FourteenYears = 441504000000,
  SeventeenYears = 536112000000,
  EighteenYears = 536112000000,
}

export enum UserGroupEntity {
  ADMIN = 1,
  USER = 2,
  POINTS_RECEIVER = 3,
  ONLY_RALF = 9,
  EVENT_TESTER = 20,
  EVENT_ADMIN = 21,
}

export enum EncouragementPointEntryDialogIntention {
  ADD,
  MODIFY,
  DELETE,
  ADD_MOBILE,
}

export enum EncouragementPointEntryDialogResult {
  OK,
  FAILED,
  ABORTED,
}

export interface EncouragementPointEntryDialogData {
  result: EncouragementPointEntryDialogResult;
  dialogIntention: EncouragementPointEntryDialogIntention;
  dialogTitle: string;
  descriptionText: string;
  buttonNoName: string;
  buttonYesName: string;
  comment: string;
  action: EncouragementPointEntryAction;
  reason: EncouragementPointReason;
  amount: number;
  maxAmount: number;
  currentUserId: string;
  currentUserName: string;
  destinationUserId: string;
  destinationUserName: string;
}

export interface AddEventDialogData {
  result: number;
  idAccountingYear?: string;
  name?: string;
  locationName?: string;
  description?: string;
  houseNumber?: string;
  street?: string;
  plz?: number;
  city?: string;
  responsiblePerson?: string;
  responsiblePersonId?: string;
  start?: firebase.firestore.Timestamp;
  end?: firebase.firestore.Timestamp;
  id?: string;
  allUserData: User[];
  userOptions?: any;
}

export interface AddWpDialogData {
  result: number;
  type?: string;
  responsiblePerson?: string;
  responsiblePersonId?: string;
  minParticipants?: number;
  start?: firebase.firestore.Timestamp;
  end?: firebase.firestore.Timestamp;
  id?: string;
  allUserData?: User[];
  userOptions?: any;
  path: string;
  parentPath?: string;
  eventData?: IEvent;
  kind?: WorkPackageKind;
  constructionShiftPresent: boolean;
  dismantlingShiftPresent: boolean;
  isUe18: boolean;
}

export interface AddParticipantsDialogData {
  result: number;
  participants: IParticipant[];
  id?: string;
  allUserData?: User[];
  workPackage: IWorkPackage;
  path: string;
  parentPath?: string;
  eventData?: IEvent;
}

export interface AbortShiftDialogData {
  decisionYes: boolean;
  aborteeName: string;
  eventData: IEvent;
  shiftData: IWorkPackage;
  comment?: string;
  userDataShiftRespPerson?: User;
  userDataOrganizer?: User;
  shiftOrganizerPhoneNumber?: string;
}

export enum QrCodeTrigger {
  SHIFT_REGISTRATION = 'shift_registration',
  CONSTR_DEMANTL_USER_PRESENT = 'contr_dismant_user_present',
  UNKNWON = 'unknown',
}

export interface QrCodeDialogData {
  title: string;
  decisionYes: boolean;
  qrCodeLink: string;
  qrCodeTrigger: QrCodeTrigger;
}

export interface CamScanDialogData {
  title: string;
  decisionYes: boolean;
  qrCodeTrigger: QrCodeTrigger;
  payload?: string;
}

export enum WorkHourEntryDialogIntention {
  ADD,
  MODIFY,
  DELETE,
  ADD_MOBILE,
}

export enum WorkHourEntryDialogResult {
  OK,
  FAILED,
  ABORTED,
}

export interface WpViewTitle {
  title: string;
  timeOfShift: string;
  freePlaces?: string;
  state?: string;
  amountParticipants?: number;
  amountMinParticipants?: number;
  dayName?: string;
}

@Injectable()
export class WorkHoursService {
  constructor() {}

  //Gibt den multiplikator fuer die Arbeitsstunden wieder. Dieser ist abhaengig vom Alter
  public getPointMultiplyer(userBirtdayDate: Date) {
    if (!userBirtdayDate) {
      return -1;
    }

    var diff = new Date().getTime() - userBirtdayDate.getTime();
    diff = Math.abs(diff);

    //Wenn Alter:

    //- unter 12 = 0 Stunden
    if (diff < Times.TwelveYears) {
      return 0;
    }
    //- 12-13 = * 2 * (1/3)
    if (diff >= Times.TwelveYears && diff < Times.FourteenYears) {
      return 2 * (1 / 3);
    }
    //- 14-17 = * 2 * (2/3)
    if (diff >= Times.FourteenYears && diff <= Times.EighteenYears) {
      return 2 * (2 / 3);
    }
    //- über 18 = * 2 * (3/3)
    if (diff >= Times.EighteenYears) {
      return 2 * (3 / 3);
    }
    return -1;
  }
}
