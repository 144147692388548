import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonFunctionsService } from 'src/app/services/common-functions.service';
import {
  AbortShiftDialogData,
  SiteURL,
} from 'src/app/services/definitions.service';
import { LogService } from 'src/app/services/log.service';

@Component({
  selector: 'app-shift-abort',
  templateUrl: './shift-abort.component.html',
  styleUrls: ['./shift-abort.component.css'],
})
export class ShiftAbortComponent implements OnInit {
  commentFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(5),
    Validators.maxLength(500),
  ]);
  myForm: FormGroup;
  onlyCall: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ShiftAbortComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AbortShiftDialogData,
    private commonFunctions: CommonFunctionsService,
    private log: LogService,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    fb: FormBuilder
  ) {
    this.myForm = fb.group({
      comment: this.commentFormControl,
    });
  }

  ngOnInit(): void {
    //check phone number
    if (this.data.shiftOrganizerPhoneNumber) 
      this.onlyCall = this.isWithin48HoursBefore();    
  }

  onNoClick(): void {
    this.data.decisionYes = false;
    this.dialogRef.close(this.data);
  }

  async onYesClick(): Promise<void> {
    if (this.data?.userDataOrganizer && this.data?.userDataShiftRespPerson) {
      var comment = this.myForm.get('comment').value;
      this.data.comment = comment;

      //prepare data for mails
      var mailEventOrganizer = this.data?.userDataOrganizer.email;
      var mailShiftOrganizer = this.data?.userDataShiftRespPerson.email;
      var nameEventOrganizer = `${this.commonFunctions.getPrettyPrintedFirstName(
        this.data?.userDataOrganizer
      )} ${this.commonFunctions.getPrettyPrintedLastName(
        this.data?.userDataOrganizer
      )}`;
      var nameShiftOrganizer = `${this.commonFunctions.getPrettyPrintedFirstName(
        this.data?.userDataShiftRespPerson
      )} ${this.commonFunctions.getPrettyPrintedLastName(
        this.data?.userDataShiftRespPerson
      )}`;
      var aborteeName = this.data.aborteeName;
      var eventName = this.data.eventData.name;
      var eventDate = new Date(
        this.data.eventData.start.seconds * 1000
      ).toLocaleDateString('de-DE');
      var shiftName = this.data.shiftData.type;
      var shiftStart = new Date(this.data.shiftData.start.seconds * 1000);
      var shiftEnd = new Date(this.data.shiftData.end.seconds * 1000);
      var startMin =
        (shiftStart.getMinutes() < 10 ? '0' : '') + shiftStart.getMinutes();
      var endMin =
        (shiftEnd.getMinutes() < 10 ? '0' : '') + shiftEnd.getMinutes();
      var shiftDateRange = `${shiftStart.getHours()}:${startMin} Uhr - ${shiftEnd.getHours()}:${endMin} Uhr`;

      //send mail to event organizer
      // await this.sendMail(
      //   comment,
      //   mailEventOrganizer,
      //   nameEventOrganizer,
      //   aborteeName,
      //   eventName,
      //   eventDate,
      //   shiftName,
      //   shiftDateRange
      // );

      //send mail to shift organizer
      await this.sendMail(
        comment,
        mailShiftOrganizer,
        nameShiftOrganizer,
        aborteeName,
        eventName,
        eventDate,
        shiftName,
        shiftDateRange
      );

      this.data.decisionYes = true;
      this.dialogRef.close(this.data);
    } else {
      this.commonFunctions.showErrorToast(
        'Event-Verantwortlicher konnte nicht geladen werden'
      );
      this.data.decisionYes = false;
      this.dialogRef.close(this.data);
    }
  }

  //Send an email
  private async sendMail(
    comment: string,
    receiverEmail: string,
    nameOrganizer: string,
    nameAbortee: string,
    eventName: string,
    eventDate: string,
    shiftName: string,
    shiftTimeRange: string
  ) {
    let formData = new FormData();

    formData.append('receiver_mail', receiverEmail);
    formData.append('organizer_name', nameOrganizer);
    formData.append('event_name', eventName);
    formData.append('event_date', eventDate);
    formData.append('shift_name', shiftName);
    formData.append('shift_time_range', shiftTimeRange);
    formData.append('abort_comment', comment);
    formData.append('name_abortee', nameAbortee);
    var file_data = formData;

    return await this.http
      .post(SiteURL + '/backend/email-shift-abort.php', file_data)
      .subscribe(
        (res) => {
          if (res && res == 1) {
            return true;
          } else {
            this.log.error(
              `Could not send shift abort email to receiver ${receiverEmail}`,
              res
            );
            this.commonFunctions.showErrorToast(
              `E-Mail konnte nicht an ${receiverEmail} gesendet werden`
            );
          }
          this.spinner.hide();
          return false;
        },
        (err) => {
          this.log.error(
            `Could not send shift abort email to receiver ${receiverEmail}`,
            err
          );
          this.commonFunctions.showErrorToast(
            `E-Mail konnte nicht an ${receiverEmail} gesendet werden`
          );
          this.spinner.hide();
          return false;
        }
      );
  }

  //Checks if the shift is within 48 hours before the specified time
  private isWithin48HoursBefore(): boolean {
    var specifiedTime = new Date(this.data.shiftData.start.seconds * 1000);
    const currentTime = new Date();
    const specifiedTimeMillis = specifiedTime.getTime();
    const currentTimeMillis = currentTime.getTime();

    const hours48Millis = 48 * 60 * 60 * 1000; // 48 hours in milliseconds
    
    return (
      currentTimeMillis >= specifiedTimeMillis - hours48Millis &&
      currentTimeMillis <= specifiedTimeMillis
    );
  }

  public callShiftOrganizer() {
    window.open(`tel:${this.data.shiftOrganizerPhoneNumber}`);
  }
}
