import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { IDateRange } from 'src/app/services/definitions.service';

// https://angular.io/guide/view-encapsulation
@Component({
  selector: 'time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.css'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TimeRangePickerComponent implements OnInit {
  @Input() start: Date;
  @Input() end: Date;
  @Input() rangeStart: Date;
  @Input() rangeEnd: Date;
  @Input() disable: boolean = false;
  @Output() timeChanged = new EventEmitter<IDateRange>();

  private valStartDate: Date;
  private valEndDate: Date;

  startFormControl = new FormControl();
  endFormControl = new FormControl();
  myForm: FormGroup;
  maxStartDate: string;
  minStartDate: string;
  maxEndDate: string;
  minEndDate: string;

  constructor(fb: FormBuilder) {
    this.myForm = fb.group({
      from: this.startFormControl,
      end: this.endFormControl,
    });
  }

  ngOnInit(): void {
    //set min und max times
    if (this.rangeStart && this.rangeEnd) {
      this.valStartDate = this.start;
      this.valEndDate = this.end;

      //range values
      var rangeStart = `${this.asSplittedTime(this.rangeStart)[0]}:${
        this.asSplittedTime(this.rangeStart)[1]
      }`;
      var rangeEnd = `${this.asSplittedTime(this.rangeEnd)[0]}:${
        this.asSplittedTime(this.rangeEnd)[1]
      }`;
      var currentStart = rangeStart;
      var currentEnd = rangeEnd;

      //set actual values if available
      if (this.start && this.end) {
        currentStart = `${this.asSplittedTime(this.start)[0]}:${
          this.asSplittedTime(this.start)[1]
        }`;
        currentEnd = `${this.asSplittedTime(this.end)[0]}:${
          this.asSplittedTime(this.end)[1]
        }`;
      }

      this.myForm.controls['from'].setValue(currentStart);
      this.myForm.controls['end'].setValue(currentEnd);

      this.minStartDate = rangeStart;
      this.maxStartDate = rangeEnd;
      this.maxEndDate = rangeEnd;
    }
    if (this.disable) {
      this.myForm.controls['from'].disable();
      this.myForm.controls['end'].disable();
    }
  }

  startTimeChanged(value: string) {
    this.valStartDate = this.toDate(value);
    this.setMinEndTime(value);
    this.triggerEmitValue();
  }

  endTimeChanged(value: string) {
    this.valEndDate = this.toDate(value);
    this.triggerEmitValue();
  }

  //Sets the min time of the end-time input field
  private setMinEndTime(currentStartDateAsString: string) {
    var asDate: Date = this.toDate(currentStartDateAsString);
    if (asDate) {
      var newDate = asDate;
      newDate.setMinutes(asDate.getMinutes() + 10);
      //set min of end time
      this.minEndDate = `${newDate.getHours()}:${newDate.getMinutes()}`;
    }
  }

  private asSplittedTime(date: Date): string[] {
    return date
      ? [date.getHours().toString(), date.getMinutes().toString()]
      : ['', ''];
  }

  private toDate(dateAsString: string): Date {
    if (dateAsString) {
      var splitted = dateAsString.split(':');
      var hours = +splitted[0];
      var min = +splitted[1];

      var newDate: Date = new Date();
      newDate.setHours(hours);
      newDate.setMinutes(min);
      return newDate;
    }
    return undefined;
  }

  //Validate if times are ok
  private validateDates(): boolean {
    if (this.valStartDate && this.valEndDate) {
      var modStart = this.start;
      modStart.setHours(this.valStartDate.getHours());
      modStart.setMinutes(this.valStartDate.getMinutes());
      var modEnd = this.end;
      modEnd.setHours(this.valEndDate.getHours());
      modEnd.setMinutes(this.valEndDate.getMinutes());

      if (modStart > modEnd) {
        this.myForm.controls['end'].setErrors({ incorrect: true });
      } else {
        this.myForm.controls['end'].setErrors(null);
        this.myForm.controls['end'].clearValidators();
        return true;
      }
    }
    return false;
  }

  //Are the times valid?
  public isValid() {
    return this.validateDates();
  }

  //Emits the values of this component to the parent
  private triggerEmitValue() {
    // check validity
    this.validateDates();
    var date: IDateRange = { start: modStart, end: modEnd, valid: false };

    if (this.valStartDate && this.valEndDate && this.isValid()) {
      var modStart = this.start;
      modStart.setHours(this.valStartDate.getHours());
      modStart.setMinutes(this.valStartDate.getMinutes());
      var modEnd = this.end;
      modEnd.setHours(this.valEndDate.getHours());
      modEnd.setMinutes(this.valEndDate.getMinutes());
      date.start = modStart;
      date.end = modEnd;
      date.valid = true;
    }

    this.timeChanged.emit(date);
  }
}
