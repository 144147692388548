<mat-nav-list style="text-align: center">
  <table *ngIf="isViewTypeOther()">
    <tr *ngFor="let data of data.payload; let i = index">
      <td class="date">
        {{ data.timestamp.seconds * 1000 | date: "dd.MM.yyyy" }}
      </td>
      <td class="key">
        {{ data.actionComment }}
      </td>
      <td
        class="value"
        [ngStyle]="{ color: isAdd(data.action) ? 'green' : 'red' }"
      >
        {{ isAdd(data.action) ? "+" : "-" }} {{ data.amount }}
      </td>
      <td class="lock" *ngIf="showLockedIcon(data)">
        <mat-icon>lock</mat-icon>
      </td>
    </tr>
  </table>
  <!--Point transfer-->
  <table *ngIf="isViewTypePointTransfer()">
    <tr *ngFor="let data of data.payload; let i = index">
      <td class="date">
        {{ data.timestamp.seconds * 1000 | date: "dd.MM.yyyy" }}
      </td>
      <td class="comment">
        {{ data.comment }}
      </td>
      <td class="points">
        {{ data.amountPoints }}
      </td>
      <td class="point-transfer" *ngIf="isViewTypePointTransferSender()">
        {{ data.receiverUserName }}
      </td>
      <td class="point-transfer" *ngIf="isViewTypePointTransferReceiver()">
        {{ data.senderUserName }}
      </td>
    </tr>
  </table>
</mat-nav-list>
