<!-- <h1 mat-dialog-title>{{title}}</h1>
<hr /> -->
<div mat-dialog-content style="text-align: center">
  <div class="text">
    <p style="font-size: 1rem">{{ title }}</p>
  </div>
  <hr />

  <!--Source: https://github.com/zxing-js/ngx-scanner/wiki/Getting-Started-->
  <zxing-scanner
    #scanner
    class="scanner"
    [enable]="scannerEnabled"
    [autostart]="true"
    (camerasFound)="camerasFoundHandler($event)"
    (camerasNotFound)="camerasNotFoundHandler($event)"
    (scanSuccess)="scanSuccess($event)"
    (scanFailure)="scanFailure($event)"
    (scanError)="scanError($event)"
    (scanComplete)="scanComplete($event)"
    (deviceChange)="deviceHasChanged($event)"
    [formats]="['QR_CODE']"
    [timeBetweenScans]="2000"
    [delayBetweenScanSuccess]="5000"
  ></zxing-scanner>

  <div class="change-cam" *ngIf="camInitialized">
    <span style="font-size: 0.8rem" (click)="changeCamera()"
      >Kamera wechseln</span
    >
    <button style="font-size: 0.7rem" mat-icon-button (click)="changeCamera()">
      <mat-icon>replay</mat-icon>
    </button>
  </div>

  <!-- <div style="text-align: left">
    <button
      style="font-size: 0.7rem"
      mat-icon-button
      (click)="showInfoDialog()"
      aria-label="Kamera nicht sichtbar?"
    >
      <mat-icon>info</mat-icon>
    </button>
    <span style="font-size: 0.7rem" (click)="showInfoDialog()"
      >Kamera nicht sichtbar?</span
    >
  </div> -->
</div>
<hr />
<div mat-dialog-actions class="dialog-btn-container">
  <div>
    <button
      mat-raised-button
      color="primary"
      class="dialog-btn"
      (click)="onNoClick()"
    >
      Schliessen
    </button>
  </div>
</div>
