<ngx-spinner></ngx-spinner>

<!--Toolbar-->
<mat-toolbar
  color="primary"
  style="background: black; height: 90px"
  class="z-depth-3 app-toolbar"
>
  <button
    *ngIf="isLoggedIn"
    mat-icon-button
    (click)="sidenav.toggle()"
    aria-label="Example icon-button with menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <img
    style="height: 70px; cursor: pointer; margin-right: 20px"
    *ngIf="isLoggedIn"
    (click)="goToHome()"
    src="../../../../../assets/images/logo-white.png"
  />

  <p *ngIf="isLoggedIn" class="top-nav-text">
    {{ currentRouteName }}
  </p>

  <div *ngIf="!isLoggedIn" style="text-align: center; width: 100%">
    <p class="top-nav-text" style="font-size: 1em">Residenz App</p>
  </div>

  <span class="example-spacer"></span>
  <div *ngIf="isTestingSystemActive()" class="test-system-active-container">
    <p class="top-nav-text" style="color: white; padding-top: 0px">
      Testing System
    </p>
  </div>
  <button
    *ngIf="isIOsPlatform"
    mat-icon-button
    aria-label="Menue"
    (click)="reloadPage()"
  >
    <mat-icon>autorenew</mat-icon>
  </button>
  <button
    class="account-circle"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Menue"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="openPrivacyPage()">
      <mat-icon>privacy_tip</mat-icon>
      <span>Datenschutzerklärung</span>
    </button>
    <!-- <button
      mat-menu-item
      *ngIf="isLoggedIn"
      (click)="sidenav.close(); sendLogsToDev()"
    >
      <mat-icon>send</mat-icon>
      <span>Logs senden</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isLoggedIn"
      (click)="sidenav.close(); clearLogs()"
    >
      <mat-icon>clear</mat-icon>
      <span>Logs löschen</span>
    </button> -->
    <button
      mat-menu-item
      *ngIf="!isTestingSystemActive() && !isLoggedIn"
      (click)="changeToTestSystem()"
    >
      <mat-icon>sync</mat-icon>
      <span>Change to Test System</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isTestingSystemActive() && !isLoggedIn"
      (click)="changeToProductionSystem()"
    >
      <mat-icon>sync</mat-icon>
      <span>Change to Production System</span>
    </button>
    <button mat-menu-item (click)="openHelpPage()">
      <mat-icon>help</mat-icon>
      <span>Hilfe</span>
    </button>
    <button
      mat-menu-item
      (click)="sidenav.close()"
      [routerLink]="['/settings']"
    >
      <mat-icon>key</mat-icon>
      <span>Einstellungen</span>
    </button>
    <button
      mat-menu-item
      *ngIf="isLoggedIn"
      (click)="logout(); sidenav.close()"
    >
      <mat-icon>logout</mat-icon>
      <span>Ausloggen</span>
    </button>
  </mat-menu>
</mat-toolbar>

<!--Sidenav-->
<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav mode="over" opened="false" [fixedInViewport]="false">
    <mat-toolbar>
      <mat-toolbar-row>
        <h2>Menü</h2>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-nav-list>
      <!-- <h2 matSubheader>Mitglieder</h2> -->
      <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/home']"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> grade </mat-icon>
        Förderpunkte
      </a>

      <a
        mat-list-item
        *ngIf="showWorkHoursPage"
        (click)="sidenav.close()"
        [routerLink]="['/working-hours']"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> build_circle </mat-icon>
        Arbeitsstunden
      </a>

      <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/id-card']"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> contact_page </mat-icon>
        Mitgliedsausweis
      </a>

      <a
        mat-list-item
        (click)="sidenav.close()"
        routerLinkActive="list-item-active"
        [routerLink]="['/events']"
      >
        <mat-icon matListIcon> event </mat-icon>
        Events
      </a>

      <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/point-send']"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> attach_money </mat-icon>
        Punkte einlösen
      </a>

      <a
        mat-list-item
        *ngIf="showPointsReceiveMenuItem"
        (click)="sidenav.close()"
        [routerLink]="['/point-receive']"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> account_balance_wallet </mat-icon>
        Punkte einsammeln
      </a>

      <!-- <a
        mat-list-item
        (click)="sidenav.close()"
        [routerLink]="['/settings']"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> settings </mat-icon>
        Einstellungen
      </a> -->

      <mat-divider></mat-divider>
      <!-- <h2 matSubheader>Admin</h2> -->

      <a
        *ngIf="isAdmin"
        mat-list-item
        (click)="sidenav.close(); navigateToPointAccountsAdmin()"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> insert_chart </mat-icon>
        Förderpunkte verwalten
      </a>

      <a
        *ngIf="isAdmin"
        mat-list-item
        (click)="sidenav.close(); navigateToWorkHoursAdmin()"
        routerLinkActive="list-item-active"
      >
        <mat-icon matListIcon> insert_chart </mat-icon>
        Arbeitsstunden verwalten
      </a>

      <a
        *ngIf="isAdmin"
        mat-list-item
        (click)="sidenav.close()"
        routerLinkActive="list-item-active"
        [routerLink]="['/statistics']"
      >
        <mat-icon matListIcon> leaderboard </mat-icon>
        Statistik
      </a>

      <a
        *ngIf="showEventsAdmin"
        mat-list-item
        (click)="sidenav.close()"
        routerLinkActive="list-item-active"
        [routerLink]="['/events-admin']"
      >
        <mat-icon matListIcon> event </mat-icon>
        Event-Planung
      </a>

      <mat-divider></mat-divider>
      <a mat-list-item (click)="logout(); sidenav.close()">
        <mat-icon matListIcon> logout </mat-icon>
        Logout
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill class="sidenav-content">
    <router-outlet>
      <!-- <div
        *ngIf="!hideLoadingAnimation"
        style="width: 100%; text-align: center; height: 100%"
      >
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </div> -->
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
