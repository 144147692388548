<h1 mat-dialog-title>{{ title }}</h1>
<hr style="opacity: 0.2" />
<div mat-dialog-content>
  <form [formGroup]="myForm">
    <!--Name-->
    <mat-form-field class="form-fields">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="nameFormControl" />
    </mat-form-field>

    <!--Description-->
    <mat-form-field class="form-fields">
      <mat-label>Beschreibung</mat-label>
      <input matInput [formControl]="descriptionFormControl" />
    </mat-form-field>

    <!--Veranstaltungsort-->
    <mat-form-field class="form-fields">
      <mat-label>Veranstaltungsort</mat-label>
      <input matInput [formControl]="locationNameFormControl" />
    </mat-form-field>

    <!--Strasse-->
    <mat-form-field class="form-fields">
      <mat-label>Strasse</mat-label>
      <input matInput [formControl]="streetFormControl" />
    </mat-form-field>

    <!--House Number-->
    <mat-form-field class="form-fields">
      <mat-label>Hausnummer</mat-label>
      <input matInput [formControl]="houseNumberFormControl" />
    </mat-form-field>

    <!--PLZ-->
    <mat-form-field class="form-fields">
      <mat-label>PLZ</mat-label>
      <input
        matInput
        type="number"
        placeholder=""
        [formControl]="plzNumberFormControl"
        min="10000"
      />
    </mat-form-field>

    <!--Stadt-->
    <mat-form-field class="form-fields">
      <mat-label>Stadt</mat-label>
      <input matInput [formControl]="cityFormControl" />
    </mat-form-field>

    <!--Responsible Person-->
    <mat-form-field class="form-fields">
      <mat-label>Verantwortliche Person</mat-label>
      <input
        type="text"
        placeholder=""
        aria-label="Namen eingeben..."
        matInput
        [formControl]="userSelectorFormControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredNameOptions | async"
          [value]="option"
        >
          {{ option.personalData?.firstName }}
          {{ option.personalData?.lastName }} ({{ option.email }})
        </mat-option>
      </mat-autocomplete>
      <button
        *ngIf="userSelectorFormControl.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="userSelectorFormControl.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!--Time range-->
    <mat-form-field
      class="form-fields"
      style="padding-bottom: 0px; padding-top: 5px"
    >
      <mat-label>Zeitspanne wählen</mat-label>
      <mat-date-range-input
        [formGroup]="myForm"
        [rangePicker]="picker"
        [min]="minDate"
        (click)="picker.open()"
      >
        <input
          matStartDate
          formControlName="start"
          placeholder="Start"
          [formControl]="startDateFormControl"
        />
        <input
          matEndDate
          formControlName="end"
          placeholder="Ende"
          [formControl]="endDateFormControl"
        />
      </mat-date-range-input>

      <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
      <mat-date-range-picker touchUi #picker></mat-date-range-picker>
      <mat-icon matSuffix (click)="picker.open()">date_range</mat-icon>
    </mat-form-field>
  </form>
</div>
<hr style="opacity: 0.2" />
<div mat-dialog-actions class="dialog-btn-container">
  <button
    mat-raised-button
    type="reset"
    class="dialog-btn"
    (click)="onNoClick()"
  >
    Abbrechen
  </button>
  <button
    mat-raised-button
    color="primary"
    class="dialog-btn"
    (click)="setEvent()"
    [disabled]="
      !myForm.valid ||
      startDateFormControl.value == undefined ||
      endDateFormControl.value == undefined
    "
  >
    {{ buttonOk }}
  </button>
</div>
