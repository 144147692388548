import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {
  CommonFunctionsService,
  DialogType,
} from '../services/common-functions.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
  DataModelService,
  EncouragementPointEntryAction,
  EncouragementPointReason,
} from '../services/data-model.service';
import {
  PointTransfer,
  Properties,
  TransferState,
  User,
} from '../services/definitions.service';
import { LogService } from '../services/log.service';
import { UniversalDialogDialogData } from '../universal-dialog/universal-dialog';
import {
  BottomSheetComponent,
  BottomSheetData,
  ViewDataType,
} from '../bottom-sheet/bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-point-send',
  templateUrl: './point-send.component.html',
  styleUrls: ['./point-send.component.css'],
})
export class PointSendComponent implements OnInit {
  private pointTransferCollectionSub: Subscription;
  commentFormControl = new FormControl('', [Validators.required]);
  amountFormControl = new FormControl(1, [Validators.required]);
  myForm: FormGroup;
  userData: User;
  usersEncouragementPointsData: any;
  totalUnlockedEncouragementPoints: number;
  qrCodeGenerated: boolean = false;
  qrCodeSource: string = '';
  userSpecificFinishedTransfers: PointTransfer[] = [];
  private blockUpdatesWhileDeleting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private commonFunctions: CommonFunctionsService,
    private spinner: NgxSpinnerService,
    private log: LogService,
    private authService: AuthService,
    private firestore: AngularFirestore,
    private bottomSheet: MatBottomSheet,
    private dataModelService: DataModelService
  ) {
    this.spinner.show();
    this.myForm = fb.group({
      comment: this.commentFormControl,
      amount: this.amountFormControl,
    });

    let first = new Promise<void>((resolve, reject) => {
      //set timeout for resolve. If resolve is fired before, the reject has no impact
      setTimeout(() => {
        reject('Register for user data change has failed');
      }, 5000);
      // Assign the data to the data source for the table to render
      this.commonFunctions.registerEventEmitterHandlerUserData((data: User) => {
        this.userData = data;
        resolve();
      });
    });

    let second = new Promise<void>((resolve, reject) => {
      //set timeout for resolve. If resolve is fired before, the reject has no impact
      setTimeout(() => {
        reject('Register for encouragement points data change has failed');
      }, 5000);
      //get encouragement points
      this.commonFunctions.registerEventEmitterHandlerEncouragementPoints(
        (data) => {
          this.usersEncouragementPointsData = data;
          resolve();
        }
      );
    });

    Promise.all([first, second])
      .then(
        () => {
          this.calculateMaxPoints();
        },
        (rejected) => {
          commonFunctions.showErrorToast('Konnte Basisdaten nicht laden');
          log.error(
            'Unable to load initial data: ' + rejected,
            PointSendComponent.name
          );
        }
      )
      .finally(() => {
        this.spinner.hide();
      });
  }

  private calculateMaxPoints() {
    this.totalUnlockedEncouragementPoints =
      this.getUnlockedEncouragementPointsCount();
    //set max amount validator
    this.amountFormControl.setValidators(
      Validators.max(this.totalUnlockedEncouragementPoints)
    );
  }

  ngOnInit(): void {
    this.subscribeOnCollectionChange();
  }

  ngOnDestroy() {
    this.unsubscribeEventEmitterListener();
  }

  // Generates the QR Code
  public generateQrCode() {
    var senderUserId = this.authService.getCurrentUserId();
    var amount = this.amountFormControl.value;
    var comment = this.commentFormControl.value;
    var senderUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.userData
    );
    this.qrCodeSource = `${senderUserId};${senderUserName};${amount};${comment}`;
    this.qrCodeGenerated = true;
  }

  // Die Anzahl der Förderpunkte wird berechnet anhand der Einträge in der entsprechenden collection + der Anzahl die aus den Arbeitsstunden entstehen.
  private getUnlockedEncouragementPointsCount() {
    return this.commonFunctions.getUnlockedEncouragementPointsCount(
      this.userData,
      this.usersEncouragementPointsData
    );
  }

  //Listens to changes on the point transfer collection
  private subscribeOnCollectionChange() {
    var thisUsersId = this.authService.getCurrentUserId();
    this.pointTransferCollectionSub = this.firestore
      .collection(PointTransfer())
      .valueChanges({ idField: 'id' })
      .subscribe(
        async (data: []) => {
          //avoid update while deleting old entries
          if (this.blockUpdatesWhileDeleting) {
            return;
          }

          //check for entries that have the same user id as 'senderUserId' and are in state 'pending'
          if (data) {
            var allTransfers: PointTransfer[] = data;

            //get all finished (for showing history)
            this.userSpecificFinishedTransfers = allTransfers.filter(
              (x) =>
                x.senderUserId == thisUsersId &&
                x.state == TransferState.FINISHED
            );

            //get all pending
            var allPending = allTransfers.filter(
              (x) =>
                x.senderUserId == thisUsersId &&
                x.state == TransferState.PENDING
            );

            //sort by newest pending
            allPending.sort((a: PointTransfer, b: PointTransfer) => {
              return a.timestamp < b.timestamp ? 1 : -1;
            });
            //get newest pending
            if (allPending.length > 0) {
              var newestPending = allPending[0];

              // if first entry is too old, then also delete
              var newestTime: Date = new Date(
                newestPending.timestamp.seconds * 1000
              );
              var now: Date = new Date();
              var diff = now.getTime() - newestTime.getTime(); //diff in ms
              diff = diff / 1000 / 60; //minutes

              //check if transaction too old
              if (diff > 5) {
                //too old -> delete all
                await this.deleteOldPendingTransfers(allPending);
              } else {
                //show confirmation dialog
                this.commonFunctions
                  .openDialog(
                    'Bestätigen',
                    `Willst du ${newestPending.amountPoints} Punkte an ${newestPending.receiverUserName} übertragen?`,
                    DialogType.INFO,
                    'Nein',
                    'Ja',
                    true
                  )
                  .afterClosed()
                  .subscribe((result: UniversalDialogDialogData) => {
                    this.handleDialogDecision(result.result, newestPending);
                  });

                //delete all other pending
                if (allPending.length > 1) {
                  await this.deleteOldPendingTransfers(allPending.slice(1));
                }
              }
            }
          }
        },
        (error) => {
          this.commonFunctions.showErrorToast(error, 10000);
        }
      );
  }

  //Delete old pending transfers
  private async deleteOldPendingTransfers(allPending: PointTransfer[]) {
    this.blockUpdatesWhileDbOperation();
    var showError = false;
    allPending.forEach(async (el) => {
      var ret = await this.dataModelService.deleteGeneric(
        `${PointTransfer()}/${el.id}`
      );
      if (!ret) {
        this.log.error(`Unable to delete a pending point transfer: ${el.id}`);
        showError = true;
      }
    });
    if (showError) {
      this.commonFunctions.showErrorToast(
        'Offene transfers konnten nicht gelöscht werden. Bitte melde das dem Admin.'
      );
    }
  }

  //Block updates of the point transfer collection while changing entries operation running
  private blockUpdatesWhileDbOperation() {
    this.blockUpdatesWhileDeleting = true;
    setTimeout(() => {
      this.blockUpdatesWhileDeleting = false;
    }, 5000);
  }

  //Handles the users decision when choosing to accept transfer or not
  private async handleDialogDecision(result: number, pointTransfer: PointTransfer) {
    //vorher nochmal das objekt abfragen ob es noch auf pending steht
    var updatedItem = await this.dataModelService.getByPath(PointTransfer() + '/' + pointTransfer.id);
    if (updatedItem.result) {
      var uPT : PointTransfer =  updatedItem.data;
      if (uPT.state != TransferState.PENDING) {
        //nicht im state pending
        this.commonFunctions.showErrorToast("Die Transaktion wurde vom Empfänger abgebrochen");
        return;
      }
    }else{
      this.commonFunctions.showErrorToast("Die Transaktion konnte nicht abgeschlossen werden");
      return;
    }
    if (result == 1) {
      this.completePointTransfer(pointTransfer);
    } else {
      //state auf declined setzen
      var errMsg = 'Konnte status nicht in der Datenbank speichern';
      this.dataModelService
        .updateTransferEntryState(pointTransfer.id, TransferState.DECLINED)
        .then(
          () => {},
          () => {
            this.commonFunctions.showErrorToast(errMsg);
          }
        )
        .catch(() => {
          this.commonFunctions.showErrorToast(errMsg);
        });
    }
  }

  //Executes the point transfer
  private async completePointTransfer(pointTransfer: PointTransfer) {
    this.spinner.show();
    var currentUserName = this.commonFunctions.getPrettyPrintedUserName(
      this.userData
    );
    var currentDate = firebase.firestore.Timestamp.fromDate(new Date());
    var errMsg = 'Punktetransfer fehlgeschlagen';
    var internalErr =
      'Could not transfer points. One or all of the promises have failed. Check for inconsistency. Error: ';

    //punkte dem receiver gutschreiben
    var ret = await this.dataModelService.createEncouragementPointEntry(
      pointTransfer.receiverUserId,
      pointTransfer.amountPoints,
      EncouragementPointEntryAction.ADD,
      pointTransfer.comment,
      EncouragementPointReason.POINT_TRANSFER,
      pointTransfer.senderUserName,
      currentDate,
      [],
      currentUserName
    );
    if (!ret) {
      this.commonFunctions.showErrorToast(errMsg);
      this.log.error(internalErr, PointSendComponent.name);
      this.spinner.hide();
    }
    //sleep
    await this.delay(500);

    //Punkte dem sender abziehen
    ret = await this.dataModelService.createEncouragementPointEntry(
      pointTransfer.senderUserId,
      pointTransfer.amountPoints,
      EncouragementPointEntryAction.REMOVE,
      pointTransfer.comment,
      EncouragementPointReason.POINT_TRANSFER,
      pointTransfer.receiverUserName,
      currentDate,
      [],
      currentUserName
    );
    if (!ret) {
      this.commonFunctions.showErrorToast(errMsg);
      this.log.error(internalErr, PointSendComponent.name);
      this.spinner.hide();
    }
    //sleep
    await this.delay(500);

    ret = await this.dataModelService.updateTransferEntryState(
      pointTransfer.id,
      TransferState.FINISHED
    );
    if (!ret) {
      this.commonFunctions.showErrorToast(errMsg);
      this.log.error(internalErr, PointSendComponent.name);
      this.spinner.hide();
    }

    this.commonFunctions.showSuccessToast('Punketransfer erfolgreich');
    this.qrCodeGenerated = false;
    this.calculateMaxPoints();
    this.spinner.hide();
  }

  private unsubscribeEventEmitterListener() {
    if (this.pointTransferCollectionSub)
      this.pointTransferCollectionSub.unsubscribe();
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  // Shows the points history in the bottom sheet
  public showPointsHistory() {
    //sort data
    this.userSpecificFinishedTransfers.sort((a, b) =>
      a.timestamp < b.timestamp ? 1 : -1
    );
    let data = new BottomSheetData(
      this.userSpecificFinishedTransfers,
      ViewDataType.POINT_TRANSFER_SENDER
    );
    this.bottomSheet.open(BottomSheetComponent, {
      data: data,
    });
  }

  //For testing
  // async test() {
  //   this.blockUpdatesWhileDbOperation();
  //   await this.dataModelService.createPointTransferEntry(
  //     'dc4osIO4VSNEamUKKiQUnI495lP2',
  //     'Ralf',
  //     '23uwzgzOlqWsvHlwNz8AMU7PGrG2',
  //     'Jessi',
  //     1,
  //     'Keiner',
  //     'pending'
  //   );
  // }
}
