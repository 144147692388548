import { Pipe, PipeTransform } from '@angular/core';
import { WorkPackageKind } from '../services/definitions.service';

@Pipe({
  name: 'wpKind',
})
export class WpKindPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case WorkPackageKind.Standard:
        return 'Standard';
      case WorkPackageKind.Construction:
        return 'Aufbau';
      case WorkPackageKind.Dismantling:
        return 'Abbau';
      default:
        return 'Standard';
    }
  }
}
