<h1 mat-dialog-title style="text-align: center">App Update</h1>
<hr style="opacity: 0.2" />
<div style="text-align: center; font-size: 0.8em; margin-bottom: 30px">
  <p>Ein Update ist verfügbar.</p>
</div>
<div mat-dialog-content>
  <button
    class="button"
    style="height: 80px"
    (click)="onYesClick()"
    [ngStyle]="{ 'background-color': false ? '#d8d8d8' : '' }"
  >
    Ok
  </button>
  <!-- <button
    class="button"
    (click)="onNoClick()"
    [ngStyle]="{ 'background-color': false ? '#d8d8d8' : '' }"
  >
    Nein
  </button> -->
</div>
