import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import {
  BottomSheetComponent,
  BottomSheetData,
  ViewDataType,
} from '../bottom-sheet/bottom-sheet.component';
import { CommonFunctionsService, DialogType } from '../services/common-functions.service';
import { Properties, User } from '../services/definitions.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  public userData: User;
  public usersEncouragementPointsData: any;
  public taskForm: FormGroup;
  public addFailed: boolean;
  public debug: boolean;

  constructor(
    public formBuilder: FormBuilder,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private commonFunctions: CommonFunctionsService,
  ) {
    this.addFailed = false;
    this.debug = environment.debug;

    this.taskForm = this.formBuilder.group({
      task: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }

  ngOnInit() {
    // Assign the data to the data source for the table to render
    this.commonFunctions.registerEventEmitterHandlerUserData((data: User) => {
      this.userData = data;
    });
    this.commonFunctions.registerEventEmitterHandlerEncouragementPoints(
      (data) => {
        this.usersEncouragementPointsData = data;
        //sort data by timestamp
        this.usersEncouragementPointsData.sort((a, b) =>
          a[Properties.Timestamp] < b[Properties.Timestamp] ? 1 : -1
        );
      }
    );
  }

  ngAfterViewInit() {}

  // Die Anzahl der Förderpunkte wird berechnet anhand der Einträge in der entsprechenden collection
  public getUnlockedEncouragementPointsCount() {
    return this.commonFunctions.getUnlockedEncouragementPointsCount(
      this.userData,
      this.usersEncouragementPointsData
    );
  }

  //Calculates the count of points that are not unlocked by now
  public getLockedEncouragementPointsCount() {
    var sum = 0;

    //get locked encouragement points
    var lockedEncPoints =
      this.commonFunctions.calculateEncouragementPointsCount(
        false,
        this.usersEncouragementPointsData
      );
    sum += lockedEncPoints;

    //get points that result from work hours
    var whPoints = this.commonFunctions.getPointsFromWorkHours(this.userData);
    if (Number.isInteger(whPoints)) {
      sum += whPoints;
    }
    return sum;
  }

  // Shows the points history in the bottom sheet
  public showPointsHistory() {
    let data = new BottomSheetData(
      this.usersEncouragementPointsData,
      ViewDataType.ENCOURAGEMENT_POINTS
    );
    this.bottomSheet.open(BottomSheetComponent, {
      data: data,
    });
  }

  public showInfoDialog() {
    this.commonFunctions.openDialog(
      'Gesperrte Punkte',
      'Förderpunkte die im laufenden Jahr vergeben werden, sind erst im darauffolgenden Jahr freigeschaltet. Punkte aus Arbeitsstunden werden erst im Folgejahr angerechnet.',
      DialogType.INFO
    );
  }
}
