<h1 mat-dialog-title>{{ data.event ? "Edit Event" : "Add Event" }}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="Event Title" [(ngModel)]="eventTitle" />
  </mat-form-field>
  <!--Info: da eh nur der Tag als start in frage kommt, braucht man auch nur das Ende-->
  <!-- <mat-form-field>
    <input
      matInput
      [matDatepicker]="startPicker"
      placeholder="Start Date"
      [min]="eventStartDate"
      [max]="eventStartDate"
      [(ngModel)]="eventStartDate"
      disabled="true"
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="startPicker"
    ></mat-datepicker-toggle>
    <mat-datepicker #startPicker></mat-datepicker>
  </mat-form-field> -->
  <mat-form-field>
    <input
      matInput
      [matDatepicker]="endPicker"
      placeholder="End-Datum"
      [(ngModel)]="eventEndDate"
      [min]="eventStartDate"
    />
    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
    <mat-datepicker #endPicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      [format]="24"
      [(ngModel)]="eventStartTime"
      [ngxMatTimepicker]="startTimePicker"
      placeholder="Start-Zeit"
      [max]="eventEndTime"
      readonly
    />
    <mat-icon matSuffix (click)="startTimePicker.open()"> watch_later </mat-icon>
    <ngx-mat-timepicker #startTimePicker></ngx-mat-timepicker>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      [format]="24"
      [(ngModel)]="eventEndTime"
      [ngxMatTimepicker]="endTimePicker"
      placeholder="Ende"
      [min]="eventStartTime"
      readonly
    />
    <mat-icon matSuffix (click)="endTimePicker.open()"> watch_later </mat-icon>
    <ngx-mat-timepicker #endTimePicker></ngx-mat-timepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-select placeholder="Event Type" [(ngModel)]="eventType">
      <mat-option *ngFor="let type of eventTypes" [value]="type">{{
        type
      }}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onAdd()">
    {{ data.event ? "Save" : "Add" }}
  </button>
</div>
