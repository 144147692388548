<div class="text" *ngIf="camInitialized">
  <p style="font-size: 0.8rem">
    Bitte scanne den QR-Code des Users von dem du Punkte einsammeln möchtest.
  </p>
</div>

<!--Source: https://github.com/zxing-js/ngx-scanner/wiki/Getting-Started-->
<zxing-scanner
  #scanner
  class="scanner"
  [enable]="scannerEnabled"
  [autostart]="true"
  (camerasFound)="camerasFoundHandler($event)"
  (camerasNotFound)="camerasNotFoundHandler($event)"
  (scanSuccess)="scanSuccess($event)"
  (scanFailure)="scanFailure($event)"
  (scanError)="scanError($event)"
  (scanComplete)="scanComplete($event)"
  (deviceChange)="deviceHasChanged($event)"
  [formats]="['QR_CODE']"
  [timeBetweenScans]="2000"
  [delayBetweenScanSuccess]="2000"
></zxing-scanner>

<div class="change-cam" *ngIf="camInitialized">
  <button style="font-size: 0.8rem" mat-icon-button (click)="changeCamera()">
    <mat-icon>replay</mat-icon>
  </button>
  <span style="font-size: 0.8rem" (click)="changeCamera()"
    >Kamera wechseln</span
  >
</div>

<div style="text-align: center">
  <button
    style="font-size: 0.8rem"
    mat-icon-button
    (click)="showInfoDialog()"
    aria-label="Kein Kamerabild sichtbar?"
  >
    <mat-icon>info</mat-icon>
  </button>
  <span style="font-size: 0.8rem" (click)="showInfoDialog()"
    >Kein Kamerabild sichtbar?</span
  >
</div>

<!-- <div style="text-align: center; padding-top: 40%" *ngIf="!scannerEnabled">
  <button
    mat-icon-button
    aria-label="Reset"
    (click)="scannerEnabled = true"
    style="width: 100%; height: 100%; margin-left: -100px"
  >
    <mat-icon style="font-size: 8rem">replay</mat-icon>
  </button>
</div>

<button style="font-size: 0.8rem" mat-icon-button (click)="changeCamera()">
  Kamera wechseln <mat-icon>replay</mat-icon>
</button>

<p>Current device: {{ scanner?.device?.label }}</p>
<p>scanner: {{ scanner }}</p>
<p>device: {{ scanner?.device }}</p> -->

<button
  *ngIf="userSpecificFinishedTransfers.length > 0"
  mat-fab
  class="history-btn"
  color="primary"
  aria-label="Punkte-Historie"
  (click)="showPointsHistory()"
>
  <mat-icon class="history-icon">history</mat-icon>
</button>
