import { Pipe, PipeTransform } from '@angular/core';
import { WorkHourEntryAction } from '../services/data-model.service';

@Pipe({
  name: 'workHourEntryAction',
})
export class WorkHourEntryActionPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    switch (value) {
      case WorkHourEntryAction.ADD:
        if (args && args[0] && args[0] == '2') return 'Hinzufügen';
        else return 'Hinzugefügt';
      case WorkHourEntryAction.REMOVE:
        if (args && args[0] && args[0] == '2') return 'Entnehmen';
        else return 'Entnommen';
      default:
        return 'Unbekannt';
    }
  }
}
